import {useEffect, useState} from 'react'

import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  orderBy,
  query,
  setDoc,
  updateDoc,
} from 'firebase/firestore'
import {firebase_db} from '../../../../firebase'

import {useUserStore} from '../../../../store/user'

const useConversation = (selectedUser?: any) => {
  const [inputMessage, setInputMessage] = useState('')
  const [messageLoading, setMessageLoading] = useState(true)
  const [message, setMessage] = useState<any>(null)
  const {user} = useUserStore()
  const [chatId, setChatId] = useState('')
  const userId = user?.userId

  useEffect(() => {
    if (selectedUser?.userId) {
      try {
        setMessageLoading(true)
        const sortedIds = [userId, selectedUser?.userId].map((id) => id.toLowerCase()).sort()
        const newUserId = sortedIds.join('_')

        setChatId(newUserId)
        const chatsRef = collection(firebase_db, 'chats', newUserId, 'messages')

        const unsubscribe = onSnapshot(
          query(chatsRef, orderBy('sentAt', 'asc')),
          (querySnapshot) => {
            const messages: any = querySnapshot.docs.map((doc) => {
              const data = doc.data()
              return {
                ...data,
              }
            })
            setMessage(messages)
            setMessageLoading(false)
          }
        )

        // Clean up the listener when component unmounts
        return () => {
          unsubscribe()
        }
      } catch (error) {
        setMessageLoading(false)
      }
    } else {
      setMessageLoading(false)
    }
  }, [selectedUser])

  const handleSendMessage = async () => {
    if (!inputMessage.trim().length) {
      return
    }
    const data = inputMessage.trim()
    const msgObject = {
      chatId: chatId,
      image: null,
      sendTo: selectedUser?.userId,
      sentAt: new Date(),
      sentBy: userId,
      text: data,
    }
    const messageId = `${chatId}_${msgObject.sentAt.getTime()}`
    try {
      setInputMessage('')
      // Reference the collection and documents you want to query
      const messageDocRef = doc(firebase_db, 'chats', chatId, 'messages', messageId)

      // Set the nested data within the document
      await setDoc(messageDocRef, {...msgObject})

      const chatRoomDocRef = doc(firebase_db, 'ChatRooms', chatId)
      const chatRoomDoc = await getDoc(chatRoomDocRef)
      if (!chatRoomDoc.exists()) {
        // Create new document in ChatRooms
        await setDoc(chatRoomDocRef, {
          chatId: chatId,
          lastUpdate: new Date(),
          lastMsgTo: selectedUser?.userId,
          unreadCount: 1,
          members: [userId, selectedUser?.userId],
          recentMessage: data,
          sendTo: selectedUser?.sendTo,
          sendBy: selectedUser?.sendBy,
        })
      } else {
        await updateDoc(chatRoomDocRef, {
          lastUpdate: new Date(),
          lastMsgTo: selectedUser?.userId,
          unreadCount: 1,
          members: [userId, selectedUser?.userId],
          recentMessage: data,
          sendTo: selectedUser?.sendTo,
          sendBy: selectedUser?.sendBy,
        })
      }
    } catch (error) {
      console.error('Error retrieving nested data:', error)
    }
  }
  return {
    handleSendMessage,
    inputMessage,
    setInputMessage,
    selectedUser,
    message,
    messageLoading,
  }
}

export default useConversation
