import {useEffect, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import axios from 'axios'
import NewJobCard from '../JobCard'
import '../jobPage.css'
import {Spinner} from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import {scrollToTopOfScrollable} from '../../../../utils/helper/helper'
interface jobDataProps {
  status: number
  name: string
  description: string
  activeHiring: boolean
  reqNumber: string
  createdBy: string
  seniorityLevel: string
  sponsorshipType: string
  salary: {
    currency: string
    starting: number
    ending: number
    duration: string
  }
  bonus: {
    starting: number
    ending: number
    bonusType: string
  }
  stock: {
    type: string
    stockType: string
    duration: string
    starting: number
    ending: number
  }
  jobFunctions: [string]
  companyName: string
  companyLogo: string
  profilePicture: string
  responsibilitiesList: [string]
  responsibilitiesList2: [string]
  locationsType: string
  peopleManagement: string
  reports: string
  degree: [string]
  jobResponsibility: [
    {
      responsibility: string
      duration: string
    }
  ]
  industryExperienceList: [string]
  employeeBenefitList: [string]
  collegeMajorList: [string]
  domainList: [string]
  topSkillList: [string]
  experience: [
    {
      fieldName: string
      degree: string
      experienceLevel: string
    }
  ]
  locationsList: [string]
}
const ApproveJobPage = () => {
  const API_URL = process.env.REACT_APP_API_URL
  const [jobData, setJobData] = useState<any>([])
  const [JobFunctionOptions, setJobFunctionOptions] = useState([])
  const [jobFunction, setJobFunction] = useState('')
  const [searchInput, setSearchInput] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [itemOffset, setItemOffset] = useState(0)
  const itemsPerPage = 12
  const [pageCount, setPageCount] = useState(Math.ceil(jobData.length / itemsPerPage))
  const handlePageClick = (event: any) => {
    const newOffset = event.selected
    setItemOffset(newOffset)
  }
  useEffect(() => {
    axios.get(`${API_URL}/users/domain/job-function-list`).then((response) => {
      setJobFunctionOptions(response.data)
    })
  }, [])
  useEffect(() => {
    setIsLoading(true)
    axios
      .get(
        `${API_URL}/jobs/gat-all-approve-jobs?page=${itemOffset}&limit=12&jobFunction=${jobFunction}&jobName=${searchInput?.trim()}`
      )
      .then((response) => {
        setIsLoading(false)
        setJobData(response.data)
        setPageCount(response.data.totalPages || 1)
        scrollToTopOfScrollable()
      })
      .catch((error) => {
        console.log(error)
        setIsLoading(false)
      })
  }, [searchInput, jobFunction, itemOffset])
  const handleChange = (e: any) => {
    e.preventDefault()
    let value = e.target.value
    setSearchInput(value.trimLeft())
    if (value.length && itemOffset !== 0) {
      setItemOffset(0)
    }
  }
  const handleJobFunctionChange = (value: string) => {
    setJobFunction(value)
    if (value.length && itemOffset !== 0) {
      setItemOffset(0)
    }
  }

  return (
    <div>
      <div className='d-flex justify-content-between  flex-column-reverse flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-sm-row bg-white mb-6 rounded px-5 '>
        <div className='d-flex align-items-center gap-3  p-3'>
          <span> {jobData?.totalElements} Total</span>
          <div className='search '>
            <input
              type='text'
              className='form-control input-area bg-grey'
              placeholder='Search...'
              value={searchInput}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className='d-flex align-items-center gap-3 p-3'>
          <span style={{width: '100px'}}>Job Functions</span>
          <div>
            <select
              className='form-select'
              aria-label='Default select example'
              value={jobFunction}
              name='role'
              onChange={(e) => {
                handleJobFunctionChange(e.target.value)
              }}
              style={{width: '150px'}}
            >
              <option value=''>All</option>
              {JobFunctionOptions.map((option: any, i: any) => (
                <option key={i}> {option.name}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className='card-header bg-white rounded p-2'>
        <h3 className='mx-5'>All Approved Jobs</h3>
      </div>
      {!jobData?.data?.length ? (
        <div className='d-flex p-9 m-9 justify-content-center align-items-center fs-1  fw-bold'>
          {isLoading ? <Spinner /> : 'No Jobs Found'}
        </div>
      ) : (
        <div className='d-flex flex-column justify-content-between minH-75'>
          <div className='d-flex justify-content-start flex-wrap flex-column flex-xxl-row flex-xl-row flex-lg-row flex-md-row p-1 mt-5 gap-3'>
            {jobData?.data?.map((data: any, i: any) => (
              <NewJobCard data={data} key={i} />
            ))}
          </div>
          <div className='d-flex'>
            {' '}
            <ReactPaginate
              nextLabel='Next >'
              onPageChange={handlePageClick}
              forcePage={itemOffset}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel='< Previous'
              pageClassName='page-item'
              pageLinkClassName='page-link'
              previousClassName='page-item'
              previousLinkClassName='page-link'
              nextClassName='page-item'
              nextLinkClassName='page-link'
              breakLabel='...'
              breakClassName='page-item'
              breakLinkClassName='page-link'
              containerClassName='pagination'
              activeClassName='active'
              renderOnZeroPageCount={null}
            />
          </div>
        </div>
      )}
    </div>
  )
}
export default ApproveJobPage
