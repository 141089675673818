import {useEffect, useRef, useState} from 'react'
import Conversation from './conversation'
import useChat from './useChat'
import Inbox from './chatInbox'

import './chat.css'

export default function ChatMain() {
  const [messages, setMessages] = useState([
    {from: 'computer', text: 'Hi, My Name is Jason muler'},
    {from: 'me', text: 'Hey there'},
    {from: 'me', text: 'Myself Ferin Patel'},
    {
      from: 'computer',
      text: "Nice to meet you. You can send me message and i'll reply you with same message.",
    },
  ])
  const {selectedUser, handleSelectedUser, handleSelectedUserSearched} = useChat()
  const [inputMessage, setInputMessage] = useState('')

  const handleSendMessage = () => {
    if (!inputMessage.trim().length) {
      return
    }
    const data = inputMessage

    setMessages((old) => [...old, {from: 'me', text: data}])
    setInputMessage('')

    setTimeout(() => {
      setMessages((old) => [...old, {from: 'computer', text: data}])
    }, 1000)
  }

  return (
    <>
      <div className='d-xxl-none d-xl-none flex-column flex-fluid'>
        <div className='card-header px-4 bg-white'>
          <div className='pt-5'>
            <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6'>
              <li className='nav-item'>
                <a className='nav-link active' data-bs-toggle='tab' href='#inbox_tab'>
                  Inbox
                </a>
              </li>
              <li className='nav-item'>
                <a
                  className='nav-link d-flex align-items-center'
                  data-bs-toggle='tab'
                  href='#chat_tab'
                >
                  <span>Chat</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className='tab-content w-100'>
          <div className='tab-pane fade active show bg-white w-100' id='inbox_tab' role='tabpanel'>
            <Inbox
              handleSelectedUser={handleSelectedUser}
              selectedUser={selectedUser}
              handleSelectedUserSearched={handleSelectedUserSearched}
              css={'px-2 py-4'}
            />
          </div>
          <div className='tab-pane fade show bg-white w-100' id='chat_tab' role='tabpanel'>
            <Conversation
              inputMessage={inputMessage}
              handleSendMessage={handleSendMessage}
              setInputMessage={setInputMessage}
              messages={messages}
              selectedUser={selectedUser}
            />
          </div>
        </div>
      </div>
      <div className='d-flex flex-row flex-row-fluid ' style={{width: '100%'}}>
        <div className='d-xxl-flex d-xl-flex flex-column d-none ' style={{minWidth: '40%'}}>
          <Inbox
            handleSelectedUser={handleSelectedUser}
            selectedUser={selectedUser}
            handleSelectedUserSearched={handleSelectedUserSearched}
            css={'card-body'}
          />
        </div>
        <div
          className='d-xxl-flex d-xl-flex flex-column d-none flex-row-fluid'
          // style={{width: '60%'}}
          style={{minWidth: '60%'}}
        >
          <Conversation
            inputMessage={inputMessage}
            handleSendMessage={handleSendMessage}
            setInputMessage={setInputMessage}
            messages={messages}
            selectedUser={selectedUser}
          />
        </div>
      </div>
    </>
  )
}
