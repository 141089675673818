import {useLocation, useNavigate} from 'react-router-dom'
import axios from 'axios'
import {ToastContainer, toast} from 'react-toastify'
import {Popover, Spinner} from 'react-bootstrap'
import {useMediaQuery} from 'react-responsive'
import './jobPage.css'
import 'react-toastify/dist/ReactToastify.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import {addCommas} from '../../../utils/helper/helper'
import {useState} from 'react'
interface dataProps {
  status: number
  name: string
  description: string
  activeHiring: boolean
  createdBy: string
  seniorityLevel: string
  sponsorshipType: string
  salary: {
    currency: string
    starting: number
    ending: number
    duration: string
  }
  bonus: {
    duration: string
    starting: number
    ending: number
    bonusType: string
  }
  stock: {
    type: string
    stockType: string
    duration: string
    starting: number
    ending: number
  }
  jobFunctions: [string]
  responsibilitiesList: [string]
  responsibilitiesList2: [string]
  locationsType: string
  peopleManagement: string
  reports: string
  degree: [string]
  jobResponsibility: [
    {
      responsibility: string
      duration: string
    }
  ]
  industryExperienceList: [string]
  employeeBenefitList: [string]
  collegeMajorList: [string]
  domainList: [string]
  topSkillList: [string]
  experience: [
    {
      fieldName: string
      degree: string
      experienceLevel: string
    }
  ]
  locationsList: [string]
}

const JobDetailPage = () => {
  const API_URL = process.env.REACT_APP_API_URL
  const isMobileView = useMediaQuery({maxWidth: '425px'})
  const navigate = useNavigate()
  const location: any = useLocation()
  const data = location.state.data
  const jobId = data.id
  const [isApproving, setIsApproving] = useState(false)
  const primaryLocation = data.locationsList.length > 0 && data.locationsList[0]

  const HitApproveJobAPI = () => {
    setIsApproving(true)
    axios
      .get(`${API_URL}/jobs/approve-job?jobId=${jobId}`)
      .then((res) => {
        setIsApproving(false)
        if (res.statusText === 'OK') {
          toast.success('Job Approved Successfully', {
            position: 'top-center',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: 'colored',
          })
          setTimeout(() => {
            navigate('/ApproveJobPage')
          }, 2000)
        }
      })
      .catch((error) => {
        setIsApproving(false)
        toast.error(error?.response?.data?.message, {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: 'colored',
        })
        console.error('Error occurred while approving applicant:', error.response.data.message)
      })
  }

  const popover = (
    <Popover id='popover-basic'>
      <Popover.Body className='p-5'>
        {data.employeeBenefitList.map((benefit: any, i: any) => (
          <li key={i}>{benefit}</li>
        ))}
      </Popover.Body>
    </Popover>
  )

  return (
    <>
      <div className='d-flex align-items-center flex-center justify-content-between bg-white mb-5 px-5 py-2'>
        <h4 className='text-dark fs-2'>{data.companyName}</h4>
        {isMobileView ? (
          <div>
            {' '}
            {data.status === 0 && (
              <button
                type='button'
                className='btn btn-success fs-8'
                onClick={() => HitApproveJobAPI()}
              >
                {isApproving ? <Spinner /> : 'Approve'}
              </button>
            )}
          </div>
        ) : (
          <div className='d-flex bg-white align-items-center gap-4'>
            {data.status === 0 && (
              <button
                type='button'
                className='btn btn-success fs-8'
                onClick={() => HitApproveJobAPI()}
                style={{width: '89px'}}
                disabled={isApproving}
              >
                {/* <i className='bi bi-hand-thumbs-up-fill me-2' /> */}
                {isApproving ? <Spinner style={{height: '15px', width: '15px'}} /> : 'Approve'}
              </button>
            )}
            <button
              className='btn btn-secondary fs-8'
              onClick={() => {
                navigate(data.status === 1 ? '/ApproveJobPage' : '/NewJobPage')
              }}
            >
              Back
            </button>
          </div>
        )}
      </div>
      <div className='d-flex flex-column flex-xxl-row  w-100 gap-4'>
        <div className='d-flex flex-column me-1 job-cards h-100'>
          <div className='card-body bg-white p-3 mb-5'>
            <div className='d-flex flex-wrap justify-content-between gap-4 py-1 px-3 '>
              <div className='d-flex align-items-center gap-2'>
                <i className='bi bi-database fs-1' />
                <div className='d-flex flex-column '>
                  <span className='salary-heading'>Base Salary</span>
                  <div className='d-flex align-content-center'>
                    {data?.salary?.from !== null ? (
                      <span className='salary-text'>
                        {`$${
                          data?.salary?.from === null ? '0' : addCommas(data?.salary?.from)
                        } - $${data?.salary?.to === null ? '0' : addCommas(data?.salary?.to)}`}
                      </span>
                    ) : (
                      <span className='salary-text'>Not Specified</span>
                    )}
                  </div>
                </div>
              </div>
              {/* <div className='d-flex align-items-center '>
                <i className='fa-solid fa-users-line fs-1' />

                <div className='d-flex flex-column px-3'>
                  <span className='salary-heading'>Other Benefits</span>
                  <div>
                    <OverlayTrigger trigger='click' placement='top' overlay={popover}>
                      <div className='text-primary cursor'>View</div>
                    </OverlayTrigger>
                  </div>
                </div>
              </div> */}
              {data.bonus && (
                <div className='d-flex align-items-center gap-2'>
                  <i className='bi bi-piggy-bank-fill fs-1' />
                  <div className='d-flex flex-column '>
                    <span className='salary-heading'> Bonus - Stock</span>
                    <span className='salary-text'>
                      {`${
                        data?.bonus?.from !== null && data?.bonus?.bonusType !== 'percentage'
                          ? '$'
                          : ''
                      }${data?.bonus?.from === null ? 'N/A' : addCommas(data?.bonus?.from)}${
                        data?.bonus?.from !== null && data?.bonus?.bonusType === 'percentage'
                          ? '%'
                          : ''
                      } - ${data?.stock?.from === null ? 'N/A' : addCommas(data?.stock?.from)}${
                        data?.stock?.from !== null &&
                        data?.stock?.stockType &&
                        data?.stock?.stockType?.toLowerCase() === 'percentage'
                          ? '%'
                          : ''
                      }`}
                    </span>
                  </div>
                </div>
              )}
              <div className='d-flex align-items-center gap-2'>
                <i className='bi bi-file-earmark-text fs-1' />
                <div className='d-flex flex-column '>
                  <span className='salary-heading'>Visa Requirement</span>
                  <span className='salary-text'>
                    {data.sponsorshipType}
                    {/* {data.sponsorshipType === 'yes' ? <text>yes</text> : <text>No</text>} */}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className='card-body bg-white p-2 '>
            <div className='d-flex flex-wrap salary-card'>
              <div className='d-flex flex-column ms-1'>
                <div className='d-flex flex-row  mt-2 p-1 px-1 fw-bold fs-1'>{data.name}</div>
                <div className='d-flex flex-row  mt-1 p-1 gap-3'>
                  <div className='col-4 text-dark fw-bold'>Primary Location</div>
                  <div className=' col-8 text-dark'>{primaryLocation}</div>
                </div>
                <div className='d-flex flex-row  mt-1 p-1 gap-3'>
                  <div className='  col-4 text-dark fw-bold'>Other Location</div>
                  {data.locationsList.length > 1 ? (
                    <div className='d-flex  col-8 text text-dark flex-wrap gap-2'>
                      {data.locationsList.map((option: any, index: number) =>
                        index === 0 ? null : (
                          <div className='d-flex ' style={{whiteSpace: 'normal'}} key={index}>
                            {option}
                            {index < data?.locationsList?.length - 2 && <span>,</span>}
                          </div>
                        )
                      )}
                    </div>
                  ) : (
                    <div className='d-flex  col-8 text-dark flex-wrap'>Not Available</div>
                  )}
                </div>
                <div className='d-flex flex-row  mt-1 p-1 gap-3'>
                  <div className='  col-4 text-dark fw-bold'>Job Type</div>
                  <div className=' col-8 text-dark'>{data.locationsType}</div>
                </div>
                <div className='d-flex flex-row  mt-1 p-1 gap-3'>
                  <div className='  col-4 text-dark fw-bold'>Job Function</div>
                  <div className='d-flex flex-wrap  col-8 text-dark'>
                    {/* {data.jobFunctions.join(', ')} */}
                    {data.jobFunctions.map((option: any, index: any) => (
                      <div className='d-flex  text-dark pe-2 '>
                        {option}
                        {index < data.jobFunctions.length - 1 && <text>,</text>}
                      </div>
                    ))}
                  </div>
                </div>

                <div className='d-flex flex-row  mt-1 p-1 gap-3'>
                  <div className='  col-4 text-dark fw-bold'>Requistion</div>
                  <div className=' col-8 text-dark'>{data.reqNumber}</div>
                </div>
                <div className='d-flex flex-row  mt-1 p-1 gap-3'>
                  <div className='  col-4 text-dark fw-bold'>Must Have</div>
                  <div className=' col-8 text-dark'>
                    {data.mustHaveSkills !== null && data.mustHaveSkills.length > 0
                      ? data.mustHaveSkills.join(', ')
                      : 'null'}
                  </div>

                  {/* <div className='col-md-9 col-8 text-dark'>{data.mustHaveSkills}</div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex flex-column ms-1 flex-grow-1 h-100 overflow-hidden'>
          <div className='card-header px-4 bg-white '>
            <div className='pt-5 '>
              <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6'>
                <li className='nav-item'>
                  <a className='nav-link active' data-bs-toggle='tab' href='#positionSummary_tab'>
                    Position Summary
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className='tab-content h-100'>
            <div
              className='tab-pane fade active show p-10 bg-white '
              id='positionSummary_tab'
              role='tabpanel'
            >
              <div className='d-flex flex-column p-1 mb-3'>
                <div className='text  fs-6'>{data.description}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default JobDetailPage
