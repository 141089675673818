import axios from 'axios'
import React, {ChangeEvent, useState} from 'react'
import {useForm, SubmitHandler, Controller} from 'react-hook-form'
import {useLocation, useNavigate} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import {ToastContainer, toast} from 'react-toastify'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
type Inputs = {
  fullName: string
  experienceJobFunc: string
  experienceCompanyName: string
  educationDegree: string
  educationFieldOfStudy: string
  educationSchoolName: string
  locationUserAddress: any
  locationUserState: string
  shortBio: string
  positionDataCompanyName: string
  positionDataMonthsWorked: string
  positionDataJobTitle: string
  positionDataEmploymentType: string
  positionDataStartDate: string
  positionDataEndDate: string
  educationDataSchoolName: string
  educationDataDegree: string
  educationDataMajor: string
  positionDataJobLocation: string
  educationDataStartDate: string
  educationDataEndDate: string
  educationDataEducationStatus: string
  salaryCurrencyType: string
  salaryCurrencyamount: string
  schoolName: string
  degree: string
  major: string
  startDate: string
  endDate: string
  educationStatus?: string
  userId: string
}

interface dataProps {
  id: string
  fullName: string
  firstName: string
  middleName: string
  lastName: string
  email: string
  password: string
  phoneNumber: string
  is_admin: boolean
  isDeleted: boolean
  isAlternateLoginSourceEnabled: boolean
  userLocation: userLocationType
  dateOfBirth: string
  educationList: EducationType[]
  positionList: postitionListType[]
  interestedJobFunctions: [string]
  preferredWorkplaceLocation: []
  userType: string
  isProfileComplete: boolean
  profilePicture: string
  profileVideo: string
  jobSearch: string
  seniorityLevel: string
  isWorkOnSite: boolean
  isWorkHybrid: boolean
  isWorkRemotely: boolean
  workVisaRequired: string
  nonImmigrantVisaStatus: string
  desiredSalary: string
  whenYouCanStart: string
  customJoiningDate: string
  isVerify: boolean
  roleName: string
  positionName: string
  parentId: string
  companyId: string
  location: string
  userId: string
  willingToRelocate: boolean
  talentStatus: boolean
}
interface EducationData {
  schoolName: string
  degree: string
  major: string
  startDate: string
  endDate: string
  educationStatus?: string
}
interface EducationType {
  degree: string
  fieldOfStudy: string
  year: string
  schoolName: string
}

interface userLocationType {
  address: string
  city: string
  country: string
  id: string
  state: string
}

const emptyuserLocationType = {
  address: '',
  city: '',
  country: '',
  id: '',
  state: '',
}
const emptyEducation = {
  degree: '',
  fieldOfStudy: '',
  year: '',
  schoolName: '',
}
interface postitionListType {
  id: string
  companyName: string
  employmentType: string
  startDate: string
  endDate: string
  managedPeople: string
  jobTitle: string
  jobFunc: string[]
  jobLocation: string
  industries: string
  position: string
  monthsWorked: string
  isStillWorking: boolean
}

const EmptyPositionlist = {
  id: '',
  companyName: '',
  employmentType: '',
  startDate: '',
  endDate: '',
  managedPeople: '',
  jobTitle: '',
  jobFunc: [],
  jobLocation: '',
  industries: '',
  position: '',
  monthsWorked: '',
  isStillWorking: false,
}
interface PositionData {
  companyName: string
  monthsWorked: string
  jobTitle: string
  employmentType: string
  startDate: string
  endDate: string
  jobLocation: string
  jobFunc?: string[]
}
const UpdateApplicant = () => {
  const [currentLocation, setCurrentLocation] = useState<any>()

  const location: any = useLocation()
  const data = location.state.data
  const API_URL = process.env.REACT_APP_API_URL
  const {currentUser} = useAuth()
  const applicantId = data.userId
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: {errors},
  } = useForm<Inputs>()
  const googleAPI = 'AIzaSyAI03ex2qAY3htk-dGScWZTTUSuHbJHr74'

  const [publishType, setPublishType] = useState(0)
  const [file, setFile] = useState<File | null>(null)
  const [imageUrl, setImageUrl] = useState(data?.profilePicture)
  const [newJobFunc, setNewJobFunc] = useState('')
  const [jobSummary, setJobSummary] = useState(data?.miscellaneousDetails?.shortBio)
  const [interestedJobFunctions, setInterestedJobFunctions] = useState(data.interestedJobFunctions)
  const [positionData, setPositionData] = useState<PositionData[]>(data.positionList)
  const [educationData, setEducationData] = useState<EducationData[]>(data.educationList)
  const [visarequirement, setVisarequirement] = useState(data?.workVisaRequired)
  const education: EducationType =
    data.educationList.length > 0 ? data.educationList[0] : emptyEducation
  const experience: postitionListType =
    data.positionList.length > 0 ? data.positionList[0] : EmptyPositionlist
  const locationUser: userLocationType = data.userLocation
    ? data.userLocation
    : emptyuserLocationType
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0]
    if (selectedFile) {
      setFile(selectedFile)
      setImageUrl(URL.createObjectURL(selectedFile))
    }
  }
  const handleInputEducationChange = (index: number, field: keyof EducationData, value: string) => {
    setEducationData((prevState) => {
      const updatedData = [...prevState]
      updatedData[index][field] = value
      return updatedData
    })
  }
  const handleInputPositionChange = (
    index: number,
    field: keyof PositionData,
    value: string | string[]
  ) => {
    setPositionData((prevState) => {
      const updatedData = [...prevState]

      if (field === 'jobFunc') {
        if (Array.isArray(value)) {
          updatedData[index][field] = value.filter((item) => item !== '')
        } else {
          updatedData[index][field] = []
        }
      } else {
        updatedData[index][field] = value as string
      }

      return updatedData
    })
  }

  const handleAddJobFunc = (index: number, newJobFunc: string) => {
    setPositionData((prevState) => {
      const updatedData = [...prevState]
      updatedData[index].jobFunc = [...(updatedData[index]?.jobFunc || []), newJobFunc] as string[]
      return updatedData
    })
  }

  const handleRemoveJobFunc = (index: number, optionIndex: number) => {
    setPositionData((prevState) => {
      const updatedData = [...prevState]
      const jobFunc = updatedData[index].jobFunc as string[]
      jobFunc.splice(optionIndex, 1)
      updatedData[index].jobFunc = jobFunc
      return updatedData
    })
  }
  const handleAddInterestedJobFunction = () => {
    setInterestedJobFunctions((prevState: string[]) => [...prevState, newJobFunc])
    setNewJobFunc('') // Clear the input field after adding the job function
  }

  const handleDeleteInterestedJobFunction = (index: number) => {
    setInterestedJobFunctions((prevState: string[]) => {
      const updatedJobFunctions = [...prevState]
      updatedJobFunctions.splice(index, 1)
      return updatedJobFunctions
    })
  }
  const RequestForEdit = async () => {
    setPublishType(1)
    try {
      await handleSubmit(onSubmit)()
    } catch (error) {
      console.error(error)
    }
  }
  const PublishApplicant = async () => {
    setPublishType(0)
    try {
      await handleSubmit(onSubmit)()

      axios
        .put(
          `${API_URL}/users/admin/update-candidate-on-request?adminId=${currentUser?.userId}&userId=${data?.userId}`
        )
        .then((res) => {})
    } catch (error) {
      console.error(error)
    }
  }
  const onSubmit: SubmitHandler<Inputs> = (value) => {
    const BODY = {
      educationList: educationData,
      positionList: positionData,
      user: {
        salary: {
          currencyType: value.salaryCurrencyType,
          amount: value.salaryCurrencyamount,
          payType: data?.salary.payType,
          totalCompensation: data?.salary.totalCompensation,
          isVisibleToEmployers: data?.salary.isVisibleToEmployers,
        },
        locationList: data?.locationList,
        nonImmigrantVisaStatus: data?.nonImmigrantVisaStatus,
        interestedIndustry: data?.interestedIndustry,
        avoidIndustry: data?.interestedIndustry,
        interestedDomain: data?.interestedDomain,
        avoidDomain: data?.avoidDomain,
        interestedJobFunctions: newJobFunc,
        workVisaRequired: visarequirement,
        jobSearch: data?.jobSearch,
        //     seniorityLevel: 0,
        //     whenYouCanStart: 0,
        isWorkHybrid: data?.isWorkHybrid,
        isWorkOnSite: data?.isWorkOnSite,
        isWorkRemotely: data?.isWorkRemotely,
        customJoiningDate: data?.customJoiningDate,
        profilePicture: imageUrl,
      },
      miscellaneousDetails: {
        shortBio: value?.shortBio,
        // achievement: data?.mis
        // spokenLanguagesList:[tangri],
        // interest:[video games],
        // linkedinProfileLink: '',
        // duties:[duty 2]
      },
    }
    axios
      .put(
        `${API_URL}/users/admin/update-candidate-on-request?adminId=${currentUser?.userId}&userId=${data?.userId}&publishType=${publishType}`,
        BODY
      )
      .then((res) => {})
  }
  //   const approveApplicant = () => {
  //     axios
  //       .get(`${API_URL}/users/admin/approve-applicant?applicantsId=${applicantId}`)
  //       .then((res) => {
  //
  //         if (res.statusText === 'OK') {
  //           toast.success('Applicant Approved Successfully', {
  //             position: 'top-center',
  //             autoClose: 1000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: false,
  //             draggable: false,
  //             progress: undefined,
  //             theme: 'colored',
  //           })
  //           setTimeout(() => {
  //             navigate('/applicantmain')
  //           }, 1500)
  //         }
  //         // window.location.replace('/applicantmain')
  //       })
  //       .catch((error) => {
  //         console.error('Error occurred while approving applicant:', error)
  //       })
  //   }
  const handleVisaRequirementChange = (event: any) => {
    const selectedValue = event.target.value
    setVisarequirement(selectedValue)
  }
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='d-flex bg-white flex-row-fluid justify-content-end mb-6 px-5 py-2 gap-4'>
          <button
            className='btn btn-success'
            type='submit'
            // onClick={() => {
            //   navigate('/UpdateCandidate', {
            //     state: {
            //       data: data,
            //     },
            //   })
            // }}
          >
            Save
          </button>
          <button
            className='btn btn-secondary '
            onClick={() => {
              navigate('/applicantmain')
            }}
          >
            Back
          </button>
        </div>
        <div className='d-flex flex-wrap justify-content-center align-items-center p-3 gap-5 bg-white p-3 mb-5'>
          <button type='button' className='btn btn-success fs-8' onClick={() => PublishApplicant()}>
            Approve
          </button>
          <button type='button' className='btn btn-primary  fs-8 ' onClick={() => RequestForEdit()}>
            Request For Edit
          </button>
        </div>
        <div className='d-flex flex-center'>
          <div className='card-body bg-white mb-5 '>
            {/* <div className='d-flex flex-row-fluid px-6 '> */}
            <div className='d-flex align-items-start flex-column flex-xxl-row flex-xl-row flex-lg-row flex-md-row p-6'>
              <div className='d-flex flex-column pt-2 me-10'>
                <img
                  src={imageUrl}
                  className='h-100px w-100px rounded-circle flex-center'
                  alt='Profile Image '
                />
                <input
                  accept='image/*'
                  id='icon-button-file'
                  type='file'
                  style={{display: 'none'}}
                  onChange={handleFileChange}
                />
                <label
                  className='btn btn-active-primary btn-custom bi bi-pencil fs-5 mt-2'
                  htmlFor='icon-button-file'
                />
              </div>
              <div className='d-flex flex-column col-xl-9 col-lg-9 p-5'>
                <input
                  className='form-control  input-width'
                  type='text'
                  placeholder='Full Name'
                  value={data.fullName}
                  {...register('fullName')}
                />

                <div className='d-flex align-items-center my-1'>
                  <div className='d-flex flex-row align-items-center gap-4'>
                    <input
                      className='form-control  input-width'
                      type='text'
                      placeholder='Experience'
                      value={experience.jobFunc}
                      {...register('experienceJobFunc')}
                    />

                    <div className='d-flex align-items-center gap-3'>
                      <span>@</span>
                      <input
                        className='form-control input-width'
                        type='text'
                        placeholder='Company Name'
                        value={experience.companyName}
                        {...register('experienceCompanyName')}
                      />
                    </div>
                  </div>
                </div>

                <div className='d-flex flex-row card-heading2 align-items-center my-1'>
                  <input
                    className='form-control input-width'
                    type='text'
                    placeholder='Degree'
                    value={education.degree}
                    {...register('educationDegree')}
                  />

                  <div className='d-flex align-items-center gap-2'>
                    <span className='p-1'>@</span>
                    <input
                      className='form-control input-width'
                      type='text'
                      placeholder='School name'
                      value={education.schoolName}
                      {...register('educationSchoolName')}
                    />
                  </div>
                </div>

                <div className='d-flex align-items-center my-1'>
                  {/* <i className='bi bi-geo-alt fs-5 me-1' /> */}
                  <Controller
                    name='locationUserAddress'
                    control={control}
                    defaultValue=''
                    rules={{required: 'Current Location is required'}}
                    render={({field: {onChange, value}}) => (
                      <>
                        <GooglePlacesAutocomplete
                          apiKey={googleAPI}
                          selectProps={{
                            value: currentLocation,
                            onChange: (newValue) => {
                              setCurrentLocation(newValue)
                              onChange(newValue)
                            },
                            placeholder: 'Enter the location...',
                            // components: {
                            //   MenuList: CustomMenuList,
                            // },
                            styles: {
                              input: (provided) => ({
                                ...provided,
                                color: 'blue',
                              }),
                            },
                          }}
                        />
                      </>
                    )}
                  />
                  {/* <input
                    className='form-control input-width'
                    type='text'
                    placeholder='School name'
                    value={locationUser.address}
                    {...register('locationUserAddress')}
                  /> */}
                </div>

                <div className='text my-1'>
                  <input
                    className='form-control input-width'
                    type='text'
                    placeholder='Job Summary'
                    value={jobSummary}
                    {...register('shortBio')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex flex-column flex-xxl-row  w-100 gap-4'>
          <div className='d-flex flex-column  col-xl-4 job-cards'>
            {/* <div className='card-body bg-white p-3 mb-5'>
              <div className='d-flex flex-wrap justify-content-between gap-4 py-1 px-3 '>
                <div className='d-flex  align-items-center '>
                  <i className='bi bi-database fs-1' />
                  <div className='d-flex flex-column px-1 '>
                    <span className='salary-heading'>Base Salary</span>

                    <div className='d-flex gap-1 my-1'>
                      <input
                        className='form-control input-width'
                        type='text'
                        placeholder='Job Summary'
                        value={data?.salary.amount}
                        {...register('salaryCurrencyamount')}
                      />
                      <input
                        className='form-control input-width'
                        type='text'
                        placeholder='Job Summary'
                        value={data?.salary.currencyType}
                        {...register('salaryCurrencyType')}
                      />
                    </div>
                  </div>
                </div>
                <div className='d-flex align-items-center'>
                  <i className='bi bi-file-earmark-text fs-1' />
                  <div className='d-flex flex-column px-3'>
                    <span className='salary-heading'>Visa Requirement</span>
                    <select
                      className='form-select'
                      aria-label='Select example'
                      onChange={handleVisaRequirementChange}
                    >
                      <option>select Visa Requirement</option>
                      <option value='0'>Yes</option>
                      <option value='1'>No</option>
                      <option value='2'>I am not sure</option>
                    </select>
                    <span className='salary-text'>{data.workVisaRequired}</span>
                  </div>
                </div>
              </div>
            </div> */}
            <div className='card-body bg-white p-3 '>
              <div className='d-flex flex-column salary-card gap-4'>
                <div className='d-flex flex-row align-items-center gap-2'>
                  <i className='bi bi-award fs-1' />
                  <div className='d-flex flex-wrap gap-2'>
                    {interestedJobFunctions.map((option: string, index: number) => (
                      <span className='badge badge-warning badges-text p-2 rounded-pill'>
                        {option}{' '}
                        <i
                          className='bi bi-x cursor ms-1 text-dark'
                          onClick={() => handleDeleteInterestedJobFunction(index)}
                        />
                      </span>
                    ))}
                  </div>
                </div>
                <div className='d-flex align-items-center gap-3'>
                  <input
                    type='text'
                    className='form-control w-50'
                    placeholder='Add Job Function'
                    value={newJobFunc}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setNewJobFunc(e.target.value)}
                  />
                  <button className='btn btn-info btn-sm' onClick={handleAddInterestedJobFunction}>
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex flex-column ms-1 w-100'>
            <div className='card-header px-4 bg-white'>
              <div className='pt-5'>
                <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6'>
                  <li className='nav-item'>
                    <a className='nav-link active' data-bs-toggle='tab' href='#experience_tab'>
                      Experience & Education
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='tab-content'>
              <div
                className='tab-pane fade active show p-10 bg-white'
                id='experience_tab'
                role='tabpanel'
              >
                {/* {positionList.map((positionData: any, i: any) => (
                  <div className='pb-15 border border-start border-top-0 border-end-0 border-bottom-0 border-secondary position-relative'>
                    <img
                      src={imageUrl}
                      className='h-50px w-50px rounded-circle border border-dark border-1 position-absolute start-0 translate-middle-x'
                      alt='Profile Image '
                    />
                    <div className='d-flex flex-column p-5 mb-3 experience-card ' key={i}>
                      <div className='d-flex justify-content-between '>
                        <input
                          className='form-control input-width '
                          type='text'
                          placeholder='Company Name'
                          value={positionData.companyName}
                          {...register('positionDataCompanyName')}
                        />
                       
                        <input
                          className='form-control input-width'
                          type='text'
                          placeholder='Months Worked'
                          value={positionData.monthsWorked}
                          {...register('positionDataMonthsWorked')}
                        />
                      </div>
                      <div className='d-flex justify-content-between py-2'>
                        <div className='d-flex align-item-center gap-3'>
                          <input
                            className='form-control input-width '
                            type='text'
                            placeholder='Job Title'
                            value={positionData.jobTitle}
                            {...register('positionDataJobTitle')}
                          />
                          <input
                            className='form-control input-width '
                            type='text'
                            placeholder='Employment Type'
                            value={positionData.employmentType}
                            {...register('positionDataEmploymentType')}
                          />
                        </div>
                        <div className='d-flex align-items-center gap-3'>
                          <input
                            className='form-control input-width '
                            type='text'
                            placeholder='Job startDate'
                            value={positionData.startDate}
                            {...register('positionDataStartDate')}
                          />
                          <span className='text-gray-800 fw-bold fs-7'>-</span>
                          <input
                            className='form-control input-width '
                            type='text'
                            placeholder='Job endDate'
                            value={positionData.endDate}
                            {...register('positionDataEndDate')}
                          />
                        </div>
                      </div>
                      <input
                        className='form-control input-width '
                        type='text'
                        placeholder='Job endDate'
                        value={positionData.jobLocation}
                        {...register('positionDataJobLocation')}
                      />

                      <div className='d-flex flex-wrap gap-3 align-items-center '>
                        <i className='bi bi-tags-fill fs-2' />
                        {positionData.jobFunc.map((option: any) => (
                          <div className='d-flex border tag-card align-items-center'>
                            <span>{option}</span>
                            <i className='bi bi-x cursor ms-1 text-dark' onClick={() => {}} />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))} */}
                {positionData.map((data, index) => (
                  // <div
                  //   className='pb-15 border border-start border-top-0 border-end-0 border-bottom-0 border-secondary position-relative'
                  //   key={index}
                  // >
                  //   <img
                  //     src={imageUrl}
                  //     className='h-50px w-50px rounded-circle border border-dark border-1 position-absolute start-0 translate-middle-x'
                  //     alt='Profile Image'
                  //   />
                  <div key={index} className='d-flex flex-column p-5 mb-3 experience-card'>
                    <div className='d-flex justify-content-between gap-2'>
                      <input
                        className='form-control input-width'
                        type='text'
                        placeholder='Company Name'
                        value={data.companyName}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleInputPositionChange(index, 'companyName', e.target.value)
                        }
                      />
                      <input
                        className='form-control input-width'
                        type='text'
                        placeholder='Months Worked'
                        value={data.monthsWorked}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleInputPositionChange(index, 'monthsWorked', e.target.value)
                        }
                      />
                    </div>
                    <div className='d-flex flex-wrap justify-content-between py-2 gap-2'>
                      <div className='d-flex align-item-center gap-3 '>
                        <input
                          className='form-control input-width'
                          type='text'
                          placeholder='Job Title'
                          value={data.jobTitle}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleInputPositionChange(index, 'jobTitle', e.target.value)
                          }
                        />
                        <select
                          className='form-select'
                          aria-label='Select example'
                          onChange={(e: ChangeEvent<HTMLSelectElement>) =>
                            handleInputPositionChange(index, 'employmentType', e.target.value)
                          }
                        >
                          <option>Select Employment Type</option>
                          <option value='0'>Full Time</option>
                          <option value='1'>Part Time</option>
                        </select>
                        {/* <input
                            className='form-control input-width'
                            type='text'
                            placeholder='Employment Type'
                            value={data.employmentType}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleInputPositionChange(index, 'employmentType', e.target.value)
                            }
                          /> */}
                      </div>
                      <div className='d-flex align-items-center gap-3 '>
                        <input
                          className='form-control input-width'
                          type='text'
                          placeholder='Job Start Date'
                          value={data.startDate}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleInputPositionChange(index, 'startDate', e.target.value)
                          }
                        />
                        <span className='text-gray-800 fw-bold fs-7'>-</span>
                        <input
                          className='form-control input-width'
                          type='text'
                          placeholder='Job End Date'
                          value={data.endDate}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleInputPositionChange(index, 'endDate', e.target.value)
                          }
                        />
                      </div>
                    </div>
                    <input
                      className='form-control input-width'
                      type='text'
                      placeholder='Job Location'
                      value={data.jobLocation}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        handleInputPositionChange(index, 'jobLocation', e.target.value)
                      }
                    />

                    <div className='d-flex flex-column gap-3'>
                      <div className='d-flex flex-wrap gap-3 align-items-center'>
                        {data.jobFunc && data.jobFunc?.length > 0 && (
                          <i className='bi bi-tags-fill fs-2' />
                        )}
                        {/* {data.jobFunc?.map((option: any, optionIndex: any) => (
                          <div className='d-flex flex-wrap gap-3 align-items-center'>
                            <div
                              className='d-flex border tag-card align-items-center'
                              key={optionIndex}
                            >
                              <span>{option}</span>
                              <i
                                className='bi bi-x cursor ms-1 text-dark'
                                onClick={() => handleRemoveJobFunc(index, optionIndex)}
                              />
                            </div>
                          </div>
                        ))} */}
                      </div>
                      {/* <div className='d-flex align-items-center gap-3'>
                        <input
                          type='text'
                          className='form-control w-50'
                          placeholder='Add Job Function'
                          value={newJobFunc}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            setNewJobFunc(e.target.value)
                          }
                        />
                        <button
                          className='btn btn-info btn-sm'
                          onClick={() => handleAddJobFunc(index, newJobFunc)}
                        >
                          Add
                        </button>
                      </div> */}
                    </div>
                  </div>
                  // </div>
                ))}

                {educationData.map((data, index) => (
                  // <div
                  //   className='pb-15 border border-start border-top-0 border-end-0 border-bottom-0 border-secondary position-relative'
                  //   key={index}
                  // >
                  //   <img
                  //     src={imageUrl}
                  //     className='h-50px w-50px rounded-circle border border-dark border-1 position-absolute start-0 translate-middle-x'
                  //     alt='Profile Image'
                  //   />
                  <div key={index} className='d-flex flex-column gap-2 p-5 mb-3 experience-card'>
                    <div className='d-flex align-items-center py-2 gap-'>
                      {/* <i className='fa-solid fa-graduation-cap fs-2 ms-2' /> */}
                      <input
                        className='form-control input-width'
                        type='text'
                        placeholder='School Name'
                        value={data.schoolName}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleInputEducationChange(index, 'schoolName', e.target.value)
                        }
                      />
                    </div>
                    <div className='d-flex flex-wrap align-items-center gap-2'>
                      <input
                        className='form-control input-width'
                        type='text'
                        placeholder='Degree'
                        value={data.degree}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleInputEducationChange(index, 'degree', e.target.value)
                        }
                      />
                      ,
                      <input
                        className='form-control input-width'
                        type='text'
                        placeholder='Major'
                        value={data.major}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleInputEducationChange(index, 'major', e.target.value)
                        }
                      />
                    </div>
                    <div className='d-flex align-items-end flex-wrap justify-content-between w-100 '>
                      <div className='d-flex align-items-center gap-3'>
                        <input
                          className='form-control input-width'
                          type='text'
                          placeholder='Education Start Date'
                          value={data.startDate}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleInputEducationChange(index, 'startDate', e.target.value)
                          }
                        />
                        -
                        <input
                          className='form-control input-width'
                          type='text'
                          placeholder='Education End Date'
                          value={data.endDate}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleInputEducationChange(index, 'endDate', e.target.value)
                          }
                        />
                      </div>
                      <div className='d-flex '>
                        {data.educationStatus && (
                          <div>
                            Education:
                            <input
                              className='form-control input-width'
                              type='text'
                              placeholder='Education Status'
                              value={data.educationStatus}
                              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                handleInputEducationChange(index, 'educationStatus', e.target.value)
                              }
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  // </div>
                ))}

                {/* </div> */}
                {/* /////////////////////////////////// */}
              </div>
            </div>
          </div>
        </div>
      </form>
      <ToastContainer />
    </>
  )
}

export default UpdateApplicant
