import React, {useEffect, useRef, useState} from 'react'
import {useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom'
import noImage from '../../../_metronic/assets/img/noImage.png'
import moment from 'moment'
import axios from 'axios'
import DatePicker from 'react-datepicker'
import {useUserStore} from '../../../store/user'
import {ToastContainer, toast} from 'react-toastify'
import './ViewCandidate.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-toastify/dist/ReactToastify.css'
import TalentOpportunities from './talent-opportunities.tsx'
import {Spinner} from 'react-bootstrap'
interface dataProps {
  id: string
  fullName: string
  firstName: string
  middleName: string
  lastName: string
  email: string
  password: string
  phoneNumber: string
  is_admin: boolean
  isDeleted: boolean
  isAlternateLoginSourceEnabled: boolean
  userLocation: userLocationType
  dateOfBirth: string
  educationList: EducationType[]
  positionList: postitionListType[]
  interestedJobFunctions: [string]
  preferredWorkplaceLocation: []
  userType: string
  isProfileComplete: boolean
  profilePicture: string
  profileVideo: string
  jobSearch: string
  seniorityLevel: string
  isWorkOnSite: boolean
  isWorkHybrid: boolean
  isWorkRemotely: boolean
  workVisaRequired: string
  nonImmigrantVisaStatus: string
  desiredSalary: string
  whenYouCanStart: string
  customJoiningDate: string
  isVerify: boolean
  roleName: string
  positionName: string
  parentId: string
  companyId: string
  location: string
  userId: string
  jobTitle: string
  companyName: string
  latestDegree: string
  schoolName: string
  willingToRelocate: boolean
  talentStatus: boolean
}
interface EducationType {
  degree: string
  fieldOfStudy: string
  year: string
  schoolName: string
}

interface userLocationType {
  address: any
  city: string
  country: string
  id: string
  state: string
}

const emptyuserLocationType = {
  address: '',
  city: '',
  country: '',
  id: '',
  state: '',
}
const emptyEducation = {
  degree: '',
  fieldOfStudy: '',
  year: '',
  schoolName: '',
}
interface postitionListType {
  id: string
  companyName: string
  employmentType: string
  startDate: string
  endDate: string
  managedPeople: string
  jobTitle: string
  jobFunc: string[]
  jobLocation: string
  industries: string
  position: string
  monthsWorked: string
  isStillWorking: boolean
}

const EmptyPositionlist = {
  id: '',
  companyName: '',
  employmentType: '',
  startDate: '',
  endDate: '',
  managedPeople: '',
  jobTitle: '',
  jobFunc: [],
  jobLocation: '',
  industries: '',
  position: '',
  monthsWorked: '',
  isStillWorking: false,
}
export default function ViewCandidateDetails() {
  const modalRef = useRef<any>()
  const navigate = useNavigate()
  const {id} = useParams()
  const {user} = useUserStore()
  const API_URL = process.env.REACT_APP_API_URL
  const [data, setData] = useState<any>()
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [candidateJobList, setcandidateJobList] = useState<any>([])
  const [isFocused, setIsFocused] = useState(false)
  const [openStages, setOpenStages] = useState(false)
  const [openStageId, setOpenStageId] = useState<any>()
  const [isActivelyInterviewing, setIsActivelyInterviewing] = useState<any>()
  const [jobSummary, setJobSummary] = useState('')
  const [updatedStage, setUpdatedStage] = useState({id: '', value: ''})
  const [currentStage, setCurrentStage] = useState('')
  const [jobToBeUpdated, setJobToBeUpdated] = useState<any>()
  const [selectedDate, setSelectedDate] = useState<any>({id: '', value: ''})
  const [searchJob, setSearchJob] = useState('')
  const [jobResult, setJobResult] = useState([])
  const [showJobResult, setShowJobResult] = useState(false)
  const [selectedJob, setSelectedJob] = useState<any>()
  const [appliedJobList, setAppliedJobList] = useState([])

  const [locationUser, setLocationUser] = useState<userLocationType | null>(null)
  const handleActivelyInterviewChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.checked
    setIsActivelyInterviewing(value)
    const BODY = {
      userId: data?.userId,
      isActivelyInterviewing: value,
    }
    try {
      axios
        .put(`${API_URL}/users/admin/actively-interviewing`, BODY)
        .then((res) => {})
        .catch((error) => {
          console.log('error', error)
        })
    } catch (error) {
      console.log('error', error)
    }
  }
  const handleSubmitStage = () => {
    const BODY = {
      candidateId: data?.userId,
      jobId: jobToBeUpdated?.id,
      stagingLevel: updatedStage,
      modifyTime: selectedDate?.value !== '' ? selectedDate.value : new Date(),
    }
    try {
      axios
        .put(`${API_URL}/jobs/apply/candidate-application-stage`, BODY)
        .then((res) => {
          toast.success('Stage Updated Successfully', {
            position: 'top-center',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: 'colored',
          })
          setOpenStages(false)
          fetchCandidateJobs(data?.userId)
          setUpdatedStage({id: '', value: ''})
          setJobToBeUpdated('')
          setSelectedDate({id: '', value: new Date()})
        })
        .catch((error) => {
          console.log('errror', error)
        })
    } catch (error) {
      console.log(error)
    }
  }
  const handleSearchJob = (value: any) => {
    setSearchJob(value)
    if (isFocused) {
      try {
        axios
          .get(`${API_URL}/jobs/all-jobs-for-admin?adminId=${user?.userId}&query=${value}`)
          .then((res) => {
            setShowJobResult(true)
            setJobResult(res?.data)
          })
          .catch((error) => {
            console.log('errror', error)
          })
      } catch (error) {
        console.log(error)
      }
    }
  }
  const handleFocusBlur = (value: boolean) => {
    setIsFocused(value)
  }
  const handleSubmitData = () => {
    if (appliedJobList?.some((job: any) => job?.id === selectedJob?.id)) {
      toast.error('This Job is Already Assigned to this Candidate', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: 'colored',
      })
    } else {
      try {
        const BODY = {
          candidateId: data?.userId,
          jobId: selectedJob?.id,
        }
        axios
          .post(`${API_URL}/jobs/apply/admin-apply-candidate`, BODY)
          .then((res) => {
            if (modalRef.current) {
              const closeButton = document.getElementById('modalCloseButton')
              if (closeButton) {
                closeButton.click()
              }
            }
            fetchCandidateJobs(data?.userId)
          })
          .catch((error) => {
            console.log(error)
          })
      } catch (error) {
        console.log(error)
      }
    }
  }

  function fetchCandidateData() {
    setIsDataLoading(true)
    try {
      axios
        .get(`${API_URL}/users/admin/fetch-candidate-user-for-admin?candidateId=${id}`)
        .then((response) => {
          setData(response?.data)
          setIsDataLoading(false)
          fetchCandidateJobs(response?.data?.userId)
        })
        .catch((error) => console.log(error))
    } catch (error) {
      console.log(error)
      setIsDataLoading(false)
    }
  }
  function fetchCandidateJobs(id: string) {
    try {
      axios
        .get(`${API_URL}/users/admin/candidates-application?userId=${id}`)
        .then((response) => {
          setAppliedJobList(response?.data)
        })
        .catch((error) => console.log(error))
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    fetchCandidateData()
  }, [id])
  useEffect(() => {
    setIsActivelyInterviewing(data?.isActivelyInterviewing)
    setJobSummary(data?.miscellaneousDetails?.shortBio)
    setLocationUser(data?.userLocation ? data?.userLocation : null)
  }, [data])

  return (
    <>
      {data?.fullName ? (
        <>
          <div className='d-flex bg-white flex-row-fluid justify-content-between mb-6 px-5 py-2 gap-4'>
            {/* <button
              className='btn btn-success'
              onClick={() => {
                navigate('/UpdateCandidate', {
                  state: {
                    data: data,
                  },
                })
              }}
            >
              Edit
            </button> */}
            <div className='d-flex align-items-center' style={{color: 'gray'}}>
              <div
                className='d-flex align-items-center justify-content-start cursor-pointer text-hover-info'
                onClick={() => {
                  navigate('/candidatemain')
                }}
              >
                Approved Candidates
              </div>
              <div
                className='d-flex align-items-center text-hover-info'
                style={{marginLeft: '10px'}}
              >
                <span style={{marginRight: '5px'}}>&bull;</span>
                {data?.fullName?.length > 30 ? data?.fullName.slice(0, 30) + '...' : data?.fullName}
              </div>
            </div>

            <button
              className='btn btn-secondary '
              onClick={() => {
                navigate(-1)
              }}
            >
              Back
            </button>
          </div>
          <div className='d-flex flex-center'>
            <div className='card-body bg-white mb-5 '>
              {/* <div className='d-flex flex-row-fluid px-6 '> */}
              <div className='d-flex justify-content-start flex-column flex-xxl-row flex-xl-row flex-lg-row flex-md-row align-items-start p-6 gap-2'>
                <div className='d-flex flex-column justify-content-start  me-10 '>
                  <img
                    src={data.profilePicture || noImage}
                    className='h-100px w-100px rounded-circle flex-center'
                    alt='Profile Image '
                  />
                </div>
                <div className='d-flex flex-column align-items-start gap-1 flex-grow-1'>
                  <h4 className='text-dark card-heading'>{data?.fullName}</h4>

                  {data.jobTitle && (
                    <div className='d-flex align-items-center '>
                      {/* <i className='fa-brands fa-linkedin fs-2 me-2' /> */}
                      <div className='d-flex flex-row '>
                        <div className='text-dark me-2'>
                          {data?.jobTitle} @ {data?.companyName}
                        </div>
                      </div>
                    </div>
                  )}

                  {data.latestDegree && (
                    <div className='d-flex flex-row card-heading2 align-items-start'>
                      <i className='fa-solid fa-graduation-cap fs-5 me-2' />
                      <div className='d-flex text-dark me-2 flex-wrap'>
                        <div className='text-dark'>{data.latestDegree}</div>
                        <div className='text-dark'> ,{data?.schoolName}</div>
                      </div>
                    </div>
                  )}
                  {locationUser && locationUser?.address && (
                    <div className='d-flex align-items-center '>
                      <i className='bi bi-geo-alt fs-5 me-1' />
                      <div className='text-dark me-2'>
                        {JSON.parse(locationUser?.address)?.label}
                      </div>
                    </div>
                  )}
                  {jobSummary && <div className='text my-1 pt-3'>{jobSummary}</div>}
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex flex-column flex-xxl-row  w-100 gap-4'>
            <div className='d-flex flex-column col-xl-4 job-cards'>
              <div className='d-flex flex-wrap justify-content-center align-items-center p-3 gap-3 bg-white p-3 mb-5'>
                <div className='form-check form-switch form-check-custom form-check-solid'>
                  <input
                    className='form-check-input cursor'
                    type='checkbox'
                    checked={isActivelyInterviewing}
                    id='flexSwitchDefault'
                    onChange={handleActivelyInterviewChange}
                  />
                  <label className='form-check-label text-dark' htmlFor='flexSwitchDefault'>
                    Is Actively Interviewing
                  </label>
                </div>
              </div>
              <div className='d-flex flex-wrap justify-content-center align-items-center p-3 gap-3 bg-white p-3 mb-5'>
                <div className='d-flex flex-column align-items-center'>
                  <div className='d-flex align-items-center'>
                    <div className='text'>Opportunities ({appliedJobList?.length})</div>
                    <div
                      className='text-hover-info pointer p-2'
                      data-bs-toggle='modal'
                      data-bs-target='#exampleModal'
                    >
                      <i className='bi bi-plus' />
                      Add
                    </div>
                  </div>
                  <div
                    className='modal fade'
                    id='exampleModal'
                    aria-labelledby='exampleModalLabel'
                    aria-hidden='true'
                    ref={modalRef}
                  >
                    <div className='modal-dialog'>
                      <div className='modal-content'>
                        <div className='modal-header'>
                          <h1 className='modal-title fs-5' id='exampleModalLabel'>
                            Add Job
                          </h1>
                          <button
                            type='button'
                            className='btn-close'
                            data-bs-dismiss='modal'
                            aria-label='Close'
                          />
                        </div>
                        <div
                          className='modal-body d-flex flex-column align-items-center'
                          style={{position: 'relative'}}
                        >
                          <div>
                            <div className='text'>Search:</div>
                            <input
                              type='text'
                              placeholder='Search for jobs ...'
                              value={searchJob}
                              onChange={(e) => handleSearchJob(e?.target?.value.trimStart())}
                              style={{
                                padding: '5px 10px',
                                width: '300px',
                                border: '1px solid #cfcfcf',
                              }}
                              onFocus={() => handleFocusBlur(true)}
                              onBlur={() => handleFocusBlur(false)}
                            />
                            {showJobResult && (
                              <div
                                className='d-flex flex-column gap-1 box-shadow py-1'
                                style={{
                                  position: 'absolute',
                                  width: '300px',
                                  zIndex: '99',
                                  background: '#fff',
                                  borderRadius: '0px 0px 10px 10px',
                                  maxHeight: '280px',
                                  overflow: 'auto',
                                }}
                              >
                                {jobResult?.map((job: any) => (
                                  <div
                                    className='d-flex text-hover-info  search-result align-items-center gap-2'
                                    key={job?.id}
                                    onClick={() => {
                                      setSelectedJob(job)
                                      setShowJobResult(false)
                                      setSearchJob(job?.name)
                                    }}
                                  >
                                    {/* <img
                                      src={job?.companyLogo}
                                      className='job-img'
                                      alt='company Image'
                                    /> */}
                                    <div className='d-flex flex-column'>
                                      <div className='text'>{job?.name}</div>
                                      <div className='text fs-8'>
                                        {job?.companyName && `@ ${job?.companyName}`}
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className='modal-footer'>
                          <button
                            id='modalCloseButton'
                            type='button'
                            className='btn btn-secondary'
                            data-bs-dismiss='modal'
                            onClick={() => {
                              setSelectedJob(null)
                              setSearchJob('')
                            }}
                          >
                            Close
                          </button>
                          <button
                            type='button'
                            className='btn btn-primary'
                            onClick={handleSubmitData}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className='d-flex flex-column gap-3'
                    style={{maxHeight: '450px', overflowY: 'auto'}}
                  >
                    {appliedJobList &&
                      appliedJobList.map((job: any) => (
                        <TalentOpportunities
                          job={job}
                          updatedStage={updatedStage}
                          setUpdatedStage={setUpdatedStage}
                          jobToBeUpdated={jobToBeUpdated}
                          setJobToBeUpdated={setJobToBeUpdated}
                          selectedDate={selectedDate}
                          setSelectedDate={setSelectedDate}
                          handleSubmitStage={handleSubmitStage}
                          openStages={openStages}
                          setOpenStages={setOpenStages}
                          openStageId={openStageId}
                          setOpenStageId={setOpenStageId}
                        />
                      ))}
                  </div>
                </div>
              </div>
              {/* <div className='d-flex flex-wrap  justify-content-center align-items-center p-3 gap-3 bg-white p-3 mb-5'>
                <button type='button' className='btn btn-success  fs-8 '>
                  <i className='bi bi-hand-thumbs-up-fill me-2' />
                  I'm interested
                </button>
                <button type='button' className='btn btn-danger fs-8'>
                  <i className='bi bi-hand-thumbs-down-fill me-2' />
                  Archieve
                </button>
              </div> */}
              {/* <div className='card-body bg-white p-3 mb-5'>
                <div className='d-flex flex-wrap justify-content-between gap-4 py-1 px-3 '>
                  <div className='d-flex  align-items-center '>
                    <i className='bi bi-database fs-1' />
                    <div className='d-flex flex-column px-1 '>
                      <span className='salary-heading'>Base Salary</span>
                      <span className='salary-text'></span>
                    </div>
                  </div> */}
              {/* <div className='d-flex align-items-center'>
                <i className='bi bi-piggy-bank-fill fs-1' />
                <div className='d-flex flex-column px-3'>
                  <span className='salary-heading'>Bonus Stock</span>
                  <span className='salary-text'>%5555</span>
                </div>
              </div> */}

              {/* <div className='d-flex align-items-center'>
                    <i className='bi bi-file-earmark-text fs-1' />
                    <div className='d-flex flex-column px-3'>
                      <span className='salary-heading'>Visa Requirement</span>
                      <span className='salary-text'>{data.workVisaRequired}</span>
                    </div>
                  </div>
                </div> */}
              {/* </div> */}
              <div className='card-body bg-white p-3 salary-card'>
                <div className='d-flex flex-row align-items-center gap-2'>
                  <i className='bi bi-award fs-1' />
                  <div className='d-flex flex-wrap gap-2'>
                    {data.interestedJobFunctions.map((option: any) => (
                      <span
                        className='badge badge-warning badges-text p-2'
                        style={{
                          whiteSpace: 'normal',
                          textAlign: 'start',
                          wordBreak: 'break-word',
                        }}
                      >
                        {option}
                      </span>
                    ))}
                  </div>
                </div>
                <div className='d-flex flex-wrap salary-card gap-4'>
                  {/* <div className='d-flex flex-wrap justify-content-between gap-4 p-1'>
                <div className='d-flex  align-items-center '>
                  <i className='bi bi-database fs-1' />
                  <div className='d-flex flex-column px-1 '>
                    <span className='salary-heading'>Base Salary</span>
                    <span className='salary-text'>$170k-190k</span>
                  </div>
                </div>
                <div className='d-flex align-items-center '>
                  <i className='fa-solid fa-users-line fs-1' />

                  <div className='d-flex flex-column px-3'>
                    <span className='salary-heading'>Team Dynamics</span>
                    <span className='salary-text'>5 Direct Reports</span>
                  </div>
                </div>
                <div className='d-flex align-items-center'>
                  <i className='bi bi-sliders fs-1' />
                  <div className='d-flex flex-column px-3'>
                    <span className='salary-heading'>Matching Job</span>
                    <span className='salary-text'>$170k-190k</span>
                  </div>
                </div>
                <div className='d-flex align-items-center'>
                  <i className='fa fa-network-wired fs-1' />
                  <div className='d-flex flex-column px-3'>
                    <span className='salary-heading'>Visa Requirement</span>
                    <span className='salary-text'>No need</span>
                  </div>
                </div>
              </div> */}
                </div>
              </div>
            </div>
            <div className='d-flex flex-column ms-1 w-100'>
              <div className='card-header px-4 bg-white'>
                <div className='pt-5'>
                  <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6'>
                    <li className='nav-item'>
                      <a className='nav-link active' data-bs-toggle='tab' href='#experience_tab'>
                        Experience & Education
                      </a>
                    </li>
                    {/* <li className='nav-item'>
                      <a
                        className='nav-link d-flex align-items-center'
                        data-bs-toggle='tab'
                        href='#activity_tab'
                      >
                        <i className='bi bi-arrow-clockwise fs-3 px-1' />
                        <span>Activities</span>
                      </a>
                    </li> */}
                  </ul>
                </div>
              </div>
              <div className='tab-content'>
                <div
                  className='tab-pane fade active show p-2 bg-white'
                  id='experience_tab'
                  role='tabpanel'
                >
                  {data?.positionList?.length || data?.educationList?.length ? (
                    <>
                      {data?.positionList &&
                        data?.positionList.map((positionData: any, i: any) => (
                          // <div
                          //   className='pb-15 border border-start border-top-0 border-end-0 border-bottom-0 border-secondary position-relative'

                          // >
                          //   <img
                          //     src={imageUrl || noImage}
                          //     className='h-50px w-50px rounded-circle border border-dark border-1 position-absolute start-0 translate-middle-x'
                          //     alt='Profile Image '
                          //   />
                          <div
                            className='d-flex flex-column gap-2 p-4 mb-3 experience-card'
                            key={i}
                          >
                            <div className='d-flex justify-content-between '>
                              <h4 className='text-hover-info'>{positionData?.companyName}</h4>
                              {positionData.monthsWorked && (
                                <span className='badge badge-success '>
                                  {positionData.monthsWorked}months
                                </span>
                              )}
                            </div>
                            <div className='d-flex justify-content-between flex-column flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-sm-row'>
                              <div className='d-flex align-items-center gap-2 w-100'>
                                <p>{positionData?.jobTitle}</p>
                                <p
                                  className='badge badge-light-info mx-2'
                                  style={{background: 'rgba(93, 120, 255, 0.1)'}}
                                >
                                  {positionData.employmentType === 'FULL_TIME'
                                    ? 'Full Time'
                                    : positionData.employmentType === 'PART_TIME'
                                    ? 'Part Time'
                                    : positionData.employmentType === 'FREE_LANCE'
                                    ? 'Freelance'
                                    : positionData.employmentType === ' SELF_EMPLOYED'
                                    ? 'Self Employeed'
                                    : positionData?.employmentType?.charAt(0).toUpperCase() +
                                      positionData?.employmentType.toLowerCase().slice(1)}
                                </p>
                              </div>
                              <div className='d-flex w-100 justify-content-end'>
                                <span className='text-gray-800 fw-bold fs-7'>
                                  {`${moment(positionData.startDate).format('MMM YYYY')}
                                   - 
                                  ${
                                    positionData.endDate
                                      ? moment(positionData.endDate).format('MMM YYYY')
                                      : positionData.isStillWorking === true && 'Present'
                                  }`}
                                </span>
                              </div>
                            </div>
                            <div className='text-gray-600'>{positionData.jobLocation}</div>
                            <div className='d-flex flex-wrap gap-3 align-items-center '>
                              <i className='bi bi-tags-fill fs-2' />
                              {positionData.jobFunc.map((option: any) => (
                                <span
                                  className='border tag-card'
                                  style={{
                                    whiteSpace: 'normal',
                                    textAlign: 'start',
                                    wordBreak: 'break-word',
                                  }}
                                >
                                  {option}
                                </span>
                              ))}
                            </div>
                            {/* <span className='font-12'>
                      {ExperienceKeyPoints.map((keyOption: any) => (
                        <li>{keyOption}</li>
                      ))}
                    </span> */}
                          </div>
                        ))}
                      {data?.educationList &&
                        data?.educationList.map((educationData: any, i: any) => (
                          <div
                            className='d-flex flex-column gap-2 p-4 mb-3 experience-card '
                            key={i}
                          >
                            <div className='d-flex'>
                              <h4 className='text-hover-info '>{educationData.schoolName}</h4>
                              <i className='fa-solid fa-graduation-cap fs-2 ms-2' />
                            </div>
                            <div className='d-flex flex-wrap'>
                              <span>{educationData.degree}</span>
                              <span>, {educationData.major}</span>
                            </div>
                            <div className='d-flex flex-wrap justify-content-between w-100'>
                              <div>
                                {educationData.startDate && (
                                  <span className='text-gray-800 fw-bold fs-7'>
                                    {` ${moment(educationData.startDate).format('MMM YYYY')} - 
                                    ${
                                      educationData.endDate
                                        ? moment(educationData.endDate).format('MMM YYYY')
                                        : educationData.educationStatus.charAt(0).toUpperCase() +
                                          educationData.educationStatus?.toLowerCase().slice(1)
                                    }`}
                                  </span>
                                )}
                              </div>
                              <div className='d-flex justify-content-end'>
                                {educationData.educationStatus && (
                                  <div>
                                    Education:
                                    <span
                                      className='badge badge-light-info ms-2 px-2'
                                      style={{background: 'rgba(93, 120, 255, 0.1)'}}
                                    >
                                      {educationData.educationStatus === 'IN_PROCESS'
                                        ? 'In Progress'
                                        : educationData.educationStatus === 'INCOMPLETE'
                                        ? 'Incomplete'
                                        : educationData.educationStatus.charAt(0).toUpperCase() +
                                          educationData.educationStatus?.toLowerCase().slice(1)}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                            {/* <div className='d-flex justify-content-between '>
                    <div>
                      <span>{educationData.jobFunc}</span>
                      <span className='badge badge-info mx-2'>{educationData.employmentType}</span>
                    </div>
                    <div>
                      <span className='text-gray-800 fw-bold fs-7'>
                        {educationData.startDate} - {educationData.endDate}
                      </span>
                    </div>
                  </div> */}
                          </div>
                        ))}
                    </>
                  ) : (
                    <div className='d-flex justify-content-center w-100'>
                      <p className='text text-gray-700'>
                        The Candidate has not provided information about their education and
                        experience.
                      </p>
                    </div>
                  )}
                  {/* </div> */}
                  {/* /////////////////////////////////// */}
                </div>
                {/* <div className='tab-pane fade' id='activity_tab' role='tabpanel'>
                  <div className='card card-custom '>
                    <div className='card-body '>
                      <div className='d-flex flex-column'>
                        <div className='d-flex flex-row align-items-center pb-6'>
                          <div className='d-flex flex-column '>
                            <i className='fa-sharp fa-solid fa-chart-simple fs-1' />
                          </div>
                          <div className='d-flex flex-column ms-8'>
                            <span>User Viewed The Profile</span>
                            <span className='text-info'>2 hrs Ago</span>
                          </div>
                        </div>
                        <div className='d-flex flex-row align-items-center pb-6'>
                          <div className='d-flex flex-column'>
                            <i className='bi bi-lightning-charge-fill fs-1' />
                          </div>
                          <div className='d-flex flex-column ms-8'>
                            <span>User (@username) interested </span>
                            <span className='text-info'>2 hrs Ago</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <ToastContainer />
        </>
      ) : (
        <>
          <div className='d-flex bg-white flex-row-fluid justify-content-end mb-6 px-5 py-2 gap-4'>
            <button
              className='btn btn-secondary '
              onClick={() => {
                navigate('/applicantmain')
              }}
            >
              Back
            </button>
          </div>
          {isDataLoading ? (
            <div
              className='d-flex flex-column justify-content-center align-items-center'
              style={{minHeight: '50vh'}}
            >
              <Spinner />
            </div>
          ) : (
            <div className='d-flex justify-content-center text-dark p-9 m-9 fs-1'>
              No Data Available
            </div>
          )}
        </>
      )}
    </>
  )
}
