import React, {useState} from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

export default function DateComponent() {
  const [startDate, setStartDate] = useState(new Date())
  const [endDate, setEndDate] = useState(new Date())
  return (
    <div>
      <div className='d-flex flex-row flex-wrap px-3 gap-3 align-items-center flex-center'>
        <div>
          <label>Start Date</label>
          <DatePicker
            selected={new Date(startDate)}
            onChange={(date: Date) => setStartDate(date)}
            selectsStart
            startDate={new Date(startDate)}
            endDate={new Date(startDate)}
          />
        </div>
        <div>
          <label>End Date</label>
          <DatePicker
            selected={new Date(endDate)}
            onChange={(date: Date) => setEndDate(date)}
            selectsEnd
            startDate={new Date(endDate)}
            endDate={endDate ? new Date(endDate) : null}
            minDate={new Date(startDate)}
          />
        </div>
      </div>
    </div>
  )
}
