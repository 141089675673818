import {useState} from 'react'
import {useNavigate} from 'react-router-dom'

import axios from 'axios'
import {ToastContainer, toast} from 'react-toastify'
import {Spinner} from 'react-bootstrap'

import './Admin.css'
import 'react-toastify/dist/ReactToastify.css'

type newUser = {
  email: string
  role: string
  fullName: string
}

const emptyNewUser = {
  email: '',
  role: '',
  fullName: '',
}

export default function AddteamMembers() {
  const [formData, setFormData] = useState<newUser>(emptyNewUser)
  const [error, setError] = useState({
    name: '',
    email: '',
    role: '',
    submissionError: '',
  })
  const [isLoading, setIsLoading] = useState(false)
  const navigate = useNavigate()
  const validateEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

    return emailRegex.test(email)
  }
  const submitUser = async () => {
    let isFormValid = true

    if (!(formData.email && formData.role && formData.fullName)) {
      isFormValid = false
      setError({
        name: 'Full Name is Required',
        email: 'Email is Required',
        role: 'Please Select a Role',
        submissionError: '',
      })
    }
    if (formData.fullName === '') {
      setError((prevError) => ({...prevError, name: 'Full Name is Required'}))
      isFormValid = false
    }
    if (!validateEmail(formData.email)) {
      isFormValid = false
      setError((prevError) => ({...prevError, email: 'Please Enter a Valid Email'}))
    }
    if (formData.role === '') {
      isFormValid = false
      setError((prevError) => ({...prevError, role: 'Please select a Role'}))
    } else if (isFormValid) {
      setError({
        name: '',
        email: '',
        role: '',
        submissionError: '',
      })
      setIsLoading(true)
      await axios
        .post(`${process.env.REACT_APP_API_URL}/users/admin/invite-user`, {
          email: formData.email,
          userRole: formData.role,
          fullName: formData.fullName,
        })
        .then((res) => {
          if (res.statusText === 'OK') {
            setIsLoading(false)
            toast.success('User Added Successfully', {
              position: 'top-center',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: 'colored',
            })
            setTimeout(() => {
              navigate('/manageusers')
            }, 2000)
          }
        })
        .catch((error) => {
          setIsLoading(false)
          setError((prevError) => ({...prevError, submissionError: error.response.data}))
          toast.error(`${error.response.data}`, {
            position: 'top-center',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: 'colored',
          })
        })
    }
  }
  return (
    <>
      <div className='bg-white pb-10'>
        <div className='d-flex flex-wrap align-items-baseline bg-secondary px-2'>
          <h4 className='py-3 ms-1 px-1 fs-1'>+</h4>
          <h3 className='py-3 ms-1  '>Add Team Members</h3>
        </div>
        <div className='d-flex flex-column flex-wrap my-5 gap-3 p-10 pb-10 align-content-center'>
          <div className='mb-2'>
            <label className='form-label'>Full Name : </label>
            <input
              maxLength={250}
              type='text'
              value={formData.fullName}
              className='form-control form-control-solid input-width h-40px px-5'
              placeholder='Enter full name'
              name='fullName'
              onChange={(e) => {
                setFormData({...formData, [e.target.name]: e.target.value.trimStart()})
                setError((prevError) => ({...prevError, name: ''}))
              }}
            />
            {!!error.name && <div className='text-danger'>{error.name}</div>}
          </div>

          <div className='mb-2'>
            <label className='form-label'>Email Address : </label>
            <input
              type='email'
              value={formData.email}
              className='form-control form-control-solid input-width h-40px px-5'
              placeholder='Enter email'
              name='email'
              onChange={(e) => {
                setFormData({...formData, [e.target.name]: e.target.value.trim()})
                setError((prevError) => ({...prevError, email: ''}))
              }}
            />
            {!!error.email && <div className='text-danger'>{error.email}</div>}
          </div>
          <label className='form-label col-xl-2 col-lg-2'>Select Role : </label>
          <select
            className='form-select form-select-solid input-width h-40px px-5'
            aria-label='Select role'
            value={formData.role}
            name='role'
            onChange={(e) => {
              setFormData({...formData, [e.target.name]: e.target.value})
              setError((prevError) => ({...prevError, role: ''}))
            }}
          >
            <option>Select Role</option>
            <option value='0'>Admin</option>
            <option value='3'>Super Admin</option>
          </select>
          {!!error.role && <div className='text-danger'>{error.role}</div>}
        </div>
        <div className='d-flex flex-wrap align-items-center justify-content-center px-10 ms-5 gap-5 mb-5'>
          <button
            type='button'
            className='btn btn-primary fs-8 w-100px'
            onClick={submitUser}
            disabled={isLoading}
            style={{width: '100px'}}
          >
            {isLoading ? <Spinner /> : 'Add'}
          </button>
          <button type='button' className='btn btn-secondary fs-8 w-100px'>
            Cancel
          </button>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}
