import React, {useState} from 'react'
import Filter from './filter/Index'

const PipelineVisualization = () => {
  const [activeButton, setActiveButton] = useState('active')
  const [activeylyLooking, setActivelyLooking] = useState(true)
  const [activeCandidate, setActiveCandidate] = useState(true)
  const [archievedCandidate, setArchievedCandidate] = useState(false)
  const handleActiveCandidate = () => {
    if (!activeCandidate) {
      setActiveButton('active')
      setActiveCandidate(true)
      setArchievedCandidate(false)
    }
  }
  const handleArchieveCandidate = () => {
    if (!archievedCandidate) {
      setActiveButton('archive')
      setArchievedCandidate(true)
      setActiveCandidate(false)
    }
  }
  return (
    <>
      <div>
        <div className='d-flex gap-20 bg-white'>
          <div className='d-flex flex-wrap justify-content-between align-items-center'>
            <h3 className='p-4 text-gray-600'>Piepleline Visualization</h3>
          </div>
          <div className='d-flex align-items-center gap-3 text-gray-600 p-2'>
            <span> Total</span>
            <div className='search '>
              <input
                type='text'
                className='form-control input-area bg-gray-200 text-gray-600 fs-7'
                placeholder='Search...'
                //   value={searchInput}
                //   onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className='d-flex gap-4 p-5'>
          <div className='d-flex flex-column bg-white'>
            <Filter />
          </div>
          <div className='d-flex flex-column flex-grow-1 bg-white'>
            <div className='d-flex flex-column'>
              <div className='card card-custom'>
                <div className='card-header'>
                  <div className='d-flex align-items-center px-4 py-2 justify-content-between w-100'>
                    <div className='d-flex flex-column'>
                      <div className='d-flex'>
                        <div className='form-check form-switch form-check-custom form-check-solid'>
                          <input
                            className='form-check-input'
                            type='checkbox'
                            value=''
                            id='flexSwitchDefault'
                          />
                          <label className='form-check-label fs-5' htmlFor='flexSwitchDefault'>
                            <i className='bi bi-fire text-danger fs-4' /> Actively interviewing
                          </label>
                        </div>
                      </div>
                      {/* <div className='btn-group gap-3 p-3' role='group'>
                        <button
                          className='btn btn-active-info btn-sm'
                          onClick={handleActiveCandidate}
                        >
                          Active Candidates
                        </button>
                        <button
                          className='btn btn btn-active-info btn-sm border'
                          onClick={handleArchieveCandidate}
                        >
                          Archieved
                        </button>
                      </div> */}
                      <div className='btn-group gap-3 p-3' role='group'>
                        <button
                          className={`btn btn-sm ${
                            activeButton === 'active' ? 'btn-active-info' : ''
                          }`}
                          onClick={handleActiveCandidate}
                        >
                          Active Candidates
                        </button>
                        <button
                          className={`btn btn-sm ${
                            activeButton === 'archive' ? 'btn-active-info' : ''
                          } border`}
                          onClick={handleArchieveCandidate}
                        >
                          Archived
                        </button>
                      </div>
                    </div>
                    <div className='d-flex flex-column'>
                      <div className='d-flex align-items-center'>
                        <span className='text-gray-600 fs-5 col-xl-10'>On time</span>
                        <div className='ms-1 bg-success p-1 rounded-pill' style={{width: '30px'}} />
                      </div>
                      <div className='d-flex align-items-center'>
                        <span className='text-gray-600 fs-5 col-xl-10'>Need Attention</span>
                        <div className='ms-1 bg-warning p-1 rounded-pill' style={{width: '30px'}} />
                      </div>
                      <div className='d-flex align-items-center'>
                        <span className='text-gray-600 fs-5 col-xl-10'>Behind Schedule</span>
                        <div className='ms-1 bg-danger p-1 rounded-pill' style={{width: '30px'}} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className='card-body py-0'>
                  {activeCandidate && (
                    <table className='table table-row-dashed table-row-gray-300 gy-2 '>
                      <thead>
                        <tr className='fw-bold fs-6 text-gray-800'>
                          <th>Candidate Name</th>
                          <th>Submission Date</th>
                          <th>Awaiting Review</th>
                          <th>Interview Backlog</th>
                          <th>Onsite Backlog</th>
                          <th>Offer Backlog</th>
                          <th>Days in Pipeline</th>
                        </tr>
                      </thead>
                      <tbody>
                        {['1', '2', '3', '4', '5', '6'].map((item, i) => (
                          <tr key={i}>
                            <td>
                              <div className='d-flex justify-content-between'>
                                Tiger Nixon{' '}
                                {activeylyLooking && <i className='bi bi-fire text-danger fs-4' />}
                              </div>
                            </td>
                            <td>10/22/22</td>
                            <td>
                              6days{' '}
                              <div>
                                <div
                                  className='progress '
                                  role='progressbar'
                                  aria-label='Example 1px high'
                                  aria-valuenow={25}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                >
                                  <div className='progress-bar bg-danger ' style={{width: '50%'}} />
                                </div>
                              </div>
                            </td>
                            <td>
                              6days{' '}
                              <div>
                                <div
                                  className='progress'
                                  role='progressbar'
                                  aria-label='Example 1px high'
                                  aria-valuenow={25}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                >
                                  <div
                                    className='progress-bar bg-success '
                                    style={{width: '50%'}}
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              6days{' '}
                              <div>
                                <div
                                  className='progress'
                                  role='progressbar'
                                  aria-label='Example 1px high'
                                  aria-valuenow={25}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                >
                                  <div
                                    className='progress-bar bg-warning '
                                    style={{width: '50%'}}
                                  />
                                </div>
                              </div>
                            </td>
                            <td>
                              6days{' '}
                              <div>
                                <div
                                  className='progress'
                                  role='progressbar'
                                  aria-label='Example 1px high'
                                  aria-valuenow={25}
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                >
                                  <div className='progress-bar bg-danger ' style={{width: '50%'}} />
                                </div>
                              </div>
                            </td>
                            <td>42 days</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}

                  {archievedCandidate && (
                    <table className='table table-row-dashed table-row-gray-300 gy-2 '>
                      <thead>
                        <tr className='fw-bold fs-6 text-gray-800'>
                          <th>Candidate Name</th>
                          <th>Archieve stage</th>
                          <th>Time in Pipeline</th>
                          <th>Company</th>
                          <th>University</th>
                          <th> Degree</th>
                          <th>Level</th>
                          <th>Job Function</th>
                          <th>Highlights</th>
                        </tr>
                      </thead>
                      <tbody>
                        {['1', '2', '3', '4', '5', '6'].map((item, i) => (
                          <tr key={i}>
                            <td>
                              <div className='d-flex justify-content-between'>
                                Gokui Gi
                                {activeylyLooking && <i className='bi bi-fire text-danger fs-4' />}
                              </div>
                            </td>
                            <td>Pass- Tech Screen</td>
                            <td>10days</td>
                            <td>Instagram</td>
                            <td>Stanford</td>
                            <td>Ph.D</td>
                            <td>P3</td>
                            <td>Data Scie.</td>
                            <td>Healthcare</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default PipelineVisualization
