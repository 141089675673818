import {useAuth} from '../../modules/auth'
import React, {useEffect, useRef, useState} from 'react'
import {useNavigate} from 'react-router-dom'
import noImage from '../../../_metronic/assets/img/noImage.png'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import {useLocation} from 'react-router-dom'

import axios from 'axios'
import {string} from 'yup'
import {useUserStore} from '../../../store/user'
import {useMediaQuery} from 'react-responsive'
import './Candidatecard.css'
import {scrollToTopOfScrollable} from '../../../utils/helper/helper'
interface CandidateCardProps {
  data: {
    id: string
    fullName: string
    firstName: string
    middleName: string
    lastName: string
    email: string
    password: string
    phoneNumber: string
    is_admin: boolean
    isDeleted: boolean
    isAlternateLoginSourceEnabled: boolean
    userLocation: userLocationType
    dateOfBirth: string
    educationList: EducationType[]
    positionList: postitionListType[]
    interestedJobFunctions: [string]
    miscellaneousDetails: miscellaneousDetailsType
    preferredWorkplaceLocation: []
    userType: string
    isProfileComplete: boolean
    profilePicture: string
    profileVideo: string
    jobSearch: string
    seniorityLevel: string
    isWorkOnSite: boolean
    isWorkHybrid: boolean
    isWorkRemotely: boolean
    workVisaRequired: string
    nonImmigrantVisaStatus: string
    desiredSalary: string
    whenYouCanStart: string
    customJoiningDate: string
    isVerify: boolean
    roleName: string
    positionName: string
    parentId: string
    companyId: string
    location: string
    userId: string
    jobTitle: string
    companyName: string
    latestDegree: string
    schoolName: string

    talentStatus: boolean
  }
  width?: string
}
interface EducationType {
  degree: string
  fieldOfStudy: string
  year: string
  schoolName: string
}
interface userLocationType {
  address: any
  city: string
  country: string
  id: string
  state: string
}
interface miscellaneousDetailsType {
  shortBio: string
  achievement: [string]
  spokenLanguagesList: [string]
  interest: [string]
  linkedinProfileLink: string
  duties: [string]
}

const emptyuserLocationType = {
  address: '',
  city: '',
  country: '',
  id: '',
  state: '',
}
const emptyEducation = {
  degree: '',
  fieldOfStudy: '',
  year: '',
  schoolName: '',
}
interface postitionListType {
  companyName: string
  employmentType: string
  startDate: string
  managedPeople: string
  jobTitle: string
  jobFunc: string[]
  jobLocation: string
  position: string
  monthsWorked: string
}

const EmptyPositionlist = {
  companyName: '',
  employmentType: '',
  startDate: '',
  managedPeople: '',
  jobTitle: '',
  jobFunc: [],
  jobLocation: '',
  position: '',
  monthsWorked: '',
}

export default function CandidateCard({
  data,
  handleSelected,
  employerId,
  setCandidateData,
  companyId,
}: any) {
  const isMobileView = useMediaQuery({maxWidth: 375})
  const menuRef = useRef<any>(null)

  const {user} = useUserStore()
  const {currentUser} = useAuth()
  const location = useLocation()
  const isTotalCompanyCandidatesListPage = location.pathname === '/totalCompanyCandidatesList'
  const navigate = useNavigate()
  const locationUser: userLocationType = data?.userLocation
    ? data?.userLocation
    : emptyuserLocationType

  const [isMenuOpen, setMenuOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [isLoginAsLoading, setIsLoginAsLoading] = useState(false)
  const candidateId = data?.userId

  const [currentPage, setCurrentPage] = useState(0)
  const itemsPerPage = 10
  const pageOffset = currentPage * itemsPerPage

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen)
  }

  const handleLoginAs = () => {
    if (!isLoginAsLoading) {
      if (user?.userType === 'ADMIN') {
        toast.error('User has no access', {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: 'colored',
        })
      } else if (user?.userType === 'SUPER') {
        setIsLoginAsLoading(true)
        axios
          .get(`${process.env.REACT_APP_API_URL}/users/admin/login-as?userId=${candidateId}`)
          .then((res) => {
            setIsLoginAsLoading(false)
            let token = res.data?.token
            if (token) {
              try {
                let url = `https://app.moon.ac/loginAs?token=${token}`
                window.open(url, '_blank', 'noopener,noreferrer')
              } catch (error) {
                console.error('Error opening a new window:', error)
              }
            } else {
              toast.error('Token is missing in the response', {
                position: 'top-center',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: 'colored',
              })
            }
          })
          .catch((error) => {
            setIsLoginAsLoading(false)

            console.error('ERROR', error)

            toast.error(`Error: ${error.message}`, {
              position: 'top-center',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: 'colored',
            })
          })
      }
    }
  }

  const handleRevoke = () => {
    if (!isLoading) {
      axios
        .put(`${process.env.REACT_APP_API_URL}/users/admin/revoke-candidate`, {
          companyId: companyId,
          candidateId: candidateId,
        })
        .then((res) => {
          // Display a toast message after successfully revoking the candidate
          toast.success('Candidate revoked successfully!', {
            position: 'top-center',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: 'colored',
          })

          axios
            .get(
              `${process.env.REACT_APP_API_URL}/users/admin/company-candidates?companyId=${companyId}&page=${pageOffset}&limit=${itemsPerPage}`
            )
            .then((response) => {
              setCandidateData(response.data ? response.data : [])
            })
            .catch((getError) => {
              console.error('Error making GET request:', getError)
            })
        })
        .catch((error) => {
          console.error('Error revoking candidate:', error)

          // Display an error toast message if there's an error while revoking
          toast.error(`Error revoking candidate: ${error.message}`, {
            position: 'top-center',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: 'colored',
          })
        })
    }
  }
  function handleClickOutside(event: MouseEvent) {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setMenuOpen(false)
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  return (
    <div className='d-flex candidate-card-width'>
      <div className='col'>
        <div className='card card-custom '>
          <div className='d-flex justify-content-between' style={{position: 'relative'}}>
            {data?.isActivelyInterviewing && (
              <div className='ribbon-label bg-danger active-candidate-icon'>
                <i className='bi bi-fire text-white fs-3' />
              </div>
            )}
            {location?.pathname === '/assignCandidates' && (
              <div className='d-flex'>
                <input
                  type='checkbox'
                  className='checkbox'
                  onChange={() => handleSelected(data?.userId)}
                />
              </div>
            )}
            <div className='bi bi-three-dots text-info' onClick={toggleMenu}>
              <ul className={`dot-menu ${isMenuOpen ? 'show-menu' : ''}`} ref={menuRef}>
                <li
                  className='text-hover-inverse-secondary bg-hover-secondary menu-font'
                  onClick={handleLoginAs}
                >
                  Login As
                </li>
                {isTotalCompanyCandidatesListPage && (
                  <li
                    className='text-hover-inverse-secondary bg-hover-secondary menu-font'
                    onClick={handleRevoke}
                  >
                    Revoke
                  </li>
                )}
              </ul>
            </div>
          </div>
          <div className='card-body candidate-card-height mt-5'>
            <div className='d-flex flex-column gap-3'>
              <div className='d-flex flex-row justify-content-start align-items-start gap-3'>
                <img
                  src={data?.profilePicture || noImage}
                  className='h-95px w-95px border border-3 border-danger rounded-circle'
                  alt='Profile Image '
                />
                <div className='d-flex flex-column align-items-start flex-grow-1'>
                  <h4 className='text-dark card-heading'>
                    {' '}
                    {data?.fullName?.length > 30
                      ? data?.fullName.slice(0, 30) + '...'
                      : data?.fullName}
                  </h4>

                  {data.jobTitle && (
                    <div className='d-flex align-items-center '>
                      {/* <i className='fa-brands fa-linkedin fs-2 me-2' /> */}
                      <div className='d-flex flex-row '>
                        <div className='text-dark me-2'>
                          {data?.jobTitle?.length > 20
                            ? data?.jobTitle.slice(0, 16) + '...'
                            : data?.jobTitle}{' '}
                          @{' '}
                          {data?.companyName?.length > 20
                            ? data?.companyName.slice(0, 16) + '...'
                            : data?.companyName}
                        </div>
                      </div>
                    </div>
                  )}

                  {data.latestDegree && (
                    <div className='d-flex flex-row card-heading2 align-items-start'>
                      <i className='fa-solid fa-graduation-cap fs-5 me-2' />
                      <div className='d-flex text-dark me-2 flex-wrap'>
                        <div className='text-dark'>{data.latestDegree}</div>
                        <div className='text-dark'>
                          {' '}
                          ,
                          {data?.schoolName?.length > 20
                            ? data?.schoolName.slice(0, 16) + '...'
                            : data?.schoolName}
                        </div>
                      </div>
                    </div>
                  )}

                  {locationUser && locationUser?.address && (
                    <div className='d-flex align-items-start '>
                      <i className='bi bi-geo-alt fs-5 me-1' />
                      <div className='text-dark me-2'>
                        {JSON.parse(locationUser?.address)?.label}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {data?.interestedJobFunctions.length > 0 && (
                <div className='d-flex flex-row align-items-start gap-2 pt-5'>
                  <i className='bi bi-award fs-1' />
                  <div className='d-flex flex-row flex-wrap gap-2'>
                    {data.interestedJobFunctions.map((option: any) =>
                      isMobileView ? (
                        <span
                          className='badge badge-warning badges-text p-1 fs-9'
                          style={{
                            whiteSpace: 'normal',
                            textAlign: 'start',
                            wordBreak: 'break-word',
                          }}
                        >
                          {option?.length > 30 ? option.slice(0, 30) + '...' : option}
                        </span>
                      ) : (
                        <span
                          className='badge badge-warning badges-text p-2'
                          style={{
                            whiteSpace: 'normal',
                            textAlign: 'start',
                            wordBreak: 'break-word',
                          }}
                        >
                          {option?.length > 30 ? option.slice(0, 30) + '...' : option}
                        </span>
                      )
                    )}
                  </div>
                </div>
              )}

              {/* <div className='flex-row-fluid border border-3 border-secondary p-4 mt-2'>
                This is a short bio , for testing purpose only to check the card height only
              </div> */}
            </div>
          </div>
          <div className='card-footer pt-0'>
            <button
              onClick={() => {
                navigate(`/viewcandidatedetails/${data?.userId}`, {
                  state: {
                    data: data,
                  },
                })
              }}
              className='btn btn-light-info border-1 w-100 py-5 mt-8 '
            >
              View
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}
