import {useCallback, useEffect, useState} from 'react'
import axios from 'axios'

import EmployeeListTable from './partials/employeeListTable'
import {ToastContainer, toast} from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
export interface Employee {
  userId: string
  firstName: string
  middleName: string
  lastName: string
  fullName: string
  email: string
  phoneNumber: string | null
  userType: string
  profilePicture: string | null
  profileVideo: string | null
  roleName: string
  companyName: string
  createdAt: string | null
}

export default function ManageUsers() {
  const [employees, setEmployees] = useState<Employee[]>([])

  useEffect(() => {
    getAllUsers()
  }, [])

  const getAllUsers = async () => {
    const res = await axios.get(`${process.env.REACT_APP_API_URL}/users/admin/get-all-employ`)

    setEmployees(res.data)
  }

  const deleteUser = useCallback(async (idToDelete: string) => {
    const res = await axios.delete(
      `${process.env.REACT_APP_API_URL}/users/admin/user?userId=${idToDelete}`
    )
    if (res.statusText === 'OK') {
      setEmployees((prev) => prev.filter((emp) => emp.userId !== idToDelete))
      toast.success('User deleted Successfully', {
        position: 'top-center',
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: 'colored',
      })
    }
  }, [])

  return (
    <div className='bg-white'>
      <div className='d-flex flex-wrap align-items-baseline bg-secondary px-2'>
        <h3 className='py-3 ms-1 '>Team Members</h3>
      </div>
      <div className='px-2'>
        <EmployeeListTable employees={employees} deleteUser={deleteUser} />
      </div>
      <ToastContainer />
    </div>
  )
}
