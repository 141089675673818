import React, {useEffect, useState} from 'react'
import CompanyCard from './companyCard/companyCard'
import axios from 'axios'
import {Spinner} from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import EmployeesListCard from './employeesListCard'
import {useLocation} from 'react-router-dom'
import {scrollToTopOfScrollable} from '../../../utils/helper/helper'

const EmployeePage = () => {
  const location: any = useLocation()
  const compnayID = location?.state?.companyId
  const [companyList, setCompanyList] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)
  const [itemOffset, setItemOffset] = useState(0)
  const itemsPerPage = 12
  const [pageCount, setPageCount] = useState(Math.ceil(companyList?.length / itemsPerPage))
  const [searchInput, setSearchInput] = useState('')

  useEffect(() => {
    setIsLoading(true)

    axios
      .get(
        `${process.env.REACT_APP_API_URL}/users/admin/employers?query=${searchInput}&page=${itemOffset}&limit=${itemsPerPage}&companyId=${compnayID}`
      )
      .then((res) => {
        setIsLoading(false)

        setCompanyList(res?.data)
        setPageCount(res?.data?.totalPages || 1)
        scrollToTopOfScrollable()
      })
      .catch((error) => {
        setIsLoading(false)
        console.log('ERROR', error)
      })
  }, [itemOffset, searchInput])

  const handlePageClick = (data: any) => {
    const selectedPage = data.selected
    setItemOffset(selectedPage)
  }
  const handleChange = (e: any) => {
    e.preventDefault()
    let value = e.target.value
    setSearchInput(value.trimLeft())
    if (value.length && itemOffset !== 0) {
      setItemOffset(0)
    }
  }
  return (
    <div>
      <div className='d-flex justify-content-between  flex-column-reverse flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-sm-row bg-white mb-6 rounded px-5 '>
        <div className='d-flex align-items-center gap-3  p-3'>
          <span> {companyList?.totalElements} Total</span>
          <div className='search '>
            <input
              type='text'
              className='form-control input-area bg-grey'
              placeholder='Search...'
              value={searchInput}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className='card-header bg-white rounded p-2'>
        <h3 className='mx-5'>Employees</h3>
      </div>

      {!companyList?.companyEmployerResponseBodyList?.length ? (
        <div className='d-flex p-9 m-9 justify-content-center align-items-center fs-1  fw-bold'>
          {isLoading ? <Spinner /> : 'No Employee Found'}
        </div>
      ) : (
        <>
          <div className='d-flex flex-column h-100 justify-content-between minH-75'>
            <div className='d-flex justify-content-start flex-wrap flex-column flex-xxl-row flex-xl-row flex-lg-row flex-md-row p-1 mt-5 gap-3'>
              {companyList?.companyEmployerResponseBodyList &&
                companyList?.companyEmployerResponseBodyList?.map((company: any, index: number) => (
                  <EmployeesListCard data={company} key={index} companyId={compnayID} />
                ))}
            </div>

            <div className='d-flex w-100'>
              <ReactPaginate
                nextLabel='Next >'
                onPageChange={handlePageClick}
                // forcePage={itemOffset}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel='< Previous'
                pageClassName='page-item'
                pageLinkClassName='page-link'
                previousClassName='page-item'
                previousLinkClassName='page-link'
                nextClassName='page-item'
                nextLinkClassName='page-link'
                breakLabel='...'
                breakClassName='page-item'
                breakLinkClassName='page-link'
                containerClassName='pagination'
                activeClassName='active'
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default EmployeePage
