import React, {useEffect, useRef, useState} from 'react'
import noImage from '../../../../_metronic/assets/img/noImage.png'
import {useNavigate} from 'react-router-dom'
import '../company.css'
const CompanyCard = ({data}: any) => {
  const menuRef = useRef<any>()
  const navigate = useNavigate()
  const [isMenuOpen, setMenuOpen] = useState(false)
  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen)
  }
  function handleClickOutside(event: MouseEvent) {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setMenuOpen(false)
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <>
      <div className='d-flex p-1 w-100'>
        <div className='col'>
          <div className='card card-custom'>
            <div className='card-body '>
              <div className='bi bi-three-dots text-info showonMobile' onClick={toggleMenu}>
                <ul className={`dot-menu ${isMenuOpen ? 'show-menu' : ''}`} ref={menuRef}>
                  <li
                    className='text-hover-inverse-secondary bg-hover-secondary menu-font'
                    onClick={() => {
                      navigate('/employeesList', {
                        state: {
                          companyId: data.id,
                        },
                      })
                    }}
                  >
                    Employees List
                  </li>
                  <li
                    className='text-hover-inverse-secondary bg-hover-secondary menu-font'
                    onClick={() => {
                      navigate('/totalCompanyCandidatesList', {
                        state: {
                          companyId: data.id,
                        },
                      })
                    }}
                  >
                    Candidates List
                  </li>
                  <li
                    className='text-hover-inverse-secondary bg-hover-secondary menu-font'
                    onClick={() => {
                      navigate('/assignCandidates', {
                        state: {
                          companyId: data?.id,
                        },
                      })
                    }}
                  >
                    Assign Candidates
                  </li>
                </ul>
              </div>
              <div
                className='company-card-body justify-content-start align-items-start gap-3'
                style={{width: '100% !important'}}
              >
                <img
                  src={`${data?.companyLogo ? data?.companyLogo : 'https://placehold.co/100'}`}
                  className='company-image rounded'
                  alt='Profile Image '
                />
                <div
                  className='d-flex flex-column flex-wrap align-items-start flex-grow-1'
                  style={{width: '100% !important'}}
                >
                  <div className='d-flex align-items-start gap-4 justify-content-between full-width '>
                    <div className='d-flex flex-column'>
                      <h4 className='text-dark card-heading text-hover-info'>{data?.name}</h4>
                      <div className='d-flex gap-2 flex-wrap'>
                        <div className='d-flex align-items-baseline gap-2'>
                          <i className='bi bi-envelope' />
                          <p className='text-dark text-hover-info'>{data?.createdByEmail}</p>
                        </div>
                        <div className='d-flex align-items-baseline gap-2'>
                          <i className='bi bi-person-rolodex' />
                          <p className='text-dark text-hover-info'>{data?.createdByName}</p>
                        </div>
                      </div>
                    </div>
                    <div className='d-flex showonLgScreen flex-wrap flex-column flex-xxl-row flex-xl-row flex-lg-row flex-md-row align-items-end justify-content-end gap-3 p-2'>
                      <button
                        className='btn btn-secondary btn-sm'
                        onClick={() => {
                          navigate('/employeesList', {
                            state: {
                              companyId: data.id,
                            },
                          })
                        }}
                      >
                        Employees List
                      </button>
                      <button
                        className='btn btn-secondary btn-sm'
                        onClick={() => {
                          navigate('/totalCompanyCandidatesList', {
                            state: {
                              companyId: data.id,
                            },
                          })
                        }}
                      >
                        Candidates List
                      </button>
                      <button
                        className='btn btn-info btn-sm'
                        onClick={() => {
                          navigate('/assignCandidates', {
                            state: {
                              companyId: data?.id,
                            },
                          })
                        }}
                      >
                        Assign Candidates
                      </button>
                    </div>
                    {/* <div className='d-flex align-items-baseline gap-2'>
                        <i className='bi bi-pin-map' />
                        <p className='text-dark text-hover-info cursor'>Melbourne </p>
                      </div> */}
                  </div>
                </div>
              </div>
            </div>

            <div className='card-footer'>
              <div className='d-flex flex-wrap gap-4 justify-content-center'>
                <div className='d-flex align-items-baseline gap-2'>
                  <i className='bi bi-person text-info fs-3' />
                  <p className='text-info fw-bold'>Total Candidates: </p>
                  <p className='text-dark ms-1'>{data?.totalAssignedCandidates} </p>
                </div>
                <div className='d-flex align-items-baseline gap-2'>
                  <i className='bi bi-person text-info fs-3' />
                  <p className='text-info fw-bold'>Total Employees: </p>
                  <p className='text-dark ms-1'> {data?.totalEmployer} </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CompanyCard
