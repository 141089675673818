import React from 'react'
import CardCheckbox from '../../CardCheckbox'
import {useSelectOptions} from '../../HandleHook'
import './filter.css'
const Filter = () => {
  const StageOptions = ['Review', 'Interview', 'Onsite', 'Offer']

  const WorkPermitOptions = ['No need', 'H1B transfer', 'OPT/CPT']
  const JobfunctionOptions = [
    'Data Scientist',
    'Data Engineer',
    'Machine Learning',
    'Product Manager',
  ]
  const SeniorityLevelOptions = ['Junior', 'Mid-Level', 'Senior', 'Manager']
  const LocationOptions = ['Remote', 'NYC', 'Boston', 'SF']
  const JobsOptions = ['Full Time', 'Hybrid', 'Remote']
  const ClientCompanyOptions = [' google', 'Facebook ', 'LinkEdin']
  const HiringManagerOptions = ['Jay', 'Steve', 'Robert']
  const HopTeamOptions = ['Michel ', 'Alex ', 'Bryan ']
  ///
  const [StageFunction, handleStageFuction] = useSelectOptions(StageOptions)
  const [Jobfunction, handleJobFunction, handleJobFunctionAlls] =
    useSelectOptions(JobfunctionOptions)
  const [WorkPermitFunction] = useSelectOptions(WorkPermitOptions)
  const [LocationFunction, handleLocationFunction, handleLocationFunctionAlls] =
    useSelectOptions(LocationOptions)
  const [SeniorityLevelfunction] = useSelectOptions(SeniorityLevelOptions)
  const [Jobsfunction, handleJobsFunction, handleJobsFunctionAlls] = useSelectOptions(JobsOptions)
  const [ClientCompanyfunction, handleClientCompanyFunction, handleClientCompanyFunctionAlls] =
    useSelectOptions(ClientCompanyOptions)
  const [HiringManagerfunction, handleHiringManagerFunction, handleHiringManagerFunctionAlls] =
    useSelectOptions(HiringManagerOptions)
  const [HopTeamfunction, handleHopTeamFunction, handleHopTeamFunctionAlls] =
    useSelectOptions(HopTeamOptions)

  return (
    <>
      <div className='d-flex flex-column'>
        <div className='card card-custom'>
          <div className='card-header'>
            <div className='d-flex align-items-center p-4 fs-4 text-gray'>
              <i className='bi bi-funnel-fill fs-3 me-1' />
              <span className='text-gray-700 fw-bold'>Filter</span>
            </div>
          </div>
          <div className='card-body'>
            <div className='accordion  accordion-flush' id='kt_accordion_1'>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='kt_accordion_1_header_3'>
                  <button
                    className='accordion-button fs-4 fw-bold collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_accordion_1_body_3'
                    aria-expanded='false'
                    aria-controls='kt_accordion_1_body_3'
                  >
                    Job Functionss
                  </button>
                </h2>
                <div
                  id='kt_accordion_1_body_3'
                  className='accordion-collapse collapse'
                  aria-labelledby='kt_accordion_1_header_3'
                  data-bs-parent='#kt_accordion_1'
                >
                  {['1', '2', '1', '2', '1', '2', '1', '2'].map((item: any, i: any) => (
                    <div className='d-flex flex-wrap'>
                      <div className='d-flex card-badge m-1 px-3 py-1 align-items-center'>
                        <span key={i}>Data Scientist </span>
                        <i className='bi bi-x fs-2 cursor' />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='kt_accordion_1_header_2'>
                  <button
                    className='accordion-button fs-4 fw-bold collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_accordion_1_body_2'
                    aria-expanded='false'
                    aria-controls='kt_accordion_1_body_2'
                  >
                    Seniority
                  </button>
                </h2>
                <div
                  id='kt_accordion_1_body_2'
                  className='accordion-collapse collapse'
                  aria-labelledby='kt_accordion_1_header_2'
                  data-bs-parent='#kt_accordion_1'
                >
                  <div className='accordion-body'>
                    {['New grad', 'Mid-Level', 'Director'].map((item: any, i: any) => (
                      <div className='d-flex flex-wrap'>
                        <div className='d-flex card-badge m-1 px-3 py-1 align-items-center'>
                          <span key={i}>{item} </span>
                          <i className='bi bi-x fs-2 cursor' />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='kt_accordion_1_header_3'>
                  <button
                    className='accordion-button fs-4 fw-bold collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_accordion_1_body_3'
                    aria-expanded='false'
                    aria-controls='kt_accordion_1_body_3'
                  >
                    Domain
                  </button>
                </h2>
                <div
                  id='kt_accordion_1_body_3'
                  className='accordion-collapse collapse'
                  aria-labelledby='kt_accordion_1_header_3'
                  data-bs-parent='#kt_accordion_1'
                >
                  <div className='accordion-body'>
                    {['New grad', 'Mid-Level', 'Director'].map((item: any, i: any) => (
                      <div className='d-flex flex-wrap'>
                        <div className='d-flex card-badge m-1 px-3 py-1 align-items-center'>
                          <span key={i}>{item} </span>
                          <i className='bi bi-x fs-2 cursor' />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='kt_accordion_1_header_3'>
                  <button
                    className='accordion-button fs-4 fw-bold collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_accordion_1_body_4'
                    aria-expanded='false'
                    aria-controls='kt_accordion_1_body_4'
                  >
                    Skills
                  </button>
                </h2>
                <div
                  id='kt_accordion_1_body_4'
                  className='accordion-collapse collapse'
                  aria-labelledby='kt_accordion_1_header_4'
                  data-bs-parent='#kt_accordion_1'
                >
                  <div className='accordion-body'>
                    {['New grad', 'Mid-Level', 'Director'].map((item: any, i: any) => (
                      <div className='d-flex flex-wrap'>
                        <div className='d-flex card-badge m-1 px-3 py-1 align-items-center'>
                          <span key={i}>{item} </span>
                          <i className='bi bi-x fs-2 cursor' />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='kt_accordion_1_header_3'>
                  <button
                    className='accordion-button fs-4 fw-bold collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_accordion_1_body_5'
                    aria-expanded='false'
                    aria-controls='kt_accordion_1_body_5'
                  >
                    Work Permit
                  </button>
                </h2>
                <div
                  id='kt_accordion_1_body_5'
                  className='accordion-collapse collapse'
                  aria-labelledby='kt_accordion_1_header_5'
                  data-bs-parent='#kt_accordion_5'
                >
                  <div className='accordion-body'>
                    {['New grad', 'Mid-Level', 'Director'].map((item: any, i: any) => (
                      <div className='d-flex flex-wrap'>
                        <div className='d-flex card-badge m-1 px-3 py-1 align-items-center'>
                          <span key={i}>{item} </span>
                          <i className='bi bi-x fs-2 cursor' />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='kt_accordion_1_header_6'>
                  <button
                    className='accordion-button fs-4 fw-bold collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_accordion_1_body_6'
                    aria-expanded='false'
                    aria-controls='kt_accordion_1_body_6'
                  >
                    Location
                  </button>
                </h2>
                <div
                  id='kt_accordion_1_body_6'
                  className='accordion-collapse collapse'
                  aria-labelledby='kt_accordion_1_header_6'
                  data-bs-parent='#kt_accordion_6'
                >
                  <div className='accordion-body'>
                    {['New grad', 'Mid-Level', 'Director'].map((item: any, i: any) => (
                      <div className='d-flex flex-wrap'>
                        <div className='d-flex card-badge m-1 px-3 py-1 align-items-center'>
                          <span key={i}>{item} </span>
                          <i className='bi bi-x fs-2 cursor' />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='kt_accordion_1_header_7'>
                  <button
                    className='accordion-button fs-4 fw-bold collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_accordion_1_body_7'
                    aria-expanded='false'
                    aria-controls='kt_accordion_1_body_7'
                  >
                    Stage
                  </button>
                </h2>
                <div
                  id='kt_accordion_1_body_3'
                  className='accordion-collapse collapse'
                  aria-labelledby='kt_accordion_1_header_7'
                  data-bs-parent='#kt_accordion_1'
                >
                  <div className='accordion-body'>
                    {['New grad', 'Mid-Level', 'Director'].map((item: any, i: any) => (
                      <div className='d-flex flex-wrap'>
                        <div className='d-flex card-badge m-1 px-3 py-1 align-items-center'>
                          <span key={i}>{item} </span>
                          <i className='bi bi-x fs-2 cursor' />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='kt_accordion_1_header_3'>
                  <button
                    className='accordion-button fs-4 fw-bold collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_accordion_1_body_8'
                    aria-expanded='false'
                    aria-controls='kt_accordion_1_body_8'
                  >
                    Recuriter
                  </button>
                </h2>
                <div
                  id='kt_accordion_1_body_3'
                  className='accordion-collapse collapse'
                  aria-labelledby='kt_accordion_1_header_8'
                  data-bs-parent='#kt_accordion_1'
                >
                  <div className='accordion-body'>
                    {['New grad', 'Mid-Level', 'Director'].map((item: any, i: any) => (
                      <div className='d-flex flex-wrap'>
                        <div className='d-flex card-badge m-1 px-3 py-1 align-items-center'>
                          <span key={i}>{item} </span>
                          <i className='bi bi-x fs-2 cursor' />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className='accordion-item'>
                <h2 className='accordion-header' id='kt_accordion_1_header_3'>
                  <button
                    className='accordion-button fs-4 fw-bold collapsed'
                    type='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_accordion_1_body_9'
                    aria-expanded='false'
                    aria-controls='kt_accordion_1_body_9'
                  >
                    Hiring Manager
                  </button>
                </h2>
                <div
                  id='kt_accordion_1_body_3'
                  className='accordion-collapse collapse'
                  aria-labelledby='kt_accordion_1_header_9'
                  data-bs-parent='#kt_accordion_1'
                >
                  <div className='accordion-body'>
                    {['New grad', 'Mid-Level', 'Director'].map((item: any, i: any) => (
                      <div className='d-flex flex-wrap'>
                        <div className='d-flex card-badge m-1 px-3 py-1 align-items-center'>
                          <span key={i}>{item} </span>
                          <i className='bi bi-x fs-2 cursor' />
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Filter
