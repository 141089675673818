/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {BsBagPlus} from 'react-icons/bs'
const SidebarMenuMain = () => {
  const intl = useIntl()

  return (
    <>
      <SidebarMenuItem
        to='/yourprofile'
        icon='bi bi-person-lines-fill'
        title='Profile'
        fontIcon='bi-app-indicator'
      />

      <SidebarMenuItemWithSub to='/reportpage' icon='bi bi-bag-plus' title='Jobs'>
        <SidebarMenuItem to='/NewJobPage' title='New Jobs' hasBullet={true} />
        <SidebarMenuItem to='/ApproveJobPage' title='All Approved Jobs' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='/reportpage' icon='bi bi-people-fill' title='Talent'>
        <SidebarMenuItem
          to='/candidatemain'
          // icon='bi bi-people-fill'
          title='Approved Candidates'
          fontIcon='bi-app-indicator'
          hasBullet={true}
        />
        <SidebarMenuItem
          to='/applicantmain'
          // icon='bi bi-bag-plus'
          title='New Applicants'
          fontIcon='bi-app-indicator'
          hasBullet={true}
        />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/company'
        icon='bi bi-buildings-fill'
        title='Companies'
        fontIcon='bi-app-indicator'
      />
      {/* <SidebarMenuItemWithSub to='/reportpage' icon='bi bi-pie-chart-fill' title='Reports'>
        <SidebarMenuItem
          to='/PipelineVisualization'
          title='Pipleline Visualization'
          hasBullet={true}
        />
        <SidebarMenuItem to='/pipeline' title='Pipleline' hasBullet={true} />
        <SidebarMenuItem to='/volume' title='Volume' hasBullet={true} />
        <SidebarMenuItem to='/speed' title='Speed' hasBullet={true} />
        <SidebarMenuItem to='/quality' title='Quality' hasBullet={true} />
        <SidebarMenuItem to='/forecasting' title='Forecasting' hasBullet={true} />
      </SidebarMenuItemWithSub> */}

      <SidebarMenuItemWithSub
        to='/superadminmain'
        icon='bi bi-person-lines-fill'
        title='Super Admin'
      >
        {/* <SidebarMenuItem to='/workdistribution' title='Work Distribution' hasBullet={true} /> */}
        <SidebarMenuItem to='/addteammembers' title='Add Team Members' hasBullet={true} />
        <SidebarMenuItem to='/manageusers' title='Manage Users' hasBullet={true} />
      </SidebarMenuItemWithSub>

      <SidebarMenuItem
        to='/chatmain'
        icon='bi bi-chat-left-text-fill'
        title='Chat'
        fontIcon='bi-app-indicator'
      />
      {/* <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      <SidebarMenuItem
        to='/profile'
        icon='element-11'
        title='Profile'
        fontIcon='bi-app-indicator'
      /> */}

      {/* <SidebarMenuItem to='/builder' icon='switch' title='Layout Builder' fontIcon='bi-layers' />
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Crafted</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='element-plus'
      >
        <SidebarMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <SidebarMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <SidebarMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <SidebarMenuItem
            to='/crafted/pages/profile/campaigns'
            title='Campaigns'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/documents'
            title='Documents'
            hasBullet={true}
          />
          <SidebarMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </SidebarMenuItemWithSub>

        <SidebarMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <SidebarMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <SidebarMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </SidebarMenuItemWithSub>
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='profile-circle'
        fontIcon='bi-person'
      >
        <SidebarMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <SidebarMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub to='/error' title='Errors' fontIcon='bi-sticky' icon='cross-circle'>
        <SidebarMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <SidebarMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='element-7'
        fontIcon='bi-layers'
      >
        <SidebarMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <SidebarMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div>
      <SidebarMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='message-text-2'
      >
        <SidebarMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <SidebarMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </SidebarMenuItemWithSub>
      <SidebarMenuItem
        to='/apps/user-management/users'
        icon='abstract-28'
        title='User management'
        fontIcon='bi-layers'
      /> */}
      {/* <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTIcon iconName='code' className='fs-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}

export {SidebarMenuMain}
