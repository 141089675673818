import React, {ChangeEvent, useState} from 'react'
import {useAuth} from '../../modules/auth'
import noImage from '../../../_metronic/assets/img/noImage.png'
import axios from 'axios'

import {Bounce, ToastContainer, toast} from 'react-toastify'
import {useUserStore} from '../../../store/user'
import AWS from 'aws-sdk'

import './yourprofile.css'
import 'react-toastify/dist/ReactToastify.css'

export default function YourProfile() {
  const {currentUser} = useAuth()
  const {user, setUser} = useUserStore()
  const [name, setName] = useState(`${user?.fullName}`)
  const [designation, setDesignation] = useState(user?.positionName || undefined)
  const [file, setFile] = useState<File | null>(null)
  const [imageUrl, setImageUrl] = useState(user?.profilePicture)
  const [showName, setShowName] = useState(true)
  const [showDesignation, setShowDesignation] = useState(true)
  const imageStyle = {
    borderRadius: '50%',
  }

  const handleFileInputChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file: any = event.target.files?.[0]
      if (!file) {
        toast.error('No file selected.', {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
          transition: Bounce,
        })
        return
      }
      const fileExtension = file.name.split('.').pop().toLowerCase()
      const supportedExtensions = ['png', 'jpeg', 'jpg', 'svg']
      if (!supportedExtensions.includes(fileExtension)) {
        toast.error('Unsupported file type. Please select a PNG, JPEG, JPG, or SVG file.', {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'colored',
          transition: Bounce,
        })
        return
      }

      const S3_BUCKET = process.env.REACT_APP_S3_BUCKET || ''
      const REGION = process.env.REACT_APP_REGION

      AWS.config.update({
        accessKeyId: process.env.REACT_APP_accessKeyId,
        secretAccessKey: process.env.REACT_APP_secretAccessKey,
      })
      const s3 = new AWS.S3({
        params: {Bucket: S3_BUCKET},
        region: REGION,
      })
      const folder = 'profile-images'
      const params = {
        Bucket: S3_BUCKET,
        Key: `${folder}/${file.name}`,
        Body: file,
      }

      const upload = await s3.putObject(params).promise()
      const url = `https://${S3_BUCKET}.s3.${REGION}.amazonaws.com/${folder}/${file.name}`
      setImageUrl(url as string)
      updateAdminProfile(name, url)
      setUser({...user, profilePicture: url})
    } catch (error) {
      console.error('Error uploading file:', error)
      // setProfileLogoLoading(false);
    }
  }

  const handleDesignationChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDesignation(e.target.value)
  }

  const updateAdminProfile = async (name: any, profileImage: any) => {
    if (!name.trim()) {
      return
    }

    try {
      let updatedImageUrl = profileImage
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/users/admin/update-admins`,
        {
          adminId: currentUser?.userId,
          fullName: name,
          profilePicture: updatedImageUrl,
        }
      )

      if (response.statusText === 'OK') {
        toast.success('Updated Successfully', {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: 'colored',
        })
        setName(response.data.fullName)
        setShowName(true)
        setUser({
          fullName: response.data.fullName,
          positionName: response.data.positionName,
          profilePicture: response.data.profilePicture,
        })
      }
    } catch (error) {
      console.error('Error updating profile:', error)
      toast.error('Error updating profile. Please try again.')
    }
  }

  return (
    <div>
      <div className='d-flex flex-center '>
        <div className='d-flex  flex-column flex-column-fluid lg-flex-column'>
          <div className='card card-custom card-border'>
            <div className='card-header bg-secondary'>
              <h3 className='card-title'>Your Profile</h3>
              {/* <div className='card-toolbar '>
                <button
                  type='button'
                  className='btn btn-sm btn-light bg-secondary btn-active-primary my-2'
                >
                  <i className='bi bi-bell-fill ' />
                </button>
              </div> */}
            </div>
            <div className='card-body ' style={{height: '70vh'}}>
              <div className='d-flex  flex-column w-20 '>
                <div className='d-flex-column  align-items-start col-xxl-12 col-xl-8 col-xs-7 w-100'>
                  <div className='d-flex flex-column gap-5 flex-center  align-items-center pe-5 col-xxl-12 col-xl-8 col-xs-7 w-100'>
                    <div
                      className='d-flex flex-column justify-content-center align-items-center h-100'
                      style={{position: 'relative'}}
                    >
                      <img
                        src={imageUrl || noImage}
                        className='image rounded-circle'
                        alt='Profile'
                        style={imageStyle}
                      />
                      <input
                        accept='.png, .jpeg, .jpg, .svg'
                        id='icon-button-file'
                        type='file'
                        style={{display: 'none'}}
                        onChange={handleFileInputChange}
                      />
                      <label
                        style={{position: 'absolute', zIndex: 50, bottom: '-15px'}}
                        className='btn btn-sm btn-bg-secondary btn-active-info btn-custom bi bi-camera fs-2 '
                        htmlFor='icon-button-file'
                      />
                    </div>

                    <div className='d-flex flex-column justify-content-center gap-5 col-xxl-3 col-xl-4 col-lg-3 col-md-5 mt-5'>
                      {showName === true ? (
                        <div className='d-flex flex-row justify-content-between align-items-center '>
                          <h3 className='fs-2'>{name}</h3>
                          <button
                            className='btn btn-sm  btn-active-info btn-custom bi-pencil-square fs-4 '
                            onClick={() => setShowName(!showName)}
                          />
                        </div>
                      ) : (
                        <div className='d-flex flex-row justify-content-between gap-5 align-items-center '>
                          <input
                            maxLength={250}
                            className='form-control '
                            type='text'
                            id='name'
                            value={name}
                            onChange={(e) => setName(e.target.value.trimStart())}
                          />
                          <button
                            className='btn btn-success btn-custom'
                            onClick={() => updateAdminProfile(name, imageUrl)}
                          >
                            Save
                          </button>
                        </div>
                      )}
                      {designation &&
                        (showDesignation === true ? (
                          <div className='d-flex flex-row justify-content-between align-items-center'>
                            <div className='text-dark fs-5'>{designation} </div>
                            <button
                              className='btn  btn-active-primary btn-custom bi bi-pencil fs-5'
                              onClick={() => setShowDesignation(!showDesignation)}
                            />
                          </div>
                        ) : (
                          <div className='d-flex flex-row justify-content-between align-items-center'>
                            <input
                              className='form-control form-control-flush'
                              type='text'
                              id='name'
                              value={designation}
                              onChange={handleDesignationChange}
                            />
                            <button
                              className='btn btn-success btn-custom'
                              onClick={() => setShowDesignation(!showDesignation)}
                            >
                              Save
                            </button>
                          </div>
                        ))}
                      <div className='text-dark fs-5'>{currentUser?.roleName} </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </div>
  )
}
