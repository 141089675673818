import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import YourProfile from '../pages/YourProfile'
import CandidateMainPage from '../pages/Candidate/index'
import ViewCandidateDetails from '../pages/Candidate/ViewCandidateDetails'
import ApplicantMainPage from '../pages/Applicant'
import ViewApplicantDetails from '../pages/Applicant/ViewApplicantDetails'
import ReportMain from '../pages/Reports'
import SuperAdminMain from '../pages/SuperAdmin'
import Pipeline from '../pages/Reports/Pipeline'
import Volume from '../pages/Reports/Volume'
import Quality from '../pages/Reports/Quality'
import Speed from '../pages/Reports/Speed'
import Forecasting from '../pages/Reports/Forecasting'
import ChatMain from '../pages/Chat'
import WorkDistribution from '../pages/SuperAdmin/WorkDistribution'
import AddteamMembers from '../pages/SuperAdmin/AddteamMembers'
import ManageUsers from '../pages/SuperAdmin/ManageUsers'
import ApproveJobPage from '../pages/JobPage/approveJobPage'
import NewJobPage from '../pages/JobPage/newJobPage'
import JobDetailPage from '../pages/JobPage/JobDetailPage'
import {ChatInner} from '../../_metronic/partials'
import EditUser from '../pages/SuperAdmin/editUser'
import PipelineVisualization from '../pages/Reports/pipelineVisualization'
import UpdateCandidate from '../pages/Candidate/updateCandidate/updateCandidate'
import UpdateApplicant from '../pages/Applicant/updateApplicant/updateApplicant'
import CompanyPage from '../pages/Company'
import EmployeesListCard from '../pages/Company/employeesListCard'
import EmployeePage from '../pages/Company/employeeIndex'
import TotalCompanyCandidatesPage from '../pages/Company/totalCompanyCandidatesIndex'
import AssignCandidatesPage from '../pages/Company/assignCandidatesIndex'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const WizardsPage = lazy(() => import('../modules/wizards/WizardsPage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))
  const WidgetsPage = lazy(() => import('../modules/widgets/WidgetsPage'))
  const ChatPage = lazy(() => import('../modules/apps/chat/ChatPage'))
  const UsersPage = lazy(() => import('../modules/apps/user-management/UsersPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/yourprofile' />} />
        <Route path='/*' element={<Navigate to='/yourprofile' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='ApproveJobPage' element={<ApproveJobPage />} />
        <Route path='NewJobPage' element={<NewJobPage />} />
        <Route path='JobDetailPage' element={<JobDetailPage />} />
        <Route path='candidatemain' element={<CandidateMainPage />} />
        <Route path='UpdateCandidate' element={<UpdateCandidate />} />
        <Route path='viewcandidatedetails/:id' element={<ViewCandidateDetails />} />
        <Route path='applicantmain' element={<ApplicantMainPage />} />
        <Route path='viewapplicantdetails/:id' element={<ViewApplicantDetails />} />
        <Route path='updateApplicant' element={<UpdateApplicant />} />
        {/* <Route path='reportpage' element={<ReportMain />} /> */}
        <Route path='superadminmain' element={<SuperAdminMain />} />
        <Route path='yourprofile' element={<YourProfile />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        <Route path='PipelineVisualization' element={<PipelineVisualization />} />
        <Route path='pipeline' element={<Pipeline />} />
        <Route path='volume' element={<Volume />} />
        <Route path='quality' element={<Quality />} />
        <Route path='speed' element={<Speed />} />
        <Route path='chatmain' element={<ChatMain />} />
        <Route path='company' element={<CompanyPage />} />
        <Route path='employeesList' element={<EmployeePage />} />
        <Route path='totalCompanyCandidatesList' element={<TotalCompanyCandidatesPage />} />
        <Route path='assignCandidates' element={<AssignCandidatesPage />} />
        {/* <Route path='chatmain' element={<ChatPage />} /> */}
        <Route path='forecasting' element={<Forecasting />} />
        <Route path='workdistribution' element={<WorkDistribution />} />
        <Route path='addteammembers' element={<AddteamMembers />} />
        <Route path='manageusers' element={<ManageUsers />} />
        <Route path='editUser' element={<EditUser />} />
        {/* Lazy Modules */}
        <Route
          path='/reportpage/*'
          element={
            <SuspensedView>
              <ReportMain />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/wizards/*'
          element={
            <SuspensedView>
              <WizardsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/widgets/*'
          element={
            <SuspensedView>
              <WidgetsPage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/chat/*'
          element={
            <SuspensedView>
              <ChatPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
