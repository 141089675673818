import React from 'react'

export default function SuperAdminMain() {
  return (
    <div>
      <div className='d-flex justify-content-between align-items-baseline bg-white px-2'>
        <h3 className='px-2'>Super Admin</h3>
        <div className='card-toolbar '>
          <i className='bi bi-bell-fill btn btn-active-primary' />
        </div>
      </div>
    </div>
  )
}
