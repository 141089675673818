import {useEffect, useRef, useState} from 'react'
import axios from 'axios'

import useInbox from './useInbox'
import ChatList from './ChatList'
import {useUserStore} from '../../../../store/user'
import noImage from '../../../../_metronic/assets/img/noImage.png'

import '../chat.css'

const Inbox = ({handleSelectedUser, selectedUser, handleSelectedUserSearched, css}: any) => {
  const API_URL = process.env.REACT_APP_API_URL
  const userResultref = useRef<HTMLDivElement>(null)

  const {user} = useUserStore()
  const [filter, setFilter] = useState('All')

  const [showChat, setShowChat] = useState<any>([])
  const [showresult, setShowresult] = useState(false)
  const {chatList, chatListLoading} = useInbox(handleSelectedUserSearched, selectedUser)
  const [results, setResults] = useState([])

  const handleQueryChange = async (event: any) => {
    const {value} = event.target

    if (value) {
      try {
        const {data: response} = await axios.get(`${API_URL}/user/search-user/?name=${value}`)
        setShowresult(true)
        setResults(response)
      } catch (error) {
        setResults([])
        console.error('Error fetching search results:', error)
      }
    } else {
      setShowresult(false)
    }
  }
  // const filteredChats = (value: string) => {
  //   setFilter(value)

  //   const filteredChat =
  //     value === 'Read'
  //       ? showChat.filter(
  //           (item: any) =>
  //             item?.lastMsgTo !== user?.userId ||
  //             (item?.lastMsgTo === user?.userId && !item.unreadCount)
  //         )
  //       : value === 'Unread'
  //       ? showChat.filter((item: any) => item?.lastMsgTo === user?.userId && item.unreadCount)
  //       : showChat

  //   setShowChat(filteredChat)
  // }
  useEffect(() => {
    setShowChat(chatList)
  }, [chatList])
  const filteredChats =
    filter === 'Read'
      ? showChat.filter(
          (item: any) =>
            item?.lastMsgTo !== user?.userId ||
            (item?.lastMsgTo === user?.userId && !item.unreadCount)
        )
      : filter === 'Unread'
      ? showChat.filter((item: any) => item?.lastMsgTo === user?.userId && item.unreadCount)
      : showChat

  function handleClickOutside(event: MouseEvent) {
    if (userResultref.current && !userResultref.current.contains(event.target as Node)) {
      setShowresult(false)
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <>
      <div className='d-flex h-100' style={{maxHeight: '79vh', width: '100%'}}>
        <div className='card card-custom card-border me-3 h-100 w-100'>
          <div className={`${css}`}>
            <div className='d-flex justify-content-between align-items-center gap-2'>
              <select
                style={{width: '110px', padding: '10px'}}
                className='form-select sm'
                aria-label='Select example'
                value={filter}
                placeholder='All'
                onChange={(e) => setFilter(e.target.value)}
              >
                <option value='All'>All</option>
                <option value='Read'>Read</option>
                <option value='Unread'>Unread</option>
              </select>

              <div className='d-flex-column search' style={{position: 'relative'}}>
                <input
                  type='text'
                  className='form-control input-area bg-grey'
                  placeholder='search'
                  aria-label='invite'
                  aria-describedby='basic-addon2'
                  onChange={handleQueryChange}
                />
                {showresult && results.length > 0 && (
                  <div className='chat-search-card'>
                    <div className='d-flex-column' style={{width: 'inherit'}} ref={userResultref}>
                      {results.map((result: any) => (
                        <div
                          className='d-flex align-items-center gap-2 px-2 py-1 fs-5 cursor w-100 mb-1 text-hover-inverse-secondary bg-hover-secondary'
                          key={result.id}
                          onClick={() => {
                            handleSelectedUserSearched(result)
                            setResults([])
                          }}
                        >
                          <img
                            src={result.profilePicture || noImage}
                            alt='profile'
                            height='30px'
                            width='30px'
                            style={{borderRadius: '100%'}}
                          />
                          <div>
                            {result?.fullName?.length > 30
                              ? result?.fullName.slice(0, 30) + '...'
                              : result?.fullName}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
            {filteredChats?.length ? (
              <div
                className='d-flex flex-column mt-1 gap-1 w-100'
                style={{maxHeight: '69vh', overflow: 'auto'}}
              >
                {filteredChats?.map((item: any) => (
                  <ChatList
                    key={item?.chatId}
                    item={item}
                    handleSelectedUser={handleSelectedUser}
                  />
                ))}
              </div>
            ) : (
              <div className='d-flex flex-column gap-3 mt-10 message-card overflow-auto'>
                <div className='d-flex'>No Conversation</div>
              </div>

              // <div className='card-body '>
              //   <div className='d-flex justify-content-between align-items-center '>
              //     <div>
              //       <select className='form-select w-50 sm' aria-label='Select example'>
              //         <option>All</option>
              //         <option value='1'>Unread</option>
              //         <option value='2'>High priority</option>
              //         <option value='3'>Candidate only</option>
              //         <option value='3'>None Candidate </option>
              //       </select>
              //     </div>
              //     <div className='search '>
              //       <input
              //         type='text'
              //         className='form-control input-area bg-grey'
              //         placeholder='search'
              //         aria-label='invite'
              //         aria-describedby='basic-addon2'
              //         onChange={handleQueryChange}
              //       />
              //       {results.length > 0 && (
              //         <div className='card-body'>
              //           <div className='d-flex-column cursor'>
              //             {results.map((result: any) => (
              //               <div
              //                 className='d-flex p-2'
              //                 key={result.id}
              //                 onClick={() => {
              //                   handleSelectedUserSearched(result)
              //                   setResults([])
              //                 }}
              //               >
              //                 {result.fullName}
              //               </div>
              //             ))}
              //           </div>
              //         </div>
              //       )}
              //     </div>
              //   </div>
              //   <div className='d-flex flex-column gap-3 mt-10 message-card overflow-auto'>

              //     <div className='d-flex'>No Conversation</div>
              //   </div>
              // </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Inbox
