import React, {useState} from 'react'
import './ApplicantCard.css'
import {useNavigate} from 'react-router-dom'
import noImage from '../../../_metronic/assets/img/noImage.png'
import {useMediaQuery} from 'react-responsive'

interface ApplicantCardProps {
  data: {
    id: string
    fullName: string
    firstName: string
    middleName: string
    lastName: string
    email: string
    password: string
    phoneNumber: string
    is_admin: boolean
    isDeleted: boolean
    isAlternateLoginSourceEnabled: boolean
    userLocation: userLocationType
    dateOfBirth: string
    salary: salaryProps
    educationList: EducationType[]
    positionList: postitionListType[]
    interestedJobFunctions: [string]
    miscellaneousDetails: miscellaneousDetailsType
    preferredWorkplaceLocation: []
    interestedIndustry: [string]
    avoidIndustry: [string]
    interestedDomain: [string]
    avoidDomain: [string]
    locationList: [string]
    userType: string
    isProfileComplete: boolean
    profilePicture: string
    profileVideo: string
    jobSearch: string
    seniorityLevel: string
    isWorkOnSite: boolean
    isWorkHybrid: boolean
    isWorkRemotely: boolean
    workVisaRequired: string
    nonImmigrantVisaStatus: string
    desiredSalary: string
    whenYouCanStart: string
    customJoiningDate: string
    isVerify: boolean
    roleName: string
    positionName: string
    parentId: string
    companyId: string
    location: string
    userId: string
    jobTitle: string
    companyName: string
    latestDegree: string
    schoolName: string
    talentStatus: boolean
  }
  width?: string
}
interface salaryProps {
  currencyType: string
  amount: string
  payType: string
  totalCompensation: string
  isVisibleToEmployers: boolean
}
interface EducationType {
  degree: string
  fieldOfStudy: string
  year: string
  schoolName: string
}
interface userLocationType {
  address: any
  city: string
  country: string
  id: string
  state: string
}
interface miscellaneousDetailsType {
  shortBio: string
  achievement: [string]
  spokenLanguagesList: [string]
  interest: [string]
  linkedinProfileLink: string
  duties: [string]
}

const emptyuserLocationType = {
  address: '',
  city: '',
  country: '',
  id: '',
  state: '',
}
const emptyEducation = {
  degree: '',
  fieldOfStudy: '',
  year: '',
  schoolName: '',
}
interface postitionListType {
  companyName: string
  employmentType: string
  startDate: string
  managedPeople: string
  jobTitle: string
  jobFunc: string[]
  jobLocation: string
  position: string
  monthsWorked: string
}

const EmptyPositionlist = {
  companyName: '',
  employmentType: '',
  startDate: '',
  managedPeople: '',
  jobTitle: '',
  jobFunc: [],
  jobLocation: '',
  position: '',
  monthsWorked: '',
}
export default function ApplicantCard({data, width = 'auto'}: ApplicantCardProps) {
  const navigate = useNavigate()
  const isMobileView = useMediaQuery({maxWidth: 375})

  const achievementsPointsOptions = ['Deep Learning', 'Computer Vision']
  const [shortBio, setShortBio] = useState(data.miscellaneousDetails?.shortBio || '')
  const education: EducationType =
    data.educationList.length > 0 ? data.educationList[0] : emptyEducation
  const experience: postitionListType =
    data.positionList.length > 0 ? data.positionList[0] : EmptyPositionlist
  const locationUser: userLocationType = data?.userLocation
    ? data?.userLocation
    : emptyuserLocationType

  return (
    <div className='d-flex p-1 applicant-card-width'>
      <div className='col'>
        <div className='card card-custom'>
          <div className='card-body p-3 mt-3 candidate-card-height'>
            <div className='d-flex flex-column gap-3'>
              <div className='d-flex flex-row justify-content-start align-items-start gap-3'>
                <img
                  src={data.profilePicture || noImage}
                  className='h-95px w-95px border border-3 border-danger rounded-circle'
                  alt='Profile Image '
                />
                <div className='d-flex flex-column align-items-start flex-grow-1'>
                  <h4 className='text-dark card-heading'>
                    {data?.fullName?.length > 30
                      ? data?.fullName.slice(0, 30) + '...'
                      : data?.fullName}
                  </h4>

                  {data.jobTitle && (
                    <div className='d-flex align-items-center '>
                      {/* <i className='fa-brands fa-linkedin fs-2 me-2' /> */}
                      <div className='d-flex flex-row flex-wrap'>
                        <div className='text-dark me-1'>
                          {data?.jobTitle?.length > 20
                            ? data?.jobTitle.slice(0, 16) + '...'
                            : data?.jobTitle}{' '}
                          @{' '}
                          {data?.companyName?.length > 20
                            ? data?.companyName.slice(0, 16) + '...'
                            : data?.companyName}
                        </div>
                      </div>
                    </div>
                  )}

                  {data.latestDegree && (
                    <div className='d-flex flex-row card-heading2 align-items-start'>
                      <i className='fa-solid fa-graduation-cap fs-5 me-2' />
                      <div className='d-flex text-dark me-1 flex-wrap'>
                        <div className='text-dark'>{data.latestDegree}</div>
                        <div className='text-dark'>
                          {' '}
                          ,
                          {data?.schoolName?.length > 19
                            ? data?.schoolName.slice(0, 15) + '...'
                            : data?.schoolName}
                        </div>
                      </div>
                    </div>
                  )}
                  {locationUser && locationUser?.address && (
                    <div className='d-flex align-items-start '>
                      <i className='bi bi-geo-alt fs-5 me-1' />
                      <div className='text-dark me-2'>
                        {JSON.parse(locationUser?.address)?.label}
                      </div>
                    </div>
                  )}
                </div>
              </div>
              {/* <div className='flex-row-fluid'>
              <div className='d-flex flex-column'>
                <div className='d-flex flex-row justify-content-between'>
                  <div>59</div>
                  <div>2788</div>
                </div>
                <div
                  className='progress'
                  role='progressbar'
                  aria-label='Success example'
                  aria-valuenow={25}
                  aria-valuemin={0}
                  aria-valuemax={100}
                >
                  <div className='progress-bar bg-success' style={{width: '80%'}}></div>
                </div>
              </div>
            </div> */}
              {data.interestedJobFunctions.length > 0 && (
                <div className='d-flex flex-row align-items-start gap-2 pt-5'>
                  <i className='bi bi-award fs-1' />
                  <div className='d-flex flex-row flex-wrap gap-2'>
                    {data.interestedJobFunctions.map((option: any) =>
                      isMobileView ? (
                        <span
                          className='badge badge-warning badges-text p-1 fs-9'
                          style={{
                            whiteSpace: 'normal',
                            textAlign: 'start',
                            wordBreak: 'break-word',
                          }}
                        >
                          {option?.length > 30 ? option.slice(0, 30) + '...' : option}
                        </span>
                      ) : (
                        <span
                          className='badge badge-warning badges-text p-2'
                          style={{
                            whiteSpace: 'normal',
                            textAlign: 'start',
                            wordBreak: 'break-word',
                          }}
                        >
                          {option?.length > 30 ? option.slice(0, 30) + '...' : option}
                        </span>
                      )
                    )}
                  </div>
                </div>
              )}
              {/* {shortBio && (
              <div className='flex-row-fluid border border-3 border-secondary p-1 mt-2'>
                {shortBio}
              </div>
            )} */}
            </div>
          </div>
          <div className='card-footer pt-0'>
            <button
              onClick={() => {
                navigate(`/viewapplicantdetails/${data?.userId}`, {
                  state: {
                    data: data,
                  },
                })
              }}
              className='btn btn-light-info border-1 w-100 py-5 mt-8 '
            >
              View Details
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
