import React, {useState} from 'react'
import {useForm, SubmitHandler} from 'react-hook-form'
import {useLocation, useNavigate} from 'react-router-dom'
import axios from 'axios'
import {ToastContainer, toast} from 'react-toastify'
import {useUserStore} from '../../../../store/user'
import {Spinner} from 'react-bootstrap'

import './editUser.css'
import 'react-toastify/dist/ReactToastify.css'
type Inputs = {
  name: string
  phoneNumber: string
  usertype: string
}
const EditUser = () => {
  const API_URL = process.env.REACT_APP_API_URL
  const navigate = useNavigate()
  const {user} = useUserStore()
  const location: any = useLocation()
  const initial = location.state
  const [userType, setUserType] = useState<string>(initial.employeeData.userType)
  const [isloading, setIsLoading] = useState(false)
  const {
    register,
    handleSubmit,
    formState: {errors},
  } = useForm<Inputs>()
  const handleUserTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setUserType(e.target.value)
  }
  const onSubmit: SubmitHandler<Inputs> = (data) => {
    setIsLoading(true)
    const BODY = {
      // adminId: user?.userId,
      userId: location.state.employeeData.userId,
      fullName: data.name.trim(),
      userType: userType,
    }

    axios
      .put(`${API_URL}/users/admin/super-admin-update-admin`, BODY)
      .then((res) => {
        setIsLoading(false)
        if (res.statusText === 'OK') {
          toast.success('Info Updated Successfully', {
            position: 'top-center',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: 'colored',
          })
          setTimeout(() => {
            navigate('/manageusers')
          }, 1000)
        }
      })
      .catch((error) => {
        console.log(error)
        setIsLoading(false)
      })
  }

  return (
    <>
      <div className='d-flex bg-white flex-row-fluid justify-content-end mb-6 px-5 py-2'>
        <button
          className='btn btn-secondary '
          onClick={() => {
            navigate('/manageusers')
          }}
        >
          Back
        </button>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='d-flex flex-column bg-white p-5 h-100'>
          {(user?.userType === 'SUPER' || user?.userType === 'Admin') && (
            <div className='d-flex mb-5 w-100 justify-content-spacebetween'>
              <div className=' responsive-field px-5'>
                <label className='form-label'>Edit Name</label>
                <input
                  maxLength={250}
                  type='text'
                  className={`form-control form-control-solid input-width h-40px px-5 ${
                    errors?.name && 'is-invalid'
                  }`}
                  placeholder=''
                  defaultValue={initial.employeeData.fullName}
                  {...register('name', {
                    required: 'Name is required',
                    maxLength: {
                      value: 255,
                      message: 'Name cannot exceed 255 characters',
                    },
                  })}
                />
                {errors?.name && <div className='text-danger'>{errors?.name?.message}</div>}
              </div>
              {/* <div className=' w-50 px-5'>
                <label className='form-label'>Edit Phone Number</label>
                <input
                  type='text'
                  className='form-control'
                  placeholder='123456789'
                  // defaultValue={initial?.phoneNumber}
                  {...register('phoneNumber')}
                />
              </div> */}
            </div>
          )}
          {user?.userType === 'SUPER' && (
            <div className='d-flex mb-5 w-100 justify-content-spacebetween'>
              <div className='responsive-field px-5'>
                <label className='form-label'>Edit User Type</label>
                <select
                  className='form-select form-select-solid input-width h-40px px-5'
                  aria-label='Select role'
                  value={userType}
                  name='role'
                  onChange={handleUserTypeChange}
                >
                  <option value='ADMIN'>Admin</option>
                  <option value='SUPER'>Super Admin</option>
                </select>
                {/* <input
                  type='text'
                  className='form-control'
                  placeholder=''
                  defaultValue={initial.employeeData.userType}
                  {...register('usertype')}
                /> */}
              </div>
            </div>
          )}
          <div className='d-flex w-full justify-content-end p-5'>
            <button
              className='btn btn-info'
              type='submit'
              disabled={isloading}
              style={{width: '100px'}}
            >
              {isloading ? <Spinner /> : 'Submit'}
            </button>
          </div>
        </div>
      </form>
      <ToastContainer />
    </>
  )
}

export default EditUser
