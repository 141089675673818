import React, {useEffect, useState} from 'react'
import './ViewApplicant.css'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import noImage from '../../../_metronic/assets/img/noImage.png'
import axios from 'axios'
import {error} from 'console'
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import moment from 'moment'
import {Spinner} from 'react-bootstrap'
import {useMediaQuery} from 'react-responsive'
interface EducationType {
  degree: string
  fieldOfStudy: string
  year: string
  schoolName: string
}

interface userLocationType {
  address: any
  city: string
  country: string
  id: string
  state: string
}

interface postitionListType {
  id: string
  companyName: string
  employmentType: string
  startDate: string
  endDate: string
  managedPeople: string
  jobTitle: string
  jobFunc: string[]
  jobLocation: string
  industries: string
  position: string
  monthsWorked: string
  isStillWorking: boolean
}

export default function ViewApplicantDetails() {
  const isMobileView = useMediaQuery({maxWidth: 375})

  const navigate = useNavigate()
  const {id} = useParams()
  const API_URL = process.env.REACT_APP_API_URL
  const [data, setData] = useState<any>()
  const [isDataLoading, setIsDataLoading] = useState(false)
  const [isApproving, setIsApproving] = useState(false)
  const [candidateJobList, setcandidateJobList] = useState<any>([])
  const [isFocused, setIsFocused] = useState(false)
  const [openStages, setOpenStages] = useState(false)
  const [openStageId, setOpenStageId] = useState<any>()
  const [isActivelyInterviewing, setIsActivelyInterviewing] = useState<any>()
  const [jobSummary, setJobSummary] = useState('')
  const [updatedStage, setUpdatedStage] = useState('')
  const [currentStage, setCurrentStage] = useState('')
  const [jobToBeUpdated, setJobToBeUpdated] = useState<any>()
  const [selectedDate, setSelectedDate] = useState<any>({id: '', value: ''})
  const [searchJob, setSearchJob] = useState('')
  const [jobResult, setJobResult] = useState([])
  const [showJobResult, setShowJobResult] = useState(false)
  const [selectedJob, setSelectedJob] = useState<any>()
  const [appliedJobList, setAppliedJobList] = useState([])

  const [locationUser, setLocationUser] = useState<userLocationType | null>(null)
  const approveApplicant = () => {
    setIsApproving(true)
    let applicantId = data?.userId
    axios
      .get(`${API_URL}/users/admin/approve-applicant?applicantsId=${applicantId}`)
      .then((res) => {
        if (res.statusText === 'OK') {
          setIsApproving(false)
          toast.success('Applicant Approved Successfully', {
            position: 'top-center',
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: 'colored',
          })
          setTimeout(() => {
            navigate('/applicantmain')
          }, 1500)
        }
        // window.location.replace('/applicantmain')
      })
      .catch((error) => {
        setIsApproving(false)

        console.error('Error occurred while approving applicant:', error)
      })
  }
  function fetchCandidateData() {
    setIsDataLoading(true)
    try {
      axios
        .get(`${API_URL}/users/admin/fetch-candidate-user-for-admin?candidateId=${id}`)
        .then((response) => {
          setData(response?.data)
          setIsDataLoading(false)
        })
        .catch((error) => console.log(error))
    } catch (error) {
      console.log(error)
      setIsDataLoading(false)
    }
  }
  useEffect(() => {
    fetchCandidateData()
  }, [id])
  useEffect(() => {
    setIsActivelyInterviewing(data?.isActivelyInterviewing)
    setJobSummary(data?.miscellaneousDetails?.shortBio)
    setLocationUser(data?.userLocation ? data?.userLocation : null)
  }, [data])
  return (
    <>
      {data?.fullName ? (
        <>
          {isMobileView ? (
            <div
              className='d-flex align-items-center justify-content-between w-100'
              style={{color: 'gray'}}
            >
              <div
                className='text cursor-pointer text-hover-info'
                onClick={() => {
                  navigate(-1)
                }}
              >
                New Applicants
              </div>

              <button
                type='button'
                className='btn btn-success fs-8'
                onClick={() => approveApplicant()}
                style={{width: '89px'}}
                disabled={isApproving}
              >
                {/* <i className='bi bi-hand-thumbs-up-fill me-2' /> */}
                {isApproving ? <Spinner style={{height: '15px', width: '15px'}} /> : 'Approve'}
              </button>
            </div>
          ) : (
            <div className='d-flex bg-white flex-row-fluid justify-content-between mb-6 px-5 py-2 gap-4'>
              {/* <button
              className='btn btn-success'
              onClick={() => {
                navigate('/UpdateCandidate', {
                  state: {
                    data: data,
                  },
                })
              }}
            >
              Edit
            </button> */}

              <div className='d-flex align-items-center' style={{color: 'gray'}}>
                <div
                  className='d-flex align-items-center justify-content-start cursor-pointer text-hover-info'
                  onClick={() => {
                    navigate(-1)
                  }}
                >
                  New Applicants
                </div>
                <div
                  className='d-flex align-items-center  text-hover-info'
                  style={{marginLeft: '10px'}}
                >
                  <span style={{marginRight: '5px'}}>&bull;</span>
                  {data?.fullName?.length > 30
                    ? data?.fullName.slice(0, 30) + '...'
                    : data?.fullName}
                </div>
              </div>
              <div className='d-flex align-items-center justify-content-end gap-2'>
                <button
                  type='button'
                  className='btn btn-success fs-8'
                  onClick={() => approveApplicant()}
                  style={{width: '89px'}}
                  disabled={isApproving}
                >
                  {/* <i className='bi bi-hand-thumbs-up-fill me-2' /> */}
                  {isApproving ? <Spinner style={{height: '15px', width: '15px'}} /> : 'Approve'}
                </button>
                <button className='btn btn-secondary fs-8' onClick={() => navigate(-1)}>
                  Back
                </button>
              </div>
            </div>
          )}
          {/* <div className='d-flex flex-wrap justify-content-center align-items-center p-3 gap-5 bg-white p-3 mb-5'>
            <button
              type='button'
              className='btn btn-success fs-8'
              onClick={() => approveApplicant()}
            >
              Approve
            </button>
            <button
              className='btn btn-primary  fs-8 
              '
              onClick={() => {
                navigate('/UpdateApplicant', {
                  state: {
                    data: data,
                  },
                })
              }}
            >
              Edit
            </button>
            <button type='button' className='btn btn-primary  fs-8 '>
              Request For Edit
            </button>
          </div> */}
          <div className='d-flex flex-center'>
            <div className='card-body bg-white mb-5 '>
              {/* <div className='d-flex flex-row-fluid px-6 '> */}
              <div className='d-flex justify-content-start flex-column flex-xxl-row flex-xl-row flex-lg-row flex-md-row align-items-start p-6 gap-2'>
                <div className='d-flex flex-column justify-content-start  me-10 '>
                  <img
                    src={data.profilePicture || noImage}
                    className='h-100px w-100px rounded-circle flex-center'
                    alt='Profile Image '
                  />
                </div>
                <div className='d-flex flex-column align-items-start gap-1 flex-grow-1'>
                  <h4 className='text-dark card-heading'> {data?.fullName} </h4>

                  {data.jobTitle && (
                    <div className='d-flex align-items-center '>
                      {/* <i className='fa-brands fa-linkedin fs-2 me-2' /> */}
                      <div className='d-flex flex-row '>
                        <div className='text-dark me-2'>
                          {data?.jobTitle} @ {data?.companyName}
                        </div>
                      </div>
                    </div>
                  )}

                  {data.latestDegree && (
                    <div className='d-flex flex-row card-heading2 align-items-start'>
                      <i className='fa-solid fa-graduation-cap fs-5 me-2' />
                      <div className='d-flex text-dark me-2 flex-wrap'>
                        <div className='text-dark'>{data.latestDegree}</div>
                        <div className='text-dark'> ,{data?.schoolName}</div>
                      </div>
                    </div>
                  )}
                  {locationUser && locationUser?.address && (
                    <div className='d-flex align-items-center '>
                      <i className='bi bi-geo-alt fs-5 me-1' />
                      <div className='text-dark me-2'>
                        {JSON.parse(locationUser?.address)?.label}
                      </div>
                    </div>
                  )}
                  {jobSummary && <div className='text my-1 pt-3'>{jobSummary}</div>}
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex flex-column flex-xxl-row  w-100 gap-4'>
            <div className='d-flex flex-column col-xl-4 job-cards'>
              {/* <div className='d-flex flex-wrap  justify-content-center align-items-center p-3 gap-3 bg-white p-3 mb-5'>
                <button type='button' className='btn btn-success  fs-8 '>
                  <i className='bi bi-hand-thumbs-up-fill me-2' />
                  I'm interested
                </button>
                <button type='button' className='btn btn-danger fs-8'>
                  <i className='bi bi-hand-thumbs-down-fill me-2' />
                  Archieve
                </button>
              </div> */}
              {/* <div className='card-body bg-white p-3 mb-5'>
                <div className='d-flex flex-wrap justify-content-between gap-4 py-1 px-3 '>
                  <div className='d-flex  align-items-center '>
                    <i className='bi bi-database fs-1' />
                    <div className='d-flex flex-column px-1 '>
                      <span className='salary-heading'>Base Salary</span>
                      <span className='salary-text'>
                        {data?.salary.amount} {data?.salary.currencyType}
                      </span>
                    </div>
                  </div>
                  <div className='d-flex align-items-center'>
                    <i className='bi bi-file-earmark-text fs-1' />
                    <div className='d-flex flex-column px-3'>
                      <span className='salary-heading'>Visa Requirement</span>
                      <span className='salary-text'>{data.workVisaRequired}</span>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className='card-body bg-white p-3 '>
                <div className='d-flex flex-wrap salary-card gap-4'>
                  {/* <div className='d-flex flex-wrap justify-content-between gap-4 p-1'>
                <div className='d-flex  align-items-center '>
                  <i className='bi bi-database fs-1' />
                  <div className='d-flex flex-column px-1 '>
                    <span className='salary-heading'>Base Salary</span>
                    <span className='salary-text'>$170k-190k</span>
                  </div>
                </div>
                <div className='d-flex align-items-center '>
                  <i className='fa-solid fa-users-line fs-1' />

                  <div className='d-flex flex-column px-3'>
                    <span className='salary-heading'>Team Dynamics</span>
                    <span className='salary-text'>5 Direct Reports</span>
                  </div>
                </div>
                <div className='d-flex align-items-center'>
                  <i className='bi bi-sliders fs-1' />
                  <div className='d-flex flex-column px-3'>
                    <span className='salary-heading'>Matching Job</span>
                    <span className='salary-text'>$170k-190k</span>
                  </div>
                </div>
                <div className='d-flex align-items-center'>
                  <i className='fa fa-network-wired fs-1' />
                  <div className='d-flex flex-column px-3'>
                    <span className='salary-heading'>Visa Requirement</span>
                    <span className='salary-text'>No need</span>
                  </div>
                </div>
              </div> */}
                  <div className='d-flex flex-row align-items-start gap-2 pt-1'>
                    <i className='bi bi-award fs-1' />
                    <div className='d-flex flex-wrap gap-2'>
                      {data.interestedJobFunctions.map((option: any) => (
                        <span
                          className='badge badge-warning badges-text p-2'
                          style={{
                            whiteSpace: 'normal',
                            textAlign: 'start',
                            wordBreak: 'break-word',
                          }}
                        >
                          {option}
                        </span>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='d-flex flex-column ms-1 w-100'>
              <div className='card-header px-4 bg-white'>
                <div className='pt-5'>
                  <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6'>
                    <li className='nav-item'>
                      <a className='nav-link active' data-bs-toggle='tab' href='#experience_tab'>
                        Experience & Education
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='tab-content'>
                <div
                  className='tab-pane fade active show p-2 bg-white'
                  id='experience_tab'
                  role='tabpanel'
                >
                  {data?.positionList?.length || data?.educationList?.length ? (
                    <>
                      {data?.positionList &&
                        data?.positionList.map((positionData: any, i: any) => (
                          // <div
                          //   className='pb-15 border border-start border-top-0 border-end-0 border-bottom-0 border-secondary position-relative'

                          // >
                          //   <img
                          //     src={imageUrl || noImage}
                          //     className='h-50px w-50px rounded-circle border border-dark border-1 position-absolute start-0 translate-middle-x'
                          //     alt='Profile Image '
                          //   />
                          <div
                            className='d-flex flex-column gap-2 p-4 mb-3 experience-card'
                            key={i}
                          >
                            <div className='d-flex justify-content-between '>
                              <h4 className='text-hover-info'>{positionData?.companyName}</h4>
                              {positionData.monthsWorked && (
                                <span className='badge badge-success '>
                                  {positionData.monthsWorked}months
                                </span>
                              )}
                            </div>
                            <div className='d-flex justify-content-between flex-column flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-sm-row'>
                              <div className='d-flex align-items-center gap-2 w-100'>
                                <p>{positionData?.jobTitle}</p>
                                <p
                                  className='badge badge-light-info mx-2'
                                  style={{background: 'rgba(93, 120, 255, 0.1)'}}
                                >
                                  {positionData.employmentType === 'FULL_TIME'
                                    ? 'Full Time'
                                    : positionData.employmentType === 'PART_TIME'
                                    ? 'Part Time'
                                    : positionData.employmentType === 'FREE_LANCE'
                                    ? 'Freelance'
                                    : positionData.employmentType === ' SELF_EMPLOYED'
                                    ? 'Self Employeed'
                                    : positionData?.employmentType?.charAt(0).toUpperCase() +
                                      positionData?.employmentType.toLowerCase().slice(1)}
                                </p>
                              </div>
                              <div className='d-flex w-100 justify-content-end'>
                                <span className='text-gray-800 fw-bold fs-7'>
                                  {`${moment(positionData.startDate).format('MMM YYYY')}
                                   - 
                                  ${
                                    positionData.endDate
                                      ? moment(positionData.endDate).format('MMM YYYY')
                                      : positionData.isStillWorking === true && 'Present'
                                  }`}
                                </span>
                              </div>
                            </div>
                            <div className='text-gray-600'>{positionData.jobLocation}</div>
                            <div className='d-flex flex-wrap gap-3 align-items-center '>
                              <i className='bi bi-tags-fill fs-2' />
                              {positionData.jobFunc.map((option: any) => (
                                <span
                                  className='border tag-card'
                                  style={{
                                    whiteSpace: 'normal',
                                    textAlign: 'start',
                                    wordBreak: 'break-word',
                                  }}
                                >
                                  {option}
                                </span>
                              ))}
                            </div>
                            {/* <span className='font-12'>
                      {ExperienceKeyPoints.map((keyOption: any) => (
                        <li>{keyOption}</li>
                      ))}
                    </span> */}
                          </div>
                        ))}
                      {data?.educationList &&
                        data?.educationList.map((educationData: any, i: any) => (
                          <div
                            className='d-flex flex-column gap-2 p-4 mb-3 experience-card '
                            key={i}
                          >
                            <div className='d-flex'>
                              <h4 className='text-hover-info '>{educationData.schoolName}</h4>
                              <i className='fa-solid fa-graduation-cap fs-2 ms-2' />
                            </div>
                            <div className='d-flex flex-wrap'>
                              <span>{educationData.degree}</span>
                              <span>, {educationData.major}</span>
                            </div>
                            <div className='d-flex flex-wrap justify-content-between w-100'>
                              <div>
                                {educationData.startDate && (
                                  <span className='text-gray-800 fw-bold fs-7'>
                                    {` ${moment(educationData.startDate).format('MMM YYYY')} - 
                                    ${
                                      educationData.endDate
                                        ? moment(educationData.endDate).format('MMM YYYY')
                                        : educationData.educationStatus.charAt(0).toUpperCase() +
                                          educationData.educationStatus?.toLowerCase().slice(1)
                                    }`}
                                  </span>
                                )}
                              </div>
                              <div className='d-flex justify-content-end'>
                                {educationData.educationStatus && (
                                  <div>
                                    Education:
                                    <span
                                      className='badge badge-light-info ms-2 px-2'
                                      style={{background: 'rgba(93, 120, 255, 0.1)'}}
                                    >
                                      {educationData.educationStatus === 'IN_PROCESS'
                                        ? 'In Progress'
                                        : educationData.educationStatus === 'INCOMPLETE'
                                        ? 'Incomplete'
                                        : educationData.educationStatus.charAt(0).toUpperCase() +
                                          educationData.educationStatus?.toLowerCase().slice(1)}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </div>
                            {/* <div className='d-flex justify-content-between '>
                    <div>
                      <span>{educationData.jobFunc}</span>
                      <span className='badge badge-info mx-2'>{educationData.employmentType}</span>
                    </div>
                    <div>
                      <span className='text-gray-800 fw-bold fs-7'>
                        {educationData.startDate} - {educationData.endDate}
                      </span>
                    </div>
                  </div> */}
                          </div>
                        ))}
                    </>
                  ) : (
                    <div className='d-flex justify-content-center w-100'>
                      <p className='text text-gray-700'>
                        The Candidate has not provided information about their education and
                        experience.
                      </p>
                    </div>
                  )}
                  {/* </div> */}
                  {/* /////////////////////////////////// */}
                </div>
                {/* <div className='tab-pane fade' id='activity_tab' role='tabpanel'>
                  <div className='card card-custom '>
                    <div className='card-body '>
                      <div className='d-flex flex-column'>
                        <div className='d-flex flex-row align-items-center pb-6'>
                          <div className='d-flex flex-column '>
                            <i className='fa-sharp fa-solid fa-chart-simple fs-1' />
                          </div>
                          <div className='d-flex flex-column ms-8'>
                            <span>User Viewed The Profile</span>
                            <span className='text-info'>2 hrs Ago</span>
                          </div>
                        </div>
                        <div className='d-flex flex-row align-items-center pb-6'>
                          <div className='d-flex flex-column'>
                            <i className='bi bi-lightning-charge-fill fs-1' />
                          </div>
                          <div className='d-flex flex-column ms-8'>
                            <span>User (@username) interested </span>
                            <span className='text-info'>2 hrs Ago</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='d-flex bg-white flex-row-fluid justify-content-end mb-6 px-5 py-2 gap-4'>
            <button
              className='btn btn-secondary '
              onClick={() => {
                navigate('/applicantmain')
              }}
            >
              Back
            </button>
          </div>
          {isDataLoading ? (
            <div
              className='d-flex flex-column justify-content-center align-items-center'
              style={{minHeight: '50vh'}}
            >
              <Spinner />
            </div>
          ) : (
            <div className='d-flex justify-content-center text-dark p-9 m-9 fs-1'>
              No Data Available
            </div>
          )}
        </>
      )}
      <ToastContainer />
    </>
  )
}
