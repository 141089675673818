import {useEffect, useState} from 'react'

import axios from 'axios'
import {Spinner} from 'react-bootstrap'
import ReactPaginate from 'react-paginate'

import ApplicantCard from './ApplicantCard'
import {scrollToTopOfScrollable} from '../../../utils/helper/helper'

export default function ApplicantMainPage() {
  const API_URL = process.env.REACT_APP_API_URL
  const [applicantsData, setApplicantsData] = useState<any>([])
  const [searchInput, setSearchInput] = useState('')
  const [jobFunction, setJobFunction] = useState('')
  const [JobFunctionOptions, setJobFunctionOptions] = useState([])
  const [itemOffset, setItemOffset] = useState(0)
  const itemsPerPage = 10
  const [pageCount, setPageCount] = useState(Math.ceil(applicantsData?.totalSize / itemsPerPage))

  const handlePageClick = (event: any) => {
    const newOffset = event.selected
    setItemOffset(newOffset)
  }
  const [isLoading, setIsLoading] = useState(false)
  const fetchData = () => {
    setIsLoading(true)
    axios
      .get(
        `${API_URL}/users/admin/get-all-applicants?function=${jobFunction}&query=${searchInput}&page=${itemOffset}&limit=12`
      )
      .then((response) => {
        setApplicantsData(response.data ? response.data : [])
        setPageCount(response.data.totalPages || 1)
        setIsLoading(false)
        scrollToTopOfScrollable()
      })
  }
  useEffect(() => {
    fetchData()
  }, [jobFunction, itemOffset, searchInput])
  useEffect(() => {
    axios.get(`${API_URL}/users/domain/job-function-list`).then((response) => {
      setJobFunctionOptions(response.data)
    })
  }, [])
  const handleChange = (e: any) => {
    e.preventDefault()
    let value = e.target.value
    setSearchInput(value.trimLeft())
    if (value.length && itemOffset !== 0) {
      setItemOffset(0)
    }
  }
  const handleJobFunctionChange = (value: string) => {
    setJobFunction(value)
    if (value.length && itemOffset !== 0) {
      setItemOffset(0)
    }
  }

  return (
    <div>
      <div className='d-flex justify-content-between  flex-column-reverse flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-sm-row bg-white mb-6 rounded px-5 '>
        <div className='d-flex align-items-center gap-3  p-3'>
          <span> {applicantsData?.totalSize} Total</span>
          <div className='search '>
            <input
              type='text'
              className='form-control input-area bg-grey'
              placeholder='Search by name'
              value={searchInput}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className='d-flex align-items-center gap-3  p-3'>
          <span style={{width: '100px'}}>Job Functions</span>
          <div>
            <select
              className='form-select'
              aria-label='Default select example'
              value={jobFunction}
              name='role'
              onChange={(e) => {
                handleJobFunctionChange(e.target.value)
              }}
              style={{width: '150px'}}
            >
              <option value=''>All</option>
              {JobFunctionOptions.map((option: any, i: any) => (
                <option key={i}> {option.name}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <div className='card-header bg-white rounded p-2'>
        <h3 className='mx-5'>New Applicants</h3>
      </div>

      {!applicantsData?.users?.length ? (
        <div className='d-flex p-9 m-9 justify-content-center align-items-center fs-1  fw-bold'>
          {isLoading ? <Spinner /> : 'No Applicant Found'}
        </div>
      ) : (
        <>
          <div className='d-flex flex-column justify-content-between' style={{minHeight: '60vh'}}>
            <div className='d-flex justify-content-start flex-wrap flex-column flex-xxl-row flex-xl-row flex-lg-row flex-md-row p-1 mt-5 gap-2'>
              {Array.isArray(applicantsData?.users) &&
                applicantsData?.users?.map((data: any) => (
                  <ApplicantCard data={data} key={data?.userId} />
                ))}
            </div>
            <div className='d-flex'>
              {' '}
              <ReactPaginate
                nextLabel='Next >'
                onPageChange={handlePageClick}
                forcePage={itemOffset}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel='< Previous'
                pageClassName='page-item'
                pageLinkClassName='page-link'
                previousClassName='page-item'
                previousLinkClassName='page-link'
                nextClassName='page-item'
                nextLinkClassName='page-link'
                breakLabel='...'
                breakClassName='page-item'
                breakLinkClassName='page-link'
                containerClassName='pagination'
                activeClassName='active'
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}
