import React, {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import CandidateCard from '../Candidate/CandidateCard'
import axios from 'axios'
import {Spinner} from 'react-bootstrap'
import {scrollToTopOfScrollable} from '../../../utils/helper/helper'
import ReactPaginate from 'react-paginate'

const TotalCompanyCandidatesPage = () => {
  const API_URL = process.env.REACT_APP_API_URL
  const [candidateData, setCandidateData] = useState<any>([])
  const [itemOffset, setItemOffset] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const itemsPerPage = 12
  const [pageCount, setPageCount] = useState(Math.ceil(candidateData?.totalSize / itemsPerPage))
  const [searchInput, setSearchInput] = useState('')

  const location: any = useLocation()
  const companyCandidatesId = location?.state?.companyId
  const handlePageClick = (event: any) => {
    const newOffset = event.selected
    setItemOffset(newOffset)
  }
  useEffect(() => {
    setIsLoading(true)
    axios
      .get(
        `${API_URL}/users/admin/company-candidates?companyId=${companyCandidatesId}&query=${searchInput}&page=${itemOffset}&limit=${itemsPerPage}`
      )
      .then((response) => {
        setCandidateData(response?.data ? response.data : [])
        setPageCount(response.data.totalPages || 1)
        setIsLoading(false)
        scrollToTopOfScrollable()
      })
  }, [searchInput, itemOffset])
  const handleChange = (e: any) => {
    e.preventDefault()
    let value = e.target.value
    setSearchInput(value.trimLeft())
    if (value.length && itemOffset !== 0) {
      setItemOffset(0)
    }
  }
  return (
    <div>
      <div className='d-flex justify-content-between  flex-column-reverse flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-sm-row bg-white mb-6 rounded px-5 '>
        <div className='d-flex align-items-center gap-3  p-3'>
          <span> {candidateData?.totalSize} Total</span>
          <div className='search '>
            <input
              type='text'
              className='form-control input-area bg-grey'
              placeholder='Search...'
              value={searchInput}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className='card-header bg-white rounded p-2'>
        <h3 className='mx-5'>Assigned Candidates</h3>
      </div>

      {!candidateData?.users?.length ? (
        <div className='d-flex p-9 m-9 justify-content-center align-items-center fs-1  fw-bold'>
          {isLoading ? <Spinner /> : 'No Candidate Found'}
        </div>
      ) : (
        <>
          <div className='d-flex justify-content-start flex-wrap flex-column flex-xxl-row flex-xl-row flex-lg-row flex-md-row p-1 mt-5 gap-3'>
            {candidateData?.users &&
              candidateData?.users?.map((companyCandidate: any, index: number) => (
                <CandidateCard
                  data={companyCandidate}
                  key={index}
                  companyId={companyCandidatesId}
                  setCandidateData={setCandidateData}
                />
              ))}
          </div>
          <div className='d-flex'>
            {' '}
            <ReactPaginate
              nextLabel='Next >'
              onPageChange={handlePageClick}
              forcePage={itemOffset}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel='< Previous'
              pageClassName='page-item'
              pageLinkClassName='page-link'
              previousClassName='page-item'
              previousLinkClassName='page-link'
              nextClassName='page-item'
              nextLinkClassName='page-link'
              breakLabel='...'
              breakClassName='page-item'
              breakLinkClassName='page-link'
              containerClassName='pagination'
              activeClassName='active'
              renderOnZeroPageCount={null}
            />
          </div>
        </>
      )}
    </div>
  )
}

export default TotalCompanyCandidatesPage
