import React from 'react'
interface ICheckBoxButtonProps {
  options: any[]
  selectedOptions: any[]
  width?: string
  handleOptionSelection: (option: any) => void
  handleOptionAll: (option: any) => void
}

export default function CardCheckbox({
  options,
  selectedOptions,
  handleOptionAll,
  handleOptionSelection,
  width = 'auto',
}: ICheckBoxButtonProps) {
  return (
    <div className='d-flex flex-wrap ms-2'>
      <button
        type='button'
        className='btn btn-secondary rounded select-all-btn'
        onClick={() => handleOptionAll(options)}
      >
        Select All
      </button>

      <div className='ms-2'>
        {options.map((option: any) => (
          <div
            className='btn-group  p-2'
            role='group'
            aria-label='Basic checkbox toggle button group '
          >
            <input
              type='checkbox'
              className='btn-check'
              id={option}
              checked={selectedOptions.includes(option) ? true : false}
              onClick={() => handleOptionSelection(option)}
              width={width}
            />
            <label
              className='btn btn-outline-primary options-border btn-sm rounded-pill'
              htmlFor={option}
            >
              {option}
            </label>
          </div>
        ))}
      </div>
    </div>
  )
}
