import create from 'zustand'

interface IUser {
  user: {
    fullName: string | null
    positionName: string | null
    profilePicture: string | null
    email: string | null
    userId: string | null
    userType: string | null
  }
  setUser: (data: any) => void
}

const user = localStorage.getItem('kt-auth-react-v')

const storedUser = user ? JSON.parse(user) : null

const initial_state = {
  user: storedUser || null,
}

export const useUserStore = create<IUser>((set: any) => ({
  ...initial_state,

  setUser: (data: any) => {
    set((state: any) => {
      localStorage.setItem('kt-auth-react-v', JSON.stringify({...state.user, ...data}))

      return {
        ...state,
        user: {
          ...state.user,
          ...data,
        },
      }
    })
  },
}))
