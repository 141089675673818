import {getScrollTop} from '../../_metronic/assets/ts/_utils'
import {ScrollTopComponent} from '../../_metronic/assets/ts/components'

export const getTimeAgo = (timestamp: any) => {
  const currentTime: any = new Date()
  const messageTime: any = new Date(timestamp)
  const timeDiff = currentTime - messageTime
  const seconds = Math.floor(timeDiff / 1000)
  const minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)

  if (seconds < 60) {
    return 'Just now'
  } else if (minutes < 60) {
    return `${minutes} minute${minutes === 1 ? '' : 's'} ago`
  } else if (hours < 24) {
    return `${hours} hour${hours === 1 ? '' : 's'} ago`
  } else {
    return messageTime.toLocaleString() // Display full timestamp if more than 24 hours
  }
}
export const addCommas = (num: any) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
export const scrollToTopOfScrollable = () => {
  const element = document.getElementById('kt_app_content_container')
  if (element) {
    ScrollTopComponent.goTop()
    // element.scrollIntoView()
  }
}
