import {useEffect, useRef, useState} from 'react'
import {useMediaQuery} from 'react-responsive'

import {ToastContainer, toast} from 'react-toastify'
import axios from 'axios'

import noImage from '../../../_metronic/assets/img/noImage.png'
import {useUserStore} from '../../../store/user'

import './company.css'
import 'react-toastify/dist/ReactToastify.css'

const EmployeesListCard = ({data}: any) => {
  const isMobileView = useMediaQuery({maxWidth: 450})
  const menuRef = useRef<any>()
  const {user} = useUserStore()
  const [isMenuOpen, setMenuOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const employeeId = data?.id

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen)
  }

  const handleLoginAs = () => {
    if (user?.userType === 'ADMIN') {
      toast.error('User has no access', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: 'colored',
      })
    } else if (user?.userType === 'SUPER') {
      axios
        .get(`${process.env.REACT_APP_API_URL}/users/admin/login-as?userId=${employeeId}`)
        .then((res) => {
          setIsLoading(false)
          let token = res.data.token
          if (token) {
            try {
              let url = `https://app.moon.ac/loginAs?token=${token}`
              window.open(url, '_blank', 'noopener,noreferrer')
            } catch (error) {
              console.error('Error opening a new window:', error)
            }
          } else {
            toast.error('Token is missing in the response', {
              position: 'top-center',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: 'colored',
            })
          }
        })
        .catch((error) => {
          setIsLoading(false)
          console.error('ERROR', error)
          toast.error(`Error: ${error.message}`, {
            position: 'top-center',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: 'colored',
          })
        })
    }
  }
  function handleClickOutside(event: MouseEvent) {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setMenuOpen(false)
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  return (
    <div className='d-flex employee-width'>
      <div className='col'>
        <div className='card card-custom'>
          <div className='bi bi-three-dots' onClick={toggleMenu}>
            <ul className={`dot-menu ${isMenuOpen ? 'show-menu' : ''}`} ref={menuRef}>
              <li
                className='text-hover-inverse-secondary bg-hover-secondary menu-font'
                onClick={handleLoginAs}
              >
                Login As
              </li>
            </ul>
          </div>
          <div className='card-body p-2 candidate-card employee-height mt-9'>
            <div className='d-flex flex-column gap-3'>
              <div className='d-flex flex-row justify-content-start align-items-start gap-3'>
                <img
                  src={data.profilePicture || noImage}
                  className='h-85px w-85px rounded-circle'
                  alt='Profile'
                />
                <div className='d-flex flex-column align-items-start flex-grow-1'>
                  <h4 className='text-dark card-heading'>{data.fullName} </h4>

                  {data.email &&
                    (isMobileView ? (
                      <>
                        <div className='d-flex flex-wrap card-heading2 align-items-center '>
                          <i className='bi bi-person-rolodex fs-6 me-2' />
                          <div className='d-flex flex-wrap fs-7 text-dark'>{data?.email}</div>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='d-flex flex-wrap card-heading2 align-items-center '>
                          <i className='bi bi-person-rolodex fs-5 me-2' />
                          <div className='d-flex flex-wrap text-dark'>{data.email}</div>
                        </div>
                      </>
                    ))}
                  {data.positionName ||
                    (data.roleName && (
                      <div className='d-flex flex-row card-heading2 align-items-center '>
                        <i className='bi bi-person-lines-fill fs-5 me-2' />
                        <div className='text-dark me-2'>{data.positionName || data.roleName}</div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  )
}

export default EmployeesListCard
