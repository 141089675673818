import React, {useEffect, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import CandidateCard from '../Candidate/CandidateCard'
import axios from 'axios'
import {Spinner} from 'react-bootstrap'
import {ToastContainer, toast} from 'react-toastify'
import ReactPaginate from 'react-paginate'
import {scrollToTopOfScrollable} from '../../../utils/helper/helper'

const AssignCandidatesPage = () => {
  const API_URL = process.env.REACT_APP_API_URL
  const [candidateData, setCandidateData] = useState<any>([])
  const [searchInput, setSearchInput] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [isCandidateLoading, setisCandidateLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(0)
  const {state}: any = useLocation()
  const companyId = state?.companyId
  const [itemOffset, setItemOffset] = useState(0)

  const itemsPerPage = 12
  const [candidateId, setCandidateId] = useState<any>([])
  const [pageCount, setPageCount] = useState<any>()

  const navigate = useNavigate()

  useEffect(() => {
    setIsLoading(true)

    const pageOffset = currentPage * itemsPerPage
    axios
      .get(
        `${API_URL}/users/admin/unassigned-candidates?query=${searchInput}&page=${itemOffset}&limit=12&companyId=${companyId}`
      )
      .then((response) => {
        setCandidateData(response.data ? response.data : [])
        setPageCount(response.data.totalPages || 1)
        setIsLoading(false)
        scrollToTopOfScrollable()
      })
  }, [itemOffset, itemsPerPage, searchInput])
  const handleSelected = (event: any) => {
    const userId = event
    if (candidateId.includes(event)) {
      setCandidateId((prevSelectedUserIds: any) =>
        prevSelectedUserIds.filter((id: string) => id !== userId)
      )
    } else {
      setCandidateId((prevSelectedUserIds: any) => [...prevSelectedUserIds, userId])
    }
  }
  const handlePageClick = (event: any) => {
    const newOffset = event.selected
    setItemOffset(newOffset)
  }
  const handleAssignCandidates = () => {
    if (candidateId.length === 0) {
      toast.error('Please select at least one Candidate', {
        position: 'top-center',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: 'colored',
      })
    } else {
      setisCandidateLoading(true)
      axios
        .post(`${API_URL}/users/admin/assign-candidate`, {
          companyId: companyId,
          candidateId: candidateId,
        })
        .then((response) => {
          setisCandidateLoading(false)

          toast.success('Candidate(s) Assigned Successfully', {
            position: 'top-center',
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: 'colored',
          })
          setTimeout(() => {
            navigate('/company')
          }, 2000)
        })
        .catch((error) => {
          setisCandidateLoading(false)
          // Handle errors
        })
    }
  }
  const handleChange = (e: any) => {
    e.preventDefault()
    let value = e.target.value
    setSearchInput(value.trimLeft())
    if (value.length && itemOffset !== 0) {
      setItemOffset(0)
    }
  }
  return (
    <>
      <div>
        <div className='d-flex justify-content-between  flex-column-reverse flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-sm-row bg-white mb-6 rounded px-5 '>
          <div className='d-flex align-items-center gap-3  p-3'>
            <span> {candidateData?.totalSize} Total</span>
            <div className='search '>
              <input
                type='text'
                className='form-control input-area bg-grey'
                placeholder='Search...'
                value={searchInput}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
        <div className='card-header bg-white rounded p-2'>
          <h3 className='mx-5'>Assign Candidates</h3>
        </div>

        {!candidateData?.users?.length ? (
          <div className='d-flex p-9 m-9 justify-content-center align-items-center fs-1  fw-bold'>
            {isLoading ? <Spinner /> : 'No Candidate Found'}
          </div>
        ) : (
          <>
            <div className='d-flex justify-content-start flex-wrap flex-column flex-xxl-row flex-xl-row flex-lg-row flex-md-row p-1 mt-5 gap-3'>
              {candidateData?.users &&
                candidateData?.users?.map((assignCandidate: any, index: number) => (
                  <CandidateCard
                    data={assignCandidate}
                    handleSelected={handleSelected}
                    key={index}
                  />
                ))}
            </div>
            <div className='d-flex'>
              {' '}
              <ReactPaginate
                nextLabel='Next >'
                onPageChange={handlePageClick}
                forcePage={itemOffset}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel='< Previous'
                pageClassName='page-item'
                pageLinkClassName='page-link'
                previousClassName='page-item'
                previousLinkClassName='page-link'
                nextClassName='page-item'
                nextLinkClassName='page-link'
                breakLabel='...'
                breakClassName='page-item'
                breakLinkClassName='page-link'
                containerClassName='pagination'
                activeClassName='active'
                renderOnZeroPageCount={null}
              />
            </div>
          </>
        )}
        <div className='card-footer pt-0' style={{position: 'relative'}}>
          <button
            onClick={handleAssignCandidates}
            className='centered-button btn btn-primary border-1  py-5 mt-8'
            style={{
              position: 'fixed',
              bottom: '40px',
              right: '45px',
              width: '100px',
            }}
            disabled={isCandidateLoading}
          >
            {isCandidateLoading ? <Spinner /> : 'Assign'}
          </button>
        </div>
      </div>
      <ToastContainer />
    </>
  )
}

export default AssignCandidatesPage
