import {useNavigate} from 'react-router-dom'
import './jobPage.css'
interface jobDataProps {
  data: {
    status: number
    name: string
    description: string
    companyName: string
    companyLogo: string
    profilePicture: string
    activeHiring: boolean
    createdBy: string
    seniorityLevel: string
    reqNumber: string
    sponsorshipType: string
    salary: {
      currency: string
      starting: number
      ending: number
      duration: string
    }
    bonus: {
      starting: number
      ending: number
      bonusType: string
    }
    stock: {
      type: string
      stockType: string
      duration: string
      starting: number
      ending: number
    }
    jobFunctions: [string]
    responsibilitiesList: [string]
    responsibilitiesList2: [string]
    locationsType: string
    peopleManagement: string
    reports: string
    degree: [string]
    jobResponsibility: [
      {
        responsibility: string
        duration: string
      }
    ]
    industryExperienceList: [string]
    employeeBenefitList: [string]
    collegeMajorList: [string]
    domainList: [string]
    topSkillList: [string]
    experience: [
      {
        fieldName: string
        degree: string
        experienceLevel: string
      }
    ]
    locationsList: [string]
  }
}
const JobCard = ({data}: jobDataProps) => {
  const navigate = useNavigate()
  const primaryLocation = data.locationsList.length > 0 && data.locationsList[0]

  const maxCompanyNameLength = 30
  const maxLocationLength = 10
  const truncatedJobTitle =
    data?.name?.length > maxCompanyNameLength
      ? data?.name.slice(0, maxCompanyNameLength) + '...'
      : data?.name

  // Truncate the location if it's too long
  const truncatedLocation =
    data.locationsList.length > 0 && data.locationsList[0].length > maxLocationLength
      ? data.locationsList[0].slice(0, maxLocationLength) + '...'
      : data.locationsList[0]

  return (
    <div className='d-flex p-1 job-card-width'>
      <div className='col'>
        <div className='card card-custom job-card-height'>
          <div className='card-body p-6'>
            <div className='d-flex flex-column justify-content-between h-100'>
              <div className='d-flex flex-column gap-3'>
                <div className='d-flex flex-row justify-content-start align-items-center gap-3'>
                  <div className='d-flex flex-column align-items-start flex-grow-1'>
                    <h3 className='text-dark fw-bold mb-3' style={{fontSize: '1.60rem'}}>
                      {data?.companyName?.length > maxCompanyNameLength
                        ? data?.companyName.slice(0, maxCompanyNameLength) + '...'
                        : data?.companyName}
                    </h3>
                    <div className='d-flex flex-column gap-0'>
                      <h4 className='text-dark card-heading'>{truncatedJobTitle}</h4>

                      <div className='d-flex flex-row flex-wrap card-heading2 gap-2 align-items-center'>
                        <div className='text-dark '>{primaryLocation}</div>
                        {truncatedLocation && data.reqNumber && (
                          <div className='text-dark'>|| </div>
                        )}
                        {data.reqNumber && <div className='text-dark'>Req # {data.reqNumber}</div>}
                      </div>
                    </div>
                  </div>
                </div>

                <div className='d-flex flex-row align-items-center gap-2'></div>
              </div>

              <div className='d-flex bg-white'>
                <button
                  onClick={() => {
                    navigate('/JobDetailPage', {
                      state: {
                        data: data,
                      },
                    })
                  }}
                  className='btn btn-light-info border-1 w-100 py-5 mt-8 cursor'
                >
                  {data.status === 0 ? 'View Details' : 'View Details'}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default JobCard
