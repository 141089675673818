import React, {useState} from 'react'

export const useSelectOptions = (optionsArray: any) => {
  const [selectedOptions, setSelectedOptions] = useState<any>([])

  const handleOptionSelection = (option: any) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions((prevSelectedOptions: any) =>
        prevSelectedOptions.filter((o: any) => o !== option)
      )
    } else {
      setSelectedOptions((prevSelectedOptions: any): any => [...prevSelectedOptions, option])
    }
  }
  const handleOptionAll = (option: any) => {
    setSelectedOptions(optionsArray)
  }

  return [selectedOptions, handleOptionSelection, handleOptionAll]
}
