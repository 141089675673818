import React from 'react'
import {Employee} from '../ManageUsers'
import {useNavigate} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import {useUserStore} from '../../../../store/user'

interface EmployeeListProps {
  employees: Employee[]
  deleteUser: (idToDelete: string) => void
}

const EmployeeListTable = ({employees, deleteUser}: EmployeeListProps) => {
  const navigate = useNavigate()
  const {user} = useUserStore()
  return (
    <div className='table-responsive'>
      <table className='table table-hover table-borderless table-sm table-responsive-md'>
        <thead>
          <tr className='fw-bold'>
            <th>Name</th>
            <th>Email</th>
            <th>
              <div className='d-flex' style={{minWidth: '80px', flexWrap: 'nowrap'}}>
                User-Type
              </div>
            </th>
            {user?.userType === 'SUPER' && <th>Action</th>}
          </tr>
        </thead>
        <tbody>
          {employees &&
            employees.map((employee: Employee) => (
              <tr key={employee?.userId}>
                <td>{employee?.fullName}</td>
                <td>{employee?.email}</td>
                <td>
                  <div className='d-flex' style={{minWidth: '80px', flexWrap: 'nowrap'}}>
                    {employee?.userType === 'SUPER'
                      ? 'Super Admin'
                      : employee?.userType?.charAt(0).toUpperCase() +
                        employee?.userType?.toLowerCase().slice(1)}
                  </div>
                </td>
                {user?.userType === 'SUPER' && (
                  <td>
                    <div className='d-flex' style={{minWidth: '50px', flexWrap: 'nowrap'}}>
                      {employee?.userId !== user.userId && (
                        <>
                          <i
                            className='bi bi-trash fs-2 cursor px-1'
                            onClick={() => deleteUser(employee.userId)}
                          />
                          <i
                            className='bi bi-pencil-square fs-2 px-1 cursor'
                            onClick={() =>
                              navigate('/editUser', {
                                state: {
                                  employeeData: employee,
                                },
                              })
                            }
                          />
                        </>
                      )}
                    </div>
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  )
}

export default EmployeeListTable
