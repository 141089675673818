import React from 'react'

export default function SalaryRangeComponent() {
  return (
    <div>
      <div className='d-flex flex-column flex-center '>
        <label htmlFor='customRange3' className='form-label'>
          Salary Range
        </label>
        <input
          type='range'
          className='form-range slider-width'
          min='0'
          max='5'
          step='0.5'
          id='customRange3'
        />
      </div>
    </div>
  )
}
