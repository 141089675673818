import React from 'react'
import DateComponent from './DateComponent'
import CardCheckbox from './CardCheckbox'
import SalaryRangeComponent from './SalaryRangeComponent'
import {useSelectOptions} from './HandleHook'
import CardRadio from './CardRadio'

export default function Speed() {
  const JobfunctionOptions = [
    'Data Scientist',
    'Data Engineer',
    'Machine Learning',
    'Product Manager',
  ]
  const SeniorityLevelOptions = ['Junior', 'Mid-Level', 'Senior', 'Manager']
  const LocationOptions = ['Remote', 'NYC', 'Boston', 'SF']
  const JobsOptions = ['Full Time', 'Hybrid', 'Remote']
  const ClientCompanyOptions = [' google', 'Facebook ', 'LinkEdin']
  const HiringManagerOptions = ['Jay', 'Steve', 'Robert']
  const HopTeamOptions = ['Michel ', 'Alex ', 'Bryan ']
  ///
  const [Jobfunction, handleJobFunction, handleJobFunctionAlls] =
    useSelectOptions(JobfunctionOptions)
  const [LocationFunction, handleLocationFunction, handleLocationFunctionAlls] =
    useSelectOptions(LocationOptions)
  const [SeniorityLevelfunction] = useSelectOptions(SeniorityLevelOptions)
  const [Jobsfunction, handleJobsFunction, handleJobsFunctionAlls] = useSelectOptions(JobsOptions)
  const [ClientCompanyfunction, handleClientCompanyFunction, handleClientCompanyFunctionAlls] =
    useSelectOptions(ClientCompanyOptions)
  const [HiringManagerfunction, handleHiringManagerFunction, handleHiringManagerFunctionAlls] =
    useSelectOptions(HiringManagerOptions)
  const [HopTeamfunction, handleHopTeamFunction, handleHopTeamFunctionAlls] =
    useSelectOptions(HopTeamOptions)
  return (
    <div className='bg-white'>
      <div className='d-flex flex-wrap justify-content-between align-items-center bg-secondary px-2'>
        <h3 className='p-5'>Process Speed</h3>
      </div>
      <div className='d-flex flex-column flex-wrap mt-5 gap-3 px-10'>
        <DateComponent />
        <div className='d-flex flex-wrap align-items-center p-1 gap-3'>
          <h6 className='col-xl-1 col-lg-1 col-md-1 col-sm-1'>Job Function</h6>
          <CardCheckbox
            options={JobfunctionOptions}
            handleOptionSelection={handleJobFunction}
            selectedOptions={Jobfunction}
            handleOptionAll={handleJobFunctionAlls}
          />
        </div>
        <div className='d-flex flex-wrap align-items-center p-1 gap-3'>
          <h6 className='col-xl-1 col-lg-1 col-md-1 col-sm-1'>Seniority Level</h6>
          <CardRadio options={SeniorityLevelOptions} selectedOptions={SeniorityLevelfunction} />
        </div>
        <SalaryRangeComponent />
        <div className='d-flex flex-wrap align-items-center p-1 gap-3'>
          <h6 className='col-xl-1 col-lg-1 col-md-1 col-sm-1'>Location</h6>
          <CardCheckbox
            options={LocationOptions}
            handleOptionSelection={handleLocationFunction}
            selectedOptions={LocationFunction}
            handleOptionAll={handleLocationFunctionAlls}
          />
        </div>
        <div className='d-flex flex-wrap align-items-center p-1 gap-3'>
          <h6 className='col-xl-1 col-lg-1 col-md-1 col-sm-1'>Client / Company</h6>
          <CardCheckbox
            options={ClientCompanyOptions}
            handleOptionSelection={handleClientCompanyFunction}
            selectedOptions={ClientCompanyfunction}
            handleOptionAll={handleClientCompanyFunctionAlls}
          />
        </div>
        <div className='d-flex flex-wrap align-items-center p-1 gap-3'>
          <h6 className='col-xl-1 col-lg-1 col-md-1 col-sm-1'>Hiring Manager</h6>
          <CardCheckbox
            options={HiringManagerOptions}
            handleOptionSelection={handleHiringManagerFunction}
            selectedOptions={HiringManagerfunction}
            handleOptionAll={handleHiringManagerFunctionAlls}
          />
        </div>
        <div className='d-flex flex-wrap align-items-center p-1 gap-3'>
          <h6 className='col-xl-1 col-lg-1 col-md-1 col-sm-1'>Hop Team Members</h6>
          <CardCheckbox
            options={HopTeamOptions}
            handleOptionSelection={handleHopTeamFunction}
            selectedOptions={HopTeamfunction}
            handleOptionAll={handleHopTeamFunctionAlls}
          />
        </div>
        <div className='d-flex flex-wrap align-items-center p-1 gap-3'>
          <h6 className='col-xl-1 col-lg-1 col-md-1 col-sm-1'>Jobs</h6>
          <CardCheckbox
            options={JobsOptions}
            handleOptionSelection={handleJobFunction}
            selectedOptions={Jobsfunction}
            handleOptionAll={handleJobsFunctionAlls}
          />
        </div>
        <div className='d-flex flex-column flex-wrap p-1 mb-10'>
          <label className='form-label'>Email to Send Report</label>
          <input type='text' className='form-control w-50' />
        </div>
        <div className='d-flex flex-wrap justify-content-end align-items-center px-10 gap-5 mb-5'>
          <button type='button' className='btn btn-primary  fs-8 '>
            Generate Report
          </button>
          <button type='button' className='btn btn-secondary fs-8'>
            Cancel
          </button>
        </div>
      </div>
    </div>
  )
}
