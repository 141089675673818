import axios from 'axios'
import React, {ChangeEvent, useState} from 'react'
import {useForm, SubmitHandler, Controller} from 'react-hook-form'
import {useLocation, useNavigate} from 'react-router-dom'
import {useAuth} from '../../../modules/auth'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'
import {components} from '../../../../_metronic/assets/ts'
type Inputs = {
  fullName: string
  experienceJobFunc: string
  experienceCompanyName: string
  educationDegree: string
  educationFieldOfStudy: string
  educationSchoolName: string
  locationUserAddress: any
  locationUserState: string
  shortBio: string
  positionDataCompanyName: string
  positionDataMonthsWorked: string
  positionDataJobTitle: string
  positionDataEmploymentType: string
  positionDataStartDate: string
  positionDataEndDate: string
  educationDataSchoolName: string
  educationDataDegree: string
  educationDataMajor: string
  positionDataJobLocation: string
  educationDataStartDate: string
  educationDataEndDate: string
  educationDataEducationStatus: string
  degree: string
  major: string
  startDate: string
  endDate: string
  educationStatus?: string
  userId: string
  jobTitle: string
  companyName: string
  latestDegree: string
  schoolName: string
  talentStatus: boolean
}

interface dataProps {
  id: string
  fullName: string
  firstName: string
  middleName: string
  lastName: string
  email: string
  password: string
  phoneNumber: string
  is_admin: boolean
  isDeleted: boolean
  isAlternateLoginSourceEnabled: boolean
  userLocation: userLocationType
  dateOfBirth: string
  educationList: EducationType[]
  positionList: postitionListType[]
  interestedJobFunctions: [string]
  preferredWorkplaceLocation: []
  userType: string
  isProfileComplete: boolean
  profilePicture: string
  profileVideo: string
  jobSearch: string
  seniorityLevel: string
  isWorkOnSite: boolean
  isWorkHybrid: boolean
  isWorkRemotely: boolean
  workVisaRequired: string
  nonImmigrantVisaStatus: string
  desiredSalary: string
  whenYouCanStart: string
  customJoiningDate: string
  isVerify: boolean
  roleName: string
  positionName: string
  parentId: string
  companyId: string
  location: string
  userId: string
  willingToRelocate: boolean
  talentStatus: boolean
}
interface EducationData {
  schoolName: string
  degree: string
  major: string
  startDate: string
  endDate: string
  educationStatus?: string
}
interface EducationType {
  degree: string
  fieldOfStudy: string
  year: string
  schoolName: string
}

interface userLocationType {
  address: string
  city: string
  country: string
  id: string
  state: string
}

const emptyuserLocationType = {
  address: '',
  city: '',
  country: '',
  id: '',
  state: '',
}
const emptyEducation = {
  degree: '',
  fieldOfStudy: '',
  year: '',
  schoolName: '',
}
interface postitionListType {
  id: string
  companyName: string
  employmentType: string
  startDate: string
  endDate: string
  managedPeople: string
  jobTitle: string
  jobFunc: string[]
  jobLocation: string
  industries: string
  position: string
  monthsWorked: string
  isStillWorking: boolean
}

const EmptyPositionlist = {
  id: '',
  companyName: '',
  employmentType: '',
  startDate: '',
  endDate: '',
  managedPeople: '',
  jobTitle: '',
  jobFunc: [],
  jobLocation: '',
  industries: '',
  position: '',
  monthsWorked: '',
  isStillWorking: false,
}
interface PositionData {
  companyName: string
  monthsWorked: string
  jobTitle: string
  employmentType: string
  startDate: string
  endDate: string
  jobLocation: string
  jobFunc?: string[]
}
const UpdateCandidate = () => {
  const location: any = useLocation()
  const data = location.state.data
  const API_URL = process.env.REACT_APP_API_URL
  const {currentUser} = useAuth()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: {errors},
  } = useForm<Inputs>()

  const [file, setFile] = useState<File | null>(null)
  const [imageUrl, setImageUrl] = useState(data?.profilePicture)
  const [newJobFunc, setNewJobFunc] = useState('')
  const [jobSummary, setJobSummary] = useState(data?.miscellaneousDetails.shortBio)
  const [interestedJobFunctions, setInterestedJobFunctions] = useState(data.interestedJobFunctions)
  const [positionData, setPositionData] = useState<PositionData[]>(data.positionList)
  const [educationData, setEducationData] = useState<EducationData[]>(data.educationList)

  const education: EducationType =
    data.educationList.length > 0 ? data.educationList[0] : emptyEducation
  const experience: postitionListType =
    data.positionList.length > 0 ? data.positionList[0] : EmptyPositionlist
  const locationUser: userLocationType = data.userLocation
    ? data.userLocation
    : emptyuserLocationType
  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0]
    if (selectedFile) {
      setFile(selectedFile)
      setImageUrl(URL.createObjectURL(selectedFile))
    }
  }
  const handleInputEducationChange = (index: number, field: keyof EducationData, value: string) => {
    setEducationData((prevState) => {
      const updatedData = [...prevState]
      updatedData[index][field] = value
      return updatedData
    })
  }
  const handleInputPositionChange = (
    index: number,
    field: keyof PositionData,
    value: string | string[]
  ) => {
    setPositionData((prevState) => {
      const updatedData = [...prevState]

      if (field === 'jobFunc') {
        if (Array.isArray(value)) {
          updatedData[index][field] = value.filter((item) => item !== '')
        } else {
          updatedData[index][field] = []
        }
      } else {
        updatedData[index][field] = value as string
      }

      return updatedData
    })
  }

  const handleAddJobFunc = (index: number, newJobFunc: string) => {
    setPositionData((prevState) => {
      const updatedData = [...prevState]
      updatedData[index].jobFunc = [...(updatedData[index]?.jobFunc || []), newJobFunc] as string[]
      return updatedData
    })
  }

  const handleRemoveJobFunc = (index: number, optionIndex: number) => {
    setPositionData((prevState) => {
      const updatedData = [...prevState]

      const jobFunc = updatedData[index].jobFunc as string[]
      jobFunc.splice(optionIndex, 1)
      updatedData[index].jobFunc = jobFunc
      return updatedData
    })
  }
  const handleAddInterestedJobFunction = () => {
    setInterestedJobFunctions((prevState: string[]) => [...prevState, newJobFunc])
    setNewJobFunc('') // Clear the input field after adding the job function
  }

  const handleDeleteInterestedJobFunction = (index: number) => {
    setInterestedJobFunctions((prevState: string[]) => {
      const updatedJobFunctions = [...prevState]
      updatedJobFunctions.splice(index, 1)
      return updatedJobFunctions
    })
  }
  const onSubmit: SubmitHandler<Inputs> = (value) => {
    const BODY = {
      educationList: educationData,
      positionList: positionData,
      user: {
        // salary: {
        //     currencyType: PKR,
        //     amount: 391,
        //     payType: 2,
        //     totalCompensation: 60,
        //     isVisibleToEmployers: true
        // },
        // locationList: [
        //     Chicao,
        //     NYC
        // ],
        // nonImmigrantVisaStatus: H1B sponsorship,
        // interestedIndustry: [
        //     industry2,
        //     industry1,
        //     industry3
        // ],
        // avoidIndustry: [
        //     industry4,
        //     industry5,
        //     industry6
        // ],
        // interestedDomain: [
        //     domain1,
        //     domain2,
        //     domain3
        // ],
        // avoidDomain: [
        //     domain4,
        //     domain5,
        //     domain6
        // ],
        // interestedJobFunctions: [
        //     Clinical Research Associate
        // ],
        //     workVisaRequired: 1,
        //     jobSearch: 0,
        //     seniorityLevel: 0,
        //     whenYouCanStart: 0,
        //     isWorkHybrid: false,
        //     isWorkOnSite: true,
        //     isWorkRemotely: false,
        //     customJoiningDate: 2023-05-29T11:19:49.371Z,
        //     profilePicture:
        //     https://firebasestorage.googleapis.com/v0/b/moon-app-21874.appspot.com/o/images%2F5d4ff327-99b6-4c22-a359-608ec4731b1e?alt=media&token=17aa6662-7de4-4307-be07-3d47350f6cf4
      },
      miscellaneousDetails: {
        shortBio: value.shortBio,
        // achievement:[achievement5,achievement5],
        // spokenLanguagesList:[tangri],
        // interest:[video games],
        linkedinProfileLink: '',
        // duties:[duty 2]
      },
    }
    axios
      .put(
        `${API_URL}/users/admin/update-candidate-on-request?adminId=${currentUser}&userId=${data?.userId}`,
        BODY
      )
      .then((res) => {})
  }

  const [currentLocation, setCurrentLocation] = useState<any>()
  // const CustomMenuList = (props: any) => {
  //   return (
  //     <components.MenuList {...props}>
  //       {props.options.length > 0 ? (
  //         props.children
  //       ) : (
  //         <div className='d-flex p-2'>
  //           {props.hasValue
  //             ? props.getValue()[0].label
  //             : "Type the location to search. Ex. Manhattan, New York"}
  //         </div>
  //       )}
  //     </components.MenuList>
  //   );
  // };
  const googleAPI = process.env.REACT_APP_GOOGLE_API_KEY
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='d-flex bg-white flex-row-fluid justify-content-end mb-6 px-5 py-2 gap-4'>
          <button
            className='btn btn-success'
            type='submit'
            // onClick={() => {
            //   navigate('/UpdateCandidate', {
            //     state: {
            //       data: data,
            //     },
            //   })
            // }}
          >
            Save
          </button>
          <button
            className='btn btn-secondary '
            onClick={() => {
              navigate('/candidatemain')
            }}
          >
            Back
          </button>
        </div>
        <div className='d-flex flex-center'>
          <div className='card-body bg-white mb-5 '>
            {/* <div className='d-flex flex-row-fluid px-6 '> */}
            <div className='d-flex flex-column flex-xxl-row flex-xl-row flex-lg-row flex-md-row px-6'>
              <div className='d-flex flex-column flex-center mt-5 me-10'>
                <img
                  src={imageUrl}
                  className='h-100px w-100px rounded-circle flex-center'
                  alt='Profile Image '
                />
                <input
                  accept='image/*'
                  id='icon-button-file'
                  type='file'
                  style={{display: 'none'}}
                  onChange={handleFileChange}
                />
                <label
                  className='btn btn-active-primary btn-custom bi bi-pencil fs-5 mt-2'
                  htmlFor='icon-button-file'
                />
              </div>
              <div className='d-flex flex-column col-xl-9 col-lg-9 mt-5 p-5'>
                <input
                  className='form-control  input-width'
                  type='text'
                  placeholder='Full Name'
                  defaultValue={data.fullName}
                  {...register('fullName')}
                />

                <div className='d-flex align-items-center my-1'>
                  <div className='d-flex flex-row align-items-center gap-4'>
                    <input
                      className='form-control  input-width'
                      type='text'
                      placeholder='Job Function'
                      defaultValue={data.jobTitle}
                      {...register('experienceJobFunc')}
                    />

                    <div className='d-flex align-items-center gap-3'>
                      <span>@</span>
                      <input
                        className='form-control input-width'
                        type='text'
                        placeholder='Company Name'
                        defaultValue={data.companyName}
                        {...register('experienceCompanyName')}
                      />
                    </div>
                  </div>
                </div>

                <div className='d-flex flex-row card-heading2 align-items-center my-1'>
                  <input
                    className='form-control input-width'
                    type='text'
                    placeholder='Degree'
                    defaultValue={data.latestDegree}
                    {...register('educationDegree')}
                  />

                  <div className='d-flex align-items-center gap-2 px-1'>
                    <span className='p-1'>,</span>
                    <input
                      className='form-control input-width'
                      type='text'
                      placeholder='field of Study'
                      defaultValue={data.schoolName}
                      {...register('educationFieldOfStudy')}
                    />
                  </div>

                  {/* <div className='d-flex align-items-center gap-2'>
                    <span className='p-1'>@</span>
                    <input
                      className='form-control input-width'
                      type='text'
                      placeholder='School name'
                      defaultValue={education.schoolName}
                      {...register('educationSchoolName')}
                    />
                  </div> */}
                </div>

                <div className='d-flex align-items-center my-1'>
                  {/* <i className='bi bi-geo-alt fs-5 me-1' /> */}
                  <Controller
                    name='locationUserAddress'
                    control={control}
                    defaultValue=''
                    rules={{required: 'Current Location is required'}}
                    render={({field: {onChange, value}}) => (
                      <>
                        <GooglePlacesAutocomplete
                          apiKey={googleAPI}
                          selectProps={{
                            value: currentLocation,
                            onChange: (newValue) => {
                              setCurrentLocation(newValue)
                              onChange(newValue)
                            },
                            placeholder: 'Enter the location...',
                            // components: {
                            //   MenuList: CustomMenuList,
                            // },
                            styles: {
                              input: (provided) => ({
                                ...provided,
                                color: 'blue',
                              }),
                            },
                          }}
                        />
                      </>
                    )}
                  />
                  {/* <input
                    className='form-control input-width'
                    type='text'
                    placeholder='School name'
                    defaultValue={locationUser.address}
                    {...register('locationUserAddress')}
                  /> */}
                  <div className='d-flex align-items-center gap-2'>
                    <span className='p-1'>,</span>
                    <input
                      className='form-control input-width'
                      type='text'
                      placeholder='School name'
                      defaultValue={locationUser.state}
                      {...register('locationUserState')}
                    />
                  </div>
                </div>

                <div className='text my-1'>
                  <input
                    className='form-control input-width'
                    type='text'
                    placeholder='Job Summary'
                    defaultValue={jobSummary}
                    {...register('shortBio')}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='d-flex flex-column flex-xxl-row  w-100 gap-4'>
          <div className='d-flex flex-column  col-xl-4 job-cards'>
            <div className='d-flex flex-wrap  justify-content-center align-items-center p-3 gap-3 bg-white p-3 mb-5'>
              <button type='button' className='btn btn-success  fs-8 '>
                <i className='bi bi-hand-thumbs-up-fill me-2' />
                I'm interested
              </button>
              <button type='button' className='btn btn-danger fs-8'>
                <i className='bi bi-hand-thumbs-down-fill me-2' />
                Archieve
              </button>
            </div>
            <div className='card-body bg-white p-3 '>
              <div className='d-flex flex-column salary-card gap-4'>
                <div className='d-flex flex-row align-items-center gap-2'>
                  <i className='bi bi-award fs-1' />
                  <div className='d-flex flex-wrap gap-2'>
                    {interestedJobFunctions.map((option: string, index: number) => (
                      <span className='badge badge-warning badges-text p-2 rounded-pill'>
                        {option}{' '}
                        <i
                          className='bi bi-x cursor ms-1 text-dark'
                          onClick={() => handleDeleteInterestedJobFunction(index)}
                        />
                      </span>
                    ))}
                  </div>
                </div>
                <div className='d-flex align-items-center gap-3'>
                  <input
                    type='text'
                    className='form-control w-50'
                    placeholder='Add Job Function'
                    value={newJobFunc}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => setNewJobFunc(e.target.value)}
                  />
                  <button className='btn btn-info btn-sm' onClick={handleAddInterestedJobFunction}>
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex flex-column ms-1 w-100'>
            <div className='card-header px-4 bg-white'>
              <div className='pt-5'>
                <ul className='nav nav-tabs nav-line-tabs nav-line-tabs-2x mb-5 fs-6'>
                  <li className='nav-item'>
                    <a className='nav-link active' data-bs-toggle='tab' href='#experience_tab'>
                      Experience & Education
                    </a>
                  </li>
                  <li className='nav-item'>
                    <a
                      className='nav-link d-flex align-items-center'
                      data-bs-toggle='tab'
                      href='#activity_tab'
                    >
                      <i className='bi bi-arrow-clockwise fs-3 px-1' />
                      <span>Activities</span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className='tab-content'>
              <div
                className='tab-pane fade active show p-10 bg-white'
                id='experience_tab'
                role='tabpanel'
              >
                {/* {positionList.map((positionData: any, i: any) => (
                  <div className='pb-15 border border-start border-top-0 border-end-0 border-bottom-0 border-secondary position-relative'>
                    <img
                      src={imageUrl}
                      className='h-50px w-50px rounded-circle border border-dark border-1 position-absolute start-0 translate-middle-x'
                      alt='Profile Image '
                    />
                    <div className='d-flex flex-column p-5 mb-3 experience-card ' key={i}>
                      <div className='d-flex justify-content-between '>
                        <input
                          className='form-control input-width '
                          type='text'
                          placeholder='Company Name'
                          defaultValue={positionData.companyName}
                          {...register('positionDataCompanyName')}
                        />
                       
                        <input
                          className='form-control input-width'
                          type='text'
                          placeholder='Months Worked'
                          defaultValue={positionData.monthsWorked}
                          {...register('positionDataMonthsWorked')}
                        />
                      </div>
                      <div className='d-flex justify-content-between py-2'>
                        <div className='d-flex align-item-center gap-3'>
                          <input
                            className='form-control input-width '
                            type='text'
                            placeholder='Job Title'
                            defaultValue={positionData.jobTitle}
                            {...register('positionDataJobTitle')}
                          />
                          <input
                            className='form-control input-width '
                            type='text'
                            placeholder='Employment Type'
                            defaultValue={positionData.employmentType}
                            {...register('positionDataEmploymentType')}
                          />
                        </div>
                        <div className='d-flex align-items-center gap-3'>
                          <input
                            className='form-control input-width '
                            type='text'
                            placeholder='Job startDate'
                            defaultValue={positionData.startDate}
                            {...register('positionDataStartDate')}
                          />
                          <span className='text-gray-800 fw-bold fs-7'>-</span>
                          <input
                            className='form-control input-width '
                            type='text'
                            placeholder='Job endDate'
                            defaultValue={positionData.endDate}
                            {...register('positionDataEndDate')}
                          />
                        </div>
                      </div>
                      <input
                        className='form-control input-width '
                        type='text'
                        placeholder='Job endDate'
                        defaultValue={positionData.jobLocation}
                        {...register('positionDataJobLocation')}
                      />

                      <div className='d-flex flex-wrap gap-3 align-items-center '>
                        <i className='bi bi-tags-fill fs-2' />
                        {positionData.jobFunc.map((option: any) => (
                          <div className='d-flex border tag-card align-items-center'>
                            <span>{option}</span>
                            <i className='bi bi-x cursor ms-1 text-dark' onClick={() => {}} />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                ))} */}
                {positionData.map((data, index) => (
                  <div
                    className='pb-15 border border-start border-top-0 border-end-0 border-bottom-0 border-secondary position-relative'
                    key={index}
                  >
                    <img
                      src={imageUrl}
                      className='h-50px w-50px rounded-circle border border-dark border-1 position-absolute start-0 translate-middle-x'
                      alt='Profile Image'
                    />
                    <div className='d-flex flex-column p-5 mb-3 experience-card'>
                      <div className='d-flex justify-content-between'>
                        <input
                          className='form-control input-width'
                          type='text'
                          placeholder='Company Name'
                          value={data.companyName}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleInputPositionChange(index, 'companyName', e.target.value)
                          }
                        />
                        <input
                          className='form-control input-width'
                          type='text'
                          placeholder='Months Worked'
                          value={data.monthsWorked}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleInputPositionChange(index, 'monthsWorked', e.target.value)
                          }
                        />
                      </div>
                      <div className='d-flex justify-content-between py-2'>
                        <div className='d-flex align-item-center gap-3'>
                          <input
                            className='form-control input-width'
                            type='text'
                            placeholder='Job Title'
                            value={data.jobTitle}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleInputPositionChange(index, 'jobTitle', e.target.value)
                            }
                          />
                          <input
                            className='form-control input-width'
                            type='text'
                            placeholder='Employment Type'
                            value={data.employmentType}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleInputPositionChange(index, 'employmentType', e.target.value)
                            }
                          />
                        </div>
                        <div className='d-flex align-items-center gap-3'>
                          <input
                            className='form-control input-width'
                            type='text'
                            placeholder='Job Start Date'
                            value={data.startDate}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleInputPositionChange(index, 'startDate', e.target.value)
                            }
                          />
                          <span className='text-gray-800 fw-bold fs-7'>-</span>
                          <input
                            className='form-control input-width'
                            type='text'
                            placeholder='Job End Date'
                            value={data.endDate}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleInputPositionChange(index, 'endDate', e.target.value)
                            }
                          />
                        </div>
                      </div>
                      <input
                        className='form-control input-width'
                        type='text'
                        placeholder='Job Location'
                        value={data.jobLocation}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleInputPositionChange(index, 'jobLocation', e.target.value)
                        }
                      />

                      <div className='d-flex flex-column gap-3'>
                        <div className='d-flex flex-wrap gap-3 align-items-center'>
                          {data.jobFunc && data.jobFunc?.length > 0 && (
                            <i className='bi bi-tags-fill fs-2' />
                          )}
                          {data.jobFunc?.map((option: any, optionIndex: any) => (
                            <div className='d-flex flex-wrap gap-3 align-items-center'>
                              <div
                                className='d-flex border tag-card align-items-center'
                                key={optionIndex}
                              >
                                <span>{option}</span>
                                <i
                                  className='bi bi-x cursor ms-1 text-dark'
                                  onClick={() => handleRemoveJobFunc(index, optionIndex)}
                                />
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className='d-flex align-items-center gap-3'>
                          <input
                            type='text'
                            className='form-control w-50'
                            placeholder='Add Job Function'
                            value={newJobFunc}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              setNewJobFunc(e.target.value)
                            }
                          />
                          <button
                            className='btn btn-info btn-sm'
                            onClick={() => handleAddJobFunc(index, newJobFunc)}
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                {educationData.map((data, index) => (
                  <div
                    className='pb-15 border border-start border-top-0 border-end-0 border-bottom-0 border-secondary position-relative'
                    key={index}
                  >
                    <img
                      src={imageUrl}
                      className='h-50px w-50px rounded-circle border border-dark border-1 position-absolute start-0 translate-middle-x'
                      alt='Profile Image'
                    />
                    <div className='d-flex flex-column p-5 mb-3 experience-card'>
                      <div className='d-flex align-items-center py-2'>
                        <input
                          className='form-control input-width'
                          type='text'
                          placeholder='School Name'
                          value={data.schoolName}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleInputEducationChange(index, 'schoolName', e.target.value)
                          }
                        />
                        <i className='fa-solid fa-graduation-cap fs-2 ms-2' />
                      </div>
                      <div className='d-flex align-items-center gap-3'>
                        <input
                          className='form-control input-width'
                          type='text'
                          placeholder='Degree'
                          value={data.degree}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleInputEducationChange(index, 'degree', e.target.value)
                          }
                        />
                        ,
                        <input
                          className='form-control input-width'
                          type='text'
                          placeholder='Major'
                          value={data.major}
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            handleInputEducationChange(index, 'major', e.target.value)
                          }
                        />
                      </div>
                      <div className='d-flex justify-content-between w-100 align-items-center'>
                        <div className='d-flex align-items-center gap-3'>
                          <input
                            className='form-control input-width'
                            type='text'
                            placeholder='Education Start Date'
                            value={data.startDate}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleInputEducationChange(index, 'startDate', e.target.value)
                            }
                          />
                          -
                          <input
                            className='form-control input-width'
                            type='text'
                            placeholder='Education End Date'
                            value={data.endDate}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                              handleInputEducationChange(index, 'endDate', e.target.value)
                            }
                          />
                        </div>
                        <div className='d-flex justify-content-end'>
                          {data.educationStatus && (
                            <div>
                              Education:
                              <input
                                className='form-control input-width'
                                type='text'
                                placeholder='Education Status'
                                value={data.educationStatus}
                                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                  handleInputEducationChange(
                                    index,
                                    'educationStatus',
                                    e.target.value
                                  )
                                }
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

                {/* </div> */}
                {/* /////////////////////////////////// */}
              </div>
              <div className='tab-pane fade' id='activity_tab' role='tabpanel'>
                <div className='card card-custom '>
                  <div className='card-body '>
                    <div className='d-flex flex-column'>
                      <div className='d-flex flex-row align-items-center pb-6'>
                        <div className='d-flex flex-column '>
                          <i className='fa-sharp fa-solid fa-chart-simple fs-1' />
                        </div>
                        <div className='d-flex flex-column ms-8'>
                          <span>User Viewed The Profile</span>
                          <span className='text-info'>2 hrs Ago</span>
                        </div>
                      </div>
                      <div className='d-flex flex-row align-items-center pb-6'>
                        <div className='d-flex flex-column'>
                          <i className='bi bi-lightning-charge-fill fs-1' />
                        </div>
                        <div className='d-flex flex-column ms-8'>
                          <span>User (@username) interested </span>
                          <span className='text-info'>2 hrs Ago</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  )
}

export default UpdateCandidate
