import React from 'react'

interface ICheckRadioButtonProps {
  options: any[]
  selectedOptions: string
  // selectedOptions: (option: any) => void
}
export default function CardRadio({options, selectedOptions}: ICheckRadioButtonProps) {
  return (
    <div>
      {options.map((option: any) => (
        <div className='btn-group p-2' role='group' aria-label='Basic radio toggle button group'>
          <input
            type='radio'
            className='btn-check'
            name='btnradio'
            id={option}
            // checked={selectedOptions.includes(option) ? true : false}
            // checked={selectedOptions.includes(option) ? true : false}
          />
          <label
            className='btn btn-outline-primary options-border btn-sm rounded-pill'
            htmlFor={option}
          >
            {option}
          </label>
        </div>
      ))}
    </div>
  )
}
