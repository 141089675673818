import {useEffect, useRef} from 'react'
import {Spinner} from 'react-bootstrap'

import useConversation from './useConversation'
import {getTimeAgo} from '../../../../utils/helper/helper'
import {useUserStore} from '../../../../store/user'
import noImage from '../../../../_metronic/assets/img/noImage.png'

import '../chat.css'

const Conversation = ({selectedUser}: any) => {
  const {handleSendMessage, inputMessage, setInputMessage, message, messageLoading} =
    useConversation(selectedUser)

  const {user} = useUserStore()
  const userId = user?.userId

  const AlwaysScrollToBottom = () => {
    const elementRef = useRef<HTMLDivElement>(null)
    useEffect(() => {
      elementRef && elementRef.current && elementRef.current.scrollIntoView(true)
    }, [])
    return <div ref={elementRef} />
  }

  return (
    <>
      {messageLoading ? (
        <div
          className='d-flex justify-content-center align-items-center w-100'
          style={{height: '79vh'}}
        >
          <div className='container-fluid ' style={{height: '79vh'}}>
            <div className='row ' style={{height: '79vh'}}>
              <div className='col ' style={{height: '79vh'}}>
                <div className='card card-custom h-100'>
                  <div className='d-flex h-100 justify-content-center align-items-center'>
                    <Spinner />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : selectedUser && message ? (
        <>
          <div className='d-flex flex-column flex-row-fluid w-100' style={{width: '100%'}}>
            <div className='card card-custom main-card-height'>
              <div className='card-header align-items-center'>
                <div className='d-flex flex-column py-1 mt-2'>
                  <div className='d-flex gap-3 align-items-center'>
                    <img
                      src={
                        (selectedUser?.sendTo.id === user?.userId
                          ? selectedUser?.sendBy?.profilePicture
                          : selectedUser?.sendTo?.profilePicture) || noImage
                      }
                      className='h-20px w-20px rounded-circle flex-center'
                      alt='Profile'
                    />

                    <span className='username-head text-hover-info pointer'>
                      {selectedUser?.sendTo.id === user?.userId
                        ? selectedUser?.sendBy?.name
                        : selectedUser?.sendTo?.name}
                    </span>
                    {/* <span className='badge badge-light-success'>Review Backlog</span> */}
                  </div>
                  {/* <li className='d-flex align-items-center p-2'>
                <span className='bullet bullet-dot bg-success me-2' />
                Active
              </li> */}
                </div>
                {/* <div className='dropdown dropdowninline'>
                <button
                  type='button'
                  className='btn btn-active-light-info btn-clean btn-sm btn-icon btn-icon-md '
                  data-toggle='dropdown'
                  aria-haspopup='true'
                  aria-expanded='true'
                >
                  <i className='bi bi-plus-lg fs-3' />
                </button>
                
              </div> */}
              </div>
              <div className='card-body message-card overflow-auto'>
                <div>
                  {/* {React.Children.toArray( */}
                  {message &&
                    message.map((item: any, index: any) => {
                      if (item.sentBy === userId) {
                        return (
                          <div
                            className='d-flex flex-column justify-content-end align-items-end'
                            key={index}
                          >
                            <div className='d-flex flex-row-reverse gap-3 align-items-baseline '>
                              <img
                                src={(user && user?.profilePicture) || noImage}
                                className='h-20px w-20px rounded-circle flex-center'
                                alt='Profile'
                              />
                              <div className='username-head text-hover-info pointer'>You</div>
                              <div className='text-muted fs-8'>
                                {' '}
                                {getTimeAgo(
                                  new Date(
                                    item?.sentAt.seconds * 1000 + item.sentAt.nanoseconds / 1000000
                                  )
                                )}
                              </div>
                            </div>

                            <div key={index}>
                              <div className='text text-end chat-sender-card '>{item.text}</div>
                            </div>
                          </div>
                        )
                      } else {
                        return (
                          <div className='d-flex flex-column justify-content-start ' key={index}>
                            <div className='d-flex gap-3 align-items-baseline'>
                              <img
                                src={
                                  (selectedUser?.sendTo.id === user?.userId
                                    ? selectedUser?.sendBy?.profilePicture
                                    : selectedUser?.sendTo?.profilePicture) || noImage
                                }
                                className='h-20px w-20px rounded-circle flex-center'
                                alt='Profile '
                              />
                              <div className='username-head text-hover-info pointer'>
                                {selectedUser?.sendTo.id === user?.userId
                                  ? selectedUser?.sendBy?.name
                                  : selectedUser?.sendTo?.name}
                              </div>
                              <div className='text-muted fs-8'>
                                {' '}
                                {getTimeAgo(
                                  new Date(
                                    item?.sentAt.seconds * 1000 + item.sentAt.nanoseconds / 1000000
                                  )
                                )}
                              </div>
                            </div>
                            <div key={index}>
                              <div className='chat-receiver-card '>{item.text}</div>
                            </div>
                          </div>
                        )
                      }
                    })}
                  {/* )}  */}
                </div>
                <AlwaysScrollToBottom />
              </div>
              <div className='card-footer'>
                <div className='d-flex'>
                  <input
                    type='text'
                    className='form-control form-control-flush'
                    placeholder='Type here....'
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleSendMessage()
                      }
                    }}
                    value={inputMessage}
                    onChange={(e) => setInputMessage(e.target.value)}
                  />
                </div>
                <div className='d-flex justify-content-end'>
                  <div>
                    <button
                      className='btn btn-info text-light btn-sm'
                      disabled={inputMessage.trim().length <= 0}
                      onClick={handleSendMessage}
                    >
                      Send
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className='d-flex justify-content-center align-items-center' style={{height: '79vh'}}>
          <div className='container-fluid' style={{height: '79vh'}}>
            <div className='row' style={{height: '79vh'}}>
              <div className='col ' style={{height: '79vh'}}>
                <div className='card card-custom h-100'>
                  <div className='d-flex h-100 justify-content-center align-items-center'>
                    <div className='text-muted fs-2'>No Conversation</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default Conversation
