import React, {useEffect, useState} from 'react'
import CompanyCard from './companyCard/companyCard'
import axios from 'axios'
import {Spinner} from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import {scrollToTopOfScrollable} from '../../../utils/helper/helper'

const CompanyPage = () => {
  const [companyList, setCompanyList] = useState<any>([])
  const [isLoading, setIsLoading] = useState(false)
  const [query, setQuery] = useState('')

  const [itemOffset, setItemOffset] = useState(0)
  const itemsPerPage = 15
  const [pageCount, setPageCount] = useState<any>()

  const handlePageClick = (event: any) => {
    const newOffset = event.selected
    setItemOffset(newOffset)
  }

  useEffect(() => {
    setIsLoading(true)
    axios
      .get(
        `${process.env.REACT_APP_API_URL}/users/admin/company?query=${query}&page=${itemOffset}&limit=${itemsPerPage}`
      )
      .then((res) => {
        setIsLoading(false)
        setCompanyList(res?.data)
        setPageCount(res.data.totalPages || 1)
        scrollToTopOfScrollable()
      })
      .catch((error) => {
        setIsLoading(false)
        console.log('ERROR', error)
      })
  }, [itemOffset, query])
  const handleChange = (e: any) => {
    e.preventDefault()
    let value = e.target.value
    setQuery(value.trimLeft())
    if (value.length && itemOffset !== 0) {
      setItemOffset(0)
    }
  }
  return (
    <div>
      <div className='d-flex justify-content-between  flex-column-reverse flex-xxl-row flex-xl-row flex-lg-row flex-md-row flex-sm-row bg-white mb-6 rounded px-5 '>
        <div className='d-flex align-items-center gap-3  p-3'>
          <span> {companyList?.totalElements} Total</span>
          <div className='search '>
            <input
              type='text'
              className='form-control input-area bg-grey'
              placeholder='Search...'
              value={query}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
      <div className='card-header bg-white rounded p-2'>
        <h3 className='mx-5'>Companies</h3>
      </div>

      {!companyList?.companies?.length ? (
        <div className='d-flex p-9 m-9 justify-content-center align-items-center fs-1  fw-bold'>
          {isLoading ? <Spinner /> : 'No Company Found'}
        </div>
      ) : (
        <>
          <div>
            <div className='d-flex justify-content-start flex-wrap flex-column flex-xxl-row flex-xl-row flex-lg-row flex-md-row p-1 mt-5 gap-3'>
              {companyList?.companies?.map((company: any, index: number) => (
                <CompanyCard data={company} key={index} />
              ))}
            </div>
            <div className='d-flex'>
              <ReactPaginate
                nextLabel='Next >'
                onPageChange={handlePageClick}
                forcePage={itemOffset}
                pageRangeDisplayed={3}
                marginPagesDisplayed={2}
                pageCount={pageCount}
                previousLabel='< Previous'
                pageClassName='page-item'
                pageLinkClassName='page-link'
                previousClassName='page-item'
                previousLinkClassName='page-link'
                nextClassName='page-item'
                nextLinkClassName='page-link'
                breakLabel='...'
                breakClassName='page-item'
                breakLinkClassName='page-link'
                containerClassName='pagination'
                activeClassName='active'
                renderOnZeroPageCount={null}
              />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default CompanyPage
