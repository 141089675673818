import React, {useEffect, useRef, useState} from 'react'
import ReactDatePicker from 'react-datepicker'
import {stageOptions} from '../../../../utils/contants'

const TalentOpportunities = ({
  job,
  updatedStage,
  setUpdatedStage,
  jobToBeUpdated,
  setJobToBeUpdated,
  selectedDate,
  setSelectedDate,
  handleSubmitStage,
  openStages,
  setOpenStages,
  openStageId,
  setOpenStageId,
}: any) => {
  const menuRef = useRef<any>()
  const [currentDate, setCurrentDate] = useState<Date | null>(new Date())
  const [filteredOptions, setFilteredOptions] = useState<any>([])
  const [position, setPosition] = useState({x: 0, y: 0})

  const mouseClick = (e: any) => {
    setPosition({x: e.clientX, y: e.clientY})
  }
  const handleStageFilter = (stage: string) => {
    switch (stage) {
      case 'UNDER_REVIEW':
        return 'badge badge-light-success'
      case 'HR_SCREEN':
        return 'badge badge-light-success'
      case 'INTERVIEW_PENDING_SCHEDULING':
      case 'INTERVIEW_SCHEDULED':
      case 'INTERVIEW_PENDING_FEEDBACK':
        return 'badge badge-light-danger'

      case 'ONSITE_PENDING_SCHEDULING':
      case 'ONSITE_SCHEDULED':
      case 'ONSITE_PENDING_FEEDBACK':
        return 'badge badge-light-info'
      case 'OFFER_PENDING':
      case 'OFFER_EXTENDED':
      case 'OFFER_ACCEPTED':
        return 'badge badge-light-warning'
      case 'HIRED_STARTED':
        return 'badge badge-light-warning'
      case 'ARCHIVED':
        return 'badge badge-light-warning'
      case 'REJECTED':
        return 'badge badge-light-danger'
      default:
        return ''
    }
  }

  useEffect(() => {
    if (job?.modifyDate !== null) {
      const [year, month, day, hours, minutes, seconds, timestamp] = job?.modifyDate || []
      const dateObject = new Date(
        year,
        month - 1,
        day,
        hours,
        minutes,
        seconds,
        (timestamp ? timestamp : 345) / 1e6
      )

      setCurrentDate(dateObject)
    } else {
      setCurrentDate(new Date())
    }
  }, [job?.id])

  function handleClickOutside(event: MouseEvent) {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setOpenStages(false)
    }
  }
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])
  useEffect(() => {
    if (job?.modifyDate !== null) {
      const [year, month, day, hours, minutes, seconds, timestamp] = job?.modifyDate || []
      const dateObject = new Date(
        year,
        month - 1,
        day,
        hours,
        minutes,
        seconds,
        (timestamp ? timestamp : 345) / 1e6
      )

      setCurrentDate(dateObject)
    } else {
      setCurrentDate(new Date())
    }
    const filteredData = filterStageOptions(job?.stagingLevel)
    setFilteredOptions(filteredData)
  }, [job])
  function filterStageOptions(stage: string) {
    const searchTerm = stage?.split('_')[0]

    switch (searchTerm) {
      case 'UNDER':
        return stageOptions.filter(
          (option) =>
            option.value !== 'REJECTED' &&
            (option.value.includes('INTERVIEW') ||
              option.value.includes('HR_SCREEN') ||
              option.value === 'ARCHIVED')
        )
      case 'HR':
        return stageOptions.filter(
          (option) =>
            option.value !== 'REJECTED' &&
            (option.value.includes('INTERVIEW') || option.value === 'ARCHIVED')
        )
      case 'INTERVIEW':
        return stageOptions.filter(
          (option) =>
            option.value !== 'REJECTED' &&
            (option.value.includes('ONSITE') ||
              option.value.includes('INTERVIEW') ||
              option.value === 'ARCHIVED')
        )
      case 'ONSITE':
        return stageOptions.filter(
          (option) =>
            option.value !== 'REJECTED' &&
            (option.value.includes('OFFER') ||
              option.value.includes('ONSITE') ||
              option.value === 'ARCHIVED')
        )
      case 'OFFER':
        return stageOptions.filter(
          (option) =>
            option.value !== 'REJECTED' &&
            (option.value === 'HIRED_STARTED' ||
              option.value.includes('OFFER') ||
              option.value === 'ARCHIVED')
        )
      case 'HIRED':
        return stageOptions.filter(
          (option) => option.value !== 'REJECTED' && option.value === 'ARCHIVED'
        )
      default:
        return stageOptions
    }
  }

  function getStageName(value: string) {
    for (const stage of stageOptions) {
      if (stage.value === value) {
        return stage.name
      }
    }
    return ''
  }
  const selectedStage = getStageName(job?.stagingLevel)

  return (
    <>
      <div className='d-flex align-items-center justify-content-between gap-3' key={job?.id}>
        <div className='d-flex align-items-center justify-content-between gap-2'>
          <i className='fa fa-suitcase' />
          <div className='text'>
            {' '}
            {job?.name && job?.name.length > 20 ? job?.name?.slice(0, 20) + '...' : job?.name}{' '}
          </div>
        </div>
        <div className='d-flex align-items-center justify-content-between gap-2'>
          <span className={handleStageFilter(job?.stagingLevel)}>{selectedStage}</span>
          <div className='d-flex flex-column '>
            <button
              className='btn btn-active-light-info btn-sm btn-icon'
              onClick={(e: any) => {
                setOpenStages(!openStages)
                setJobToBeUpdated(job)
                setOpenStageId(job?.id)
                mouseClick(e)
              }}
            >
              <i className='fa fa-caret-down' />
            </button>
            {openStages && openStageId === job?.id && (
              <div
                className='d-flex flex-column stage-dropdown gap-1'
                ref={menuRef}
                style={{position: 'absolute', top: position.y, left: position.x}}
              >
                <div className='text text-dark current-stages'>Current Stages:</div>
                <div className='divider mb-1' />
                <div className='d-flex flex-column' style={{maxHeight: '260px', overflowY: 'auto'}}>
                  {filteredOptions.map((stage: any) => (
                    <div
                      className={
                        updatedStage.value === stage?.value && updatedStage?.id === job?.id
                          ? 'd-flex stages-row-active'
                          : 'd-flex stages-row'
                      }
                      key={stage?.id}
                      onClick={() => {
                        setUpdatedStage({
                          id: job?.id,
                          value: stage?.value,
                        })
                      }}
                    >
                      <span className={stage?.class}>{stage?.name}</span>
                    </div>
                  ))}
                </div>
                <div className='d-flex flex-column current-stages'>
                  {' '}
                  <ReactDatePicker
                    selected={currentDate}
                    onChange={(date: any) => {
                      setCurrentDate(date)
                      setSelectedDate({id: job?.id, value: date})
                    }}
                    dateFormat='dd/MM/yyyy'
                    dropdownMode='select'
                    minDate={currentDate}
                    maxDate={new Date()}
                  />
                </div>
                <div className='d-flex justify-content-end px-3'>
                  <button
                    className='btn btn-info btn-sm'
                    onClick={handleSubmitStage}
                    disabled={updatedStage.id !== job?.id ? true : false}
                  >
                    Save
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default TalentOpportunities
