import {useEffect, useState} from 'react'
import {Spinner} from 'react-bootstrap'
import axios from 'axios'

import NewJobCard from '../JobCard'
import ReactPaginate from 'react-paginate'
import {scrollToTopOfScrollable} from '../../../../utils/helper/helper'

import '../jobPage.css'

interface jobDataProps {
  status: number
  name: string
  description: string
  activeHiring: boolean
  reqNumber: string
  createdBy: string
  seniorityLevel: string
  sponsorshipType: string
  salary: {
    currency: string
    starting: number
    ending: number
    duration: string
  }
  bonus: {
    starting: number
    ending: number
    bonusType: string
  }
  stock: {
    type: string
    stockType: string
    duration: string
    starting: number
    ending: number
  }
  jobFunctions: [string]
  responsibilitiesList: [string]
  responsibilitiesList2: [string]
  locationsType: string
  peopleManagement: string
  companyName: string
  companyLogo: string
  profilePicture: string
  reports: string
  degree: [string]
  jobResponsibility: [
    {
      responsibility: string
      duration: string
    }
  ]
  industryExperienceList: [string]
  employeeBenefitList: [string]
  collegeMajorList: [string]
  domainList: [string]
  topSkillList: [string]
  experience: [
    {
      fieldName: string
      degree: string
      experienceLevel: string
    }
  ]
  locationsList: [string]
}
const NewJobPage = () => {
  const API_URL = process.env.REACT_APP_API_URL
  const [jobData, setJobData] = useState<jobDataProps[]>([])
  const [isLoading, setIsLoading] = useState(false)

  const [itemOffset, setItemOffset] = useState(0)
  const itemsPerPage = 10
  const [pageCount, setPageCount] = useState(Math.ceil(jobData.length / itemsPerPage))

  const handlePageClick = (event: any) => {
    const newOffset = event.selected
    setItemOffset(newOffset)
  }

  useEffect(() => {
    setIsLoading(true)
    axios
      .get(`${API_URL}/jobs/unapproved-jobs?page=${itemOffset}&limit=12`)
      .then((response) => {
        setIsLoading(false)
        setJobData(response?.data?.jobResponseBodyList)
        setPageCount(response.data.totalPages || 1)
        scrollToTopOfScrollable()
      })
      .catch((error) => {
        setIsLoading(false)
      })
  }, [itemOffset])

  return (
    <div>
      <div className='card-header bg-white rounded p-2'>
        <h3 className='mx-5'>New Jobs</h3>
      </div>
      {!jobData?.length ? (
        <div className='d-flex p-9 m-9 justify-content-center align-items-center fs-1  fw-bold'>
          {isLoading ? <Spinner /> : 'No Jobs Found'}
        </div>
      ) : (
        <div className='d-flex flex-column justify-content-between' style={{minHeight: '60vh'}}>
          <div className='d-flex justify-content-start flex-wrap flex-column flex-xxl-row flex-xl-row flex-lg-row flex-md-row p-1 mt-5 gap-3'>
            {jobData.map((data, i) => (
              <NewJobCard data={data} key={i} />
            ))}
          </div>
          <div className='d-flex'>
            <ReactPaginate
              nextLabel='Next >'
              onPageChange={handlePageClick}
              forcePage={itemOffset}
              pageRangeDisplayed={3}
              marginPagesDisplayed={2}
              pageCount={pageCount}
              previousLabel='< Previous'
              pageClassName='page-item'
              pageLinkClassName='page-link'
              previousClassName='page-item'
              previousLinkClassName='page-link'
              nextClassName='page-item'
              nextLinkClassName='page-link'
              breakLabel='...'
              breakClassName='page-item'
              breakLinkClassName='page-link'
              containerClassName='pagination'
              activeClassName='active'
              renderOnZeroPageCount={null}
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default NewJobPage
