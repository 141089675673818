export const stageOptions = [
  {id: 1, class: 'badge badge-light-success', name: 'Under Review', value: 'UNDER_REVIEW'},
  {id: 2, class: 'badge badge-light-success', name: 'HR Screen', value: 'HR_SCREEN'},
  {
    id: 3,
    class: 'badge badge-light-danger',
    name: 'Interview Pending Scheduling',
    value: 'INTERVIEW_PENDING_SCHEDULING',
  },
  {
    id: 4,
    class: 'badge badge-light-danger',
    name: 'Interview Scheduled',
    value: 'INTERVIEW_SCHEDULED',
  },
  {
    id: 5,
    class: 'badge badge-light-danger',
    name: 'Interview Pending Feedback',
    value: 'INTERVIEW_PENDING_FEEDBACK',
  },
  {
    id: 6,
    class: 'badge badge-light-info',
    name: 'Onsite Pending Scheduling',
    value: 'ONSITE_PENDING_SCHEDULING',
  },
  {id: 7, class: 'badge badge-light-info', name: 'Onsite Scheduled', value: 'ONSITE_SCHEDULED'},
  {
    id: 8,
    class: 'badge badge-light-info',
    name: 'Onsite Pending Feedback',
    value: 'ONSITE_PENDING_FEEDBACK',
  },
  {id: 9, class: 'badge badge-light-warning', name: 'Offer Pending', value: 'OFFER_PENDING'},
  {id: 10, class: 'badge badge-light-warning', name: 'Offer Extended', value: 'OFFER_EXTENDED'},
  {id: 11, class: 'badge badge-light-warning', name: 'Offer Accepted', value: 'OFFER_ACCEPTED'},
  {id: 12, class: 'badge badge-light-warning', name: 'Hired Started', value: 'HIRED_STARTED'},
  {id: 13, class: 'badge badge-light-warning', name: 'Archived', value: 'ARCHIVED'},
]
