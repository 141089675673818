import {getTimeAgo} from '../../../../utils/helper/helper'
import {useUserStore} from '../../../../store/user'
import noImage from '../../../../_metronic/assets/img/noImage.png'

import '../chat.css'

const ChatList = ({item, handleSelectedUser}: any) => {
  const {user} = useUserStore()

  return (
    <>
      <div className='d-flex border-bottom w-100'>
        <div className='card p-1 card-custom card-border me-3 h-100 w-100'>
          <div className='card-body p-0  cursor' onClick={() => handleSelectedUser(item)}>
            <div className='d-flex flex-column gap-3  message-card overflow-auto'>
              <div className='d-flex  gap-2 p-3 m-1 justify-content-between '>
                <div className='d-flex align-items-center gap-3 w-100'>
                  <img
                    src={
                      (item?.sendTo.id === user?.userId
                        ? item?.sendBy?.profilePicture
                        : item?.sendTo?.profilePicture) || noImage
                    }
                    className='h-40px w-40px rounded-circle flex-center'
                    alt='Profile'
                  />
                  <div className='w-100'>
                    <div className='d-flex justify-content-between align-items-center gap-2 w-100'>
                      <span className='username-head text-hover-info pointer'>
                        {item?.sendTo.id === user?.userId
                          ? item?.sendBy?.name?.length > 45
                            ? item?.sendBy?.name?.slice(0, 45) + '...'
                            : item?.sendBy?.name
                          : item?.sendTo?.name?.length > 45
                          ? item?.sendTo?.name?.slice(0, 45) + '...'
                          : item?.sendTo?.name}
                      </span>
                      <span className='d-flex fs-9'>
                        {' '}
                        {getTimeAgo(
                          new Date(
                            item?.lastUpdate.seconds * 1000 + item.lastUpdate.nanoseconds / 1000000
                          )
                        )}
                      </span>
                      {/* <span className='bullet bullet-dot bg-success me-2' /> */}
                    </div>
                    <div className='username-desctiption'>
                      {item?.recentMessage?.length > 70
                        ? item?.recentMessage?.slice(0, 70) + '...'
                        : item?.recentMessage}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ChatList
