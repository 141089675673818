/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
import {useAuth} from '../core/Auth'
import PinInput from 'react-pin-input'
import axios from 'axios'
import {ToastContainer, toast} from 'react-toastify'
import {useUserStore} from '../../../../store/user'

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
})

const initialValues = {
  // email: 'superdmain@gmail.com',
  email: '',
  password: '',
}

/*
  Formik+YUP+Typescript:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
  https://medium.com/@maurice.de.beijer/yup-validation-and-typescript-and-formik-6c342578a20e
*/

export function Login() {
  const {setUser} = useUserStore()
  const [email, setEmail] = useState('')
  const [showError, setShowError] = useState('')
  const [otpError, setOtpError] = useState(false)
  const [otp, setOtp] = useState('')
  const [showOtpInput, setShowOtpInput] = useState(false)
  const API_URL = process.env.REACT_APP_API_URL
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [isLoading, setIsLoading] = useState(false)

  const handleEmailChange = (e: any) => {
    setEmail(e.target.value.trim())
  }
  const handleContinue = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
    const isEmailValid = emailRegex.test(email)

    if (email && isEmailValid) {
      setIsLoading(true)

      try {
        axios
          .post(`${API_URL}/users/auth/generate-otp-admin-login`, {
            email: email,
          })
          .then((res) => {
            setIsLoading(false)
            setShowOtpInput(true)
          })
          .catch((error) => {
            setIsLoading(false)
            console.log('ERROR', error?.response?.data)

            setShowError(error?.response?.data)
            console.error('error', error)

            toast.error(`${error?.response?.data}`, {
              position: 'top-center',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: 'colored',
            })
          })
      } catch (error) {
        console.log(error)
      }
    } else {
      setShowError('Please enter a valid email')
    }
  }

  const handleOtpChange = (value: any) => {
    if (value.length === 6) {
      setOtp(value)
      setOtpError(false)
    } else {
      setOtpError(true)
    }
  }
  const handleLogin = async (otpProps: any) => {
    try {
      setIsLoading(true)
      const {data: res} = await login(otpProps, email)
      const token = res.token
      const user = res
      saveAuth({token, user})
      saveAuth(res)
      const {data: userByToken} = await getUserByToken(res.token)
      setUser(user)
      setCurrentUser(user)
    } catch (error: any) {
      setOtpError(true)
      console.error(error)
      saveAuth(undefined)
    }
    setIsLoading(false)
  }

  const formik = useFormik({
    initialValues,
    validationSchema: loginSchema,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const {data: auth} = await login(values.email, values.password)

        saveAuth(auth)
        const {data: user} = await getUserByToken(auth.api_token)
        setCurrentUser(auth.user)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The login details are incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  return (
    <>
      <form
        className='form w-100'
        onSubmit={formik.handleSubmit}
        noValidate
        id='kt_login_signin_form'
      >
        <div className='text-center mb-11'>
          <h1 className='text-dark fw-bolder mb-3'>Log In</h1>
        </div>
        {!showOtpInput ? (
          <div className='fv-row mb-8'>
            <label className='form-label fs-6 fw-bolder text-dark'>Email</label>
            <input
              placeholder='Email'
              className='form-control bg-transparent'
              type='email'
              name='email'
              value={email}
              onChange={handleEmailChange}
            />
            {showError && (
              <div className='fw-bold p-4' style={{color: 'red'}}>
                {showError}
              </div>
            )}
            <div className='d-grid pt-5'>
              <button
                type='submit'
                id='kt_sign_in_submit'
                className='btn btn-info'
                disabled={!email || isLoading}
                onClick={handleContinue}
              >
                {isLoading ? (
                  <div className='spinner-border' role='status'>
                    <span className='sr-only'>Loading...</span>
                  </div>
                ) : (
                  <span className='indicator-label fw-bold'>Login</span>
                )}
              </button>
            </div>
          </div>
        ) : (
          <div className='d-flex flex-column align-items-center mb-8'>
            <div className='d-flex flex-column align-items-center'>
              <label className='form-label fs-3  text-dark'>We have sent code on :</label>
              <label className='form-label fs-4 fw-bold text-dark'>{email}</label>
            </div>
            <PinInput
              length={6}
              secret={false}
              onChange={(e) => setOtp(e)}
              initialValue={otp}
              type='numeric'
              inputMode='number'
              placeholder='◦'
              style={{padding: '10px'}}
              inputStyle={{border: '1px solid #b5afaf', borderRadius: '10px', margin: '2px 5px'}}
              inputFocusStyle={{
                border: '2px solid #5d78ff',
                borderRadius: '10px',
              }}
              onComplete={(value, index) => {
                handleLogin(value)
                setOtp(value)
              }}
              autoSelect={true}
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
            {otpError && (
              <div className='fw-bold p-1' style={{color: 'red'}}>
                *Invalid OTP
              </div>
            )}

            <div className='d-flex justify-content-center w-100 pt-4'>
              <button
                type='submit'
                className='btn btn-info w-100'
                disabled={!otp || isLoading}
                onClick={() => handleLogin(otp)}
              >
                {isLoading ? (
                  <div className='spinner-border' role='status'>
                    <span className='sr-only'>Loading...</span>
                  </div>
                ) : (
                  <span className='indicator-label fs-4 fw-bold'>Verify</span>
                )}
              </button>
            </div>
          </div>
        )}
      </form>
      <ToastContainer />
    </>
  )
}
